.how-teach-txt-sec ul li svg , .batch-comparison-sec .batch-comparison-cta svg ,
.head-txt-sec ul li svg{
    height:19px ;
    width: 16px;
}
p.hts-txt-tab{
    margin:10px 0;
}
/*new global css Start*/
.headerStrip {
    margin-top: 48px;
}

@media (max-width:768px) {
    .headerStrip {
        margin-top: 105px;
    }
}

.header-sticky .hs-container {
    background-color: #ec4712;
    padding: 3px 43px 10px 10px !important;
    position: fixed;
    top: 0;
    width: 100%;
    border-radius: 0 !important;
    z-index: 2;
    text-align: center;
    z-index: 999;
}

    .header-sticky .hs-container .close-sticky {
        font-size: 25px;
        opacity: 1;
        margin-right: 8px !important;
        margin-top: 3px !important;
        float: right;
        text-decoration: none;
        color: #ffeae4 !important;
        background-color: lightcoral;
        padding: 2px 7px !important;
        border-radius: 50px !important;
        width: 30px !important;
        height: 30px !important;
        background: none !important;
        position: absolute;
        top: 0;
        right: 0;
    }

@media (max-width: 768px) {
    .header-sticky .hs-container .close-sticky {
        margin-right: 5px !important;
    }

    .header-sticky .hs-container .hs-txt p .header-sticky-span-txt {
        display: inline-block !important;
    }

    .header-sticky .hs-container .hs-txt p .marque {
        display: none;
    }
}


.header-sticky .hs-container .hs-txt {
    padding: 5px 0 !important;
}

    .header-sticky .hs-container .hs-txt p {
        margin: 0;
        font-size: 17px;
        color: #fff;
        margin-bottom: -5px;
        text-align: center;
        padding: 0 15px;
    }

        .header-sticky .hs-container .hs-txt p .marque {
            padding-top: 5px;
        }

        .header-sticky .hs-container .hs-txt p .header-sticky-span-txt {
            display: none;
        }

        .header-sticky .hs-container .hs-txt p .clamBtn {
            background-color: #333;
            border: none;
            color: #fff;
            box-shadow: 1px 1px 5px rgba(82, 76, 76, 0.5);
            padding: 4px 10px;
            border-radius: 4px;
            text-decoration: none;
        }

@media (max-width:576px) {
    .header-sticky .hs-container .hs-txt p .clamBtn {
        display: inline-block;
        margin: 5px auto 0px auto;
        width: 50%;
    }
}

/*-------------- Header Sticky Modal Form -------------*/
.model {
    display: none;
    position: fixed;
    padding-top: 100px;
    top: 100px;
    left: 0;
    z-index: 1;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    padding-top: 100px;
}

@media (max-width:768px) {
    .model {
        position: fixed;
        top: 80px;
    }
}

.model-content {
    position: relative;
    width: 40%;
    margin: auto;
    background-color: #fff;
    animation-name: animatetop;
    animation-duration: 0.4s;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    border-radius: 5px;
}

@media (max-width:768px) {
    .model-content {
        width: 90%;
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

#dropDownCountryCode {
    padding: 0 25px !important;
    width: 100%;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #b9b9b9 !important;
    margin: 0;
}

#stickydropGrade, #stickydropSubject {
    width: 100%;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #b9b9b9 !important;
    margin: 0;
    padding: 0 25px !important;
}

#stickyBtnRegister {
    background-color: #68c721 !important;
    font-size: 18px;
    color: #fff;
}

.spanError {
    color: #ff0000 !important;
    font-size: 13px;
}

.model-close {
    position: absolute;
    left: 0;
    opacity: 0.9;
    background-color: #fff;
    top: -32px;
    width: 32px;
    text-align: center;
    height: 30px;
    border-radius: 5px;
    line-height: 30px;
    color: rgb(0, 0, 0);
    font-size: 28px;
    font-weight: bold;
}

    .model-close:hover,
    .model-close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }

.model .model-body {
    position: relative;
    padding: 20px 25px 50px;
}

    .model .model-body .badge-img {
        position: absolute;
        top: -60px;
        right: -30px;
        width: 18%;
    }

@media (max-width:768px) {
    .model .model-body .badge-img {
        position: absolute;
        top: -40px;
        right: -13px;
        width: 18%;
    }
}

.model .model-body h3 {
    margin-top: 10px;
    padding-bottom: 12px;
    width: 80%;
    font-size: 24px;
    font-weight: normal;
    line-height: 25px;
}

@media (max-width:768px) {
    .model .model-body h3 {
        width: 90%;
    }
}

.model .model-body h3 span {
    color: #f47408;
}

.model .model-body input {
    padding: 0 30px;
    width: 100%;
    height: 38px;
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid lightgray !important;
    margin: 0;
    display: inline-block !important;
}

.model .model-body .form-element {
    position: relative;
    height: 65px;
}

    .model .model-body .form-element .main-drop-box {
        width: 100%;
    }

        .model .model-body .form-element .main-drop-box .drop-box1 {
            width: 48%;
            display: inline-block;
        }

        .model .model-body .form-element .main-drop-box .drop-box2 {
            width: 49%;
            display: inline-block;
            margin-left: 2px;
        }

    .model .model-body .form-element img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 11px;
        left: 8px !important;
    }

#modalMobile {
    padding: 0 30px 0 100px;
}

.pl-0 {
    padding-left: 0px;
}

.pr-0 {
    padding-right: 0px;
}

.mr-0 {
    margin-right: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

@media (max-width:768px) {
    .pl-0 {
        padding-left: 0px;
        padding-right: 0;
    }

    .pr-0 {
        padding-right: 0px;
        padding-left: 0;
        margin-top: 5px;
    }
}

#select_staff,
#select_staff2 {
    border: none;
    width: 25px;
    background-color: #fff !important;
    padding: 0 0px;
    font-size: 15px;
    font-weight: normal;
    color: #000;
}

    #select_staff:hover,
    #select_staff2:hover {
        background-color: #fff !important;
    }

    #select_staff:focus,
    #select_staff:active,
    #select_staff2:focus,
    #select_staff2:active {
        background-color: #fff !important;
        border: none !important;
        outline: none;
    }


#txtMobile {
    padding: 0 30px 0 100px;
}

.ctry-drpdwn {
    display: inline-block;
    position: absolute !important;
    width: 10% !important;
    margin: 0 !important;
    font-size: 21px;
    border: none;
    left: 30px;
    background-color: transparent !important;
}

.model .model-body .form-group .grade {
    padding: 6px 25px;
}

.model .model-body .btn {
    background: #68c721;
    border: none;
    color: #fff;
    font-size: 16px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-weight: bold;
}
/*-------------- End Header Sticky Form ----------*/
.custom-dropdown {
    position: absolute !important;
    width: 11% !important;
    margin: 0 !important;
    font-size: 21px;
    border: none;
    left: 30px;
    background-color: transparent !important;
    display: inline-block;
}

    .custom-dropdown .dropbtn {
        font-size: 14px;
        background-color: transparent;
        color: #333;
        border: none;
    }

.custom-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px;
    z-index: 1;
}

    .custom-dropdown-content a {
        display: inline-block;
        width: 100%;
        padding: 4px 0;
        text-decoration: none;
        font-size: 16px;
        color: #333;
        transition: 0.3s;
    }

        .custom-dropdown-content a:hover {
            color: #fff;
            background-color: cornflowerblue;
            padding: 4px;
        }

.custom-dropdown:hover .custom-dropdown-content {
    display: block;
}
/*--------- Header Sticky Form Popup ----------*/
.model2 {
    display: none;
    position: fixed;
    padding-top: 170px;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
}

.model-content2 {
    position: relative;
    width: 40%;
    margin: auto;
    background-color: #fff;
    animation-name: animatetop;
    animation-duration: 0.4s;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    border-radius: 5px;
}

@media (max-width:768px) {
    .model-content2 {
        width: 90%;
        margin-top: 50px;
    }

    .user-reg-form .modal .modal-dialog .modal-content .modal-header h3 {
        font-size: 17px;
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.model-body2 {
    padding: 10px 16px 25px;
    text-align: center;
}

    .model-body2 h4 {
        font-size: 28px;
        color: #54ab12;
        padding-bottom: 10px;
        font-weight: 500;
    }

    .model-body2 p {
        font-size: 18px;
        width: 85%;
        text-align: center;
        margin: auto;
    }

    .model-body2 small {
        font-size: 14px;
    }

    .model-body2 .popup-close-sec {
        text-align: center;
    }

        .model-body2 .popup-close-sec .popup-close-btn {
            text-align: center;
            display: inline-block;
            padding: 6px 15px;
            background-color: #54ab12;
            color: #fff;
            margin-top: 15px;
            border-radius: 5px;
            text-decoration: none;
        }


/* junior hacker new popup - 20-10-2020 start */


.font-montserrat {
    font-family: 'Montserrat', sans-serif;
}


#juniorHackerCodingMaster {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    background-color: rgba(0,0,0,0.7);
    top: 0px;
}

    #juniorHackerCodingMaster .innerJuniorHackerPop {
        margin: auto;
        background: transparent;
        padding: 0;
        border-radius: 10px;
        position: relative;
    }

    #juniorHackerCodingMaster .junioirHackerLeft {
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        margin-top: 22px;
    }

    #juniorHackerCodingMaster .ask-header-popup-banner {
        position: relative;
        padding: 0;
    }

        #juniorHackerCodingMaster .ask-header-popup-banner img {
            width: 100%;
        }






    #juniorHackerCodingMaster .junioirHackerLeft h2,
    #juniorHackerCodingMaster .junioirHackerLeft p,
    #juniorHackerCodingMaster .junioirHackerLeft a {
        font-family: 'Montserrat', sans-serif;
    }

    #juniorHackerCodingMaster .junioirHackerLeft img.whiteLogoJh {
        width: 100px;
    }

    #juniorHackerCodingMaster .junioirHackerLeft h2 {
        margin: 0 0 10px;
        padding-bottom: 0;
        font-weight: 600;
        font-size: 30px;
        color: #fff;
        border-bottom: 0;
        line-height: 1.1;
    }

        #juniorHackerCodingMaster .junioirHackerLeft h2:before {
            display: none;
        }

    #juniorHackerCodingMaster .junioirHackerLeft p {
        margin-bottom: 30px;
        text-transform: capitalize;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.4;
    }

    #juniorHackerCodingMaster .junioirHackerLeft a {
        background-color: #FFE553;
        font-size: 18px;
        color: #000;
        text-decoration: none;
        padding: 15px 20px;
        border-radius: 8px;
        font-weight: 600;
        position: relative;
        z-index: 9;
    }

    #juniorHackerCodingMaster .cross {
        position: absolute;
        right: 10px;
        background-color: #FFE553;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        text-align: center;
        top: 10px;
        z-index: 1;
        cursor: pointer;
    }

/*------------- User Form -------------*/
.user-reg-form .modal {
    padding-right: 0 !important;
}

#modaldropGrade, #dropDownCountryCode, #modaldropSubject {
    padding: 5px 0 4px 25px;
}

.user-reg-form .modal .modal-dialog .modal-content .modal-body {
    padding: 26px !important;
}

    .user-reg-form .modal .modal-dialog .modal-content .modal-body .form-group {
        position: relative;
        padding-bottom: 9px;
    }

    .user-reg-form .modal .modal-dialog .modal-content .modal-body .mob-field-container .mob-field1 {
        width: 48%;
        float: left;
    }

    .user-reg-form .modal .modal-dialog .modal-content .modal-body .mob-field-container .mob-field2 {
        width: 50%;
        float: left;
        margin-left: 10px;
    }

@media (max-width:768px) {
    .user-reg-form .modal .modal-dialog .modal-content .modal-body .mob-field-container .mob-field1 {
        width: 100%;
        float: left;
    }

    .user-reg-form .modal .modal-dialog .modal-content .modal-body .mob-field-container .mob-field2 {
        width: 100%;
        float: left;
        margin-left: 0px;
    }
}

.user-reg-form .modal .modal-dialog .modal-content .modal-header .modal-title {
    display: inline-block;
}

.user-reg-form .modal .modal-dialog .modal-content .modal-header .close-modal {
    border: 1px solid #020202;
    border-radius: 50%;
    padding: 0;
    opacity: 0.70;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    position: relative;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    float: right;
    background-color: #fff;
    font-size: 20px;
    margin-top: 0 !important;
}

@media (max-width:768px) {
    .user-reg-form .modal .modal-dialog .modal-content .modal-header .close-modal {
        border: 1px solid #020202;
        border-radius: 50%;
        padding: 1px 5px;
        opacity: 0.70;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        position: relative;
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        font-size: 17px;
        line-height: 25px;
    }
}

.user-reg-form .modal .modal-dialog .modal-content .modal-header h3 {
    margin: 0;
}

.user-form-btn {
    background-color: #fc6b22;
    color: #ffffff;
    border: 1px solid #fc6b22;
    padding: 8px;
    font-size: 18px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-image: none;
}

    .user-form-btn:hover {
        color: #fff;
        opacity: 0.8;
    }

.user-reg-form .modal .modal-dialog .modal-content .modal-header .close:focus {
    outline: none;
}

.user-reg-form .modal .modal-dialog .modal-content .modal-header {
    padding: 15px 15px 15px 30px;
    color: #3e3e3e;
    border-bottom: 1px solid #e5e5e5;
    background-color: #ffffff;
    background-image: linear-gradient(to bottom, #fff 0%, #fff 100%);
    border-radius: 5px;
}

    .user-reg-form .modal .modal-dialog .modal-content .modal-header h3 span {
        color: #fc6b22;
    }

.user-reg-form .ctry-drpdwn1 {
    display: inline-block;
    position: absolute;
    font-size: 21px;
    border: none;
    left: 30px;
}

.user-reg-form #select_staff, .user-reg-form #select_staff2 {
    border: none;
    width: 45px;
    background-color: #fff !important;
    padding: 0 0px;
    font-size: 15px;
    font-weight: normal;
    color: #000;
}

.user-reg-form #admin_cal_list, .user-reg-form #admin_cal_list2 {
    position: absolute;
    top: 32px;
    left: -30px;
    height: 110px;
    overflow: auto;
}

.user-reg-form .modal .modal-dialog .modal-content .modal-body .form-group input {
    padding: 6px 30px;
}

.user-reg-form .modal .modal-dialog .modal-content .modal-body form {
    padding: 5px 15px 20px;
}

.user-reg-form .modal .modal-dialog .modal-content .modal-body .form-group img {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    left: 8px;
}

.user-reg-form .modal .modal-dialog .modal-content .modal-body .form-group .grade {
    padding: 6px 25px;
}

.user-reg-form #modalMobile {
    padding: 0 30px 0 10px;
}
/*------------- End User Form -------------*/
/*---------- Success Message Popup--------------*/

.success-msg-popup .modal-header, .success-msg-popup #leadMsgPopup .modal-header,
.success-msg-popup #alreadyLoggedInMsgPopup .modal-header {
    padding: 0 !important;
    border-bottom: none !important;
    background-color: #ffffff;
    background-image: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
}

    .success-msg-popup .modal-header .close, .success-msg-popup #leadMsgPopup .modal-header .close,
    .success-msg-popup #alreadyLoggedInMsgPopup .modal-header .close {
        border: 1px solid #020202;
        border-radius: 50%;
        padding: 3px 7px;
        opacity: 0.70;
        transition: 0.4s;
        position: relative;
        top: 0;
        left: 0;
        width: 35px;
        height: 35px;
    }

.success-msg-popup .modal .modal-dialog .modal-content .modal-body {
    text-align: center !important;
    padding: 15px 0 25px !important;
}

    .success-msg-popup .modal .modal-dialog .modal-content .modal-body .smp-txt1, .success-msg-popup .modal .modal-dialog .modal-content .modal-body .smp-txt1,
    .success-msg-popup .modal-body .smp-txt1 {
        color: #2f2d2e;
        font-size: 24px;
        margin-top: 15px;
        text-align: center;
    }

    .success-msg-popup .modal .modal-dialog .modal-content .modal-body .smp-txt2, .success-msg-popup .modal .modal-dialog .modal-content .modal-body .smp-txt2,
    .success-msg-popup .modal .modal-dialog .modal-content .modal-body .smp-txt2 {
        color: rgba(60, 60, 60, 0.6);
        font-size: 20px;
        margin-top: 10px;
        text-align: center;
    }

    .success-msg-popup .modal .modal-dialog .modal-content .modal-body .closeModalBtn {
        background-color: #fff;
        border: 1px solid #ec4712;
        color: #ec4712;
        padding: 5px 20px;
        border-radius: 5px;
        margin-top: 10px;
    }

#juniorHackerCodingMaster .cross span {
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    line-height: 30px;
}

#dropDownCountryCode2 {
    padding: 0 25px !important;
    width: 100%;
    height: 34px;
    border-radius: 5px;
    border: 1px solid #b9b9b9 !important;
    margin: 0;
}

#gradeField, #subjectField {
    padding: 5px 0 4px 25px;
}

@media(min-width:992px) {
    #juniorHackerCodingMaster .innerJuniorHackerPop {
        max-width: 500px;
        margin-top: 5%;
    }

    #juniorHackerCodingMaster .juniorMobileImg {
        display: none;
    }

    #juniorHackerCodingMaster .innerJuniorHackerPop .row {
        display: flex;
    }

    #juniorHackerCodingMaster .innerJuniorHackerPop .row {
        display: flex;
    }

        #juniorHackerCodingMaster .innerJuniorHackerPop .row .junioirHackerLeft {
            float: left;
            width: 50%;
        }

        #juniorHackerCodingMaster .innerJuniorHackerPop .row .junioirHackerRight {
            float: left;
            width: 50%;
            padding-left: 15px;
        }
}

@media(max-width:991px) {

    #juniorHackerCodingMaster .innerJuniorHackerPop .row {
        display: block;
    }

    #juniorHackerCodingMaster {
        padding: 30px 10px 30px 10px;
    }

        #juniorHackerCodingMaster .innerJuniorHackerPop::after {
            position: absolute;
            content: "";
            background-position: center;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 140px;
            display: none;
        }

        #juniorHackerCodingMaster .junioirHackerLeft {
            text-align: center;
            margin: 0;
        }

        #juniorHackerCodingMaster .junioirHackerRight {
            display: none;
        }

        #juniorHackerCodingMaster .junioirHackerLeft .juniorMobileImg {
            width: 200px;
            margin: 0 auto 30px;
        }

        #juniorHackerCodingMaster .junioirHackerLeft p {
            margin-bottom: 10px;
        }

        #juniorHackerCodingMaster .innerJuniorHackerPop {
            max-width: 500px;
        }
}

@media(max-width:767px) {
    #juniorHackerCodingMaster .junioirHackerLeft h2 {
        margin: 0 35px 10px;
        font-size: 22px;
    }
}

#footerBottomText {
    border-top: solid 1px #5f5c5c;
    padding: 14px 0 0 0;
}

    #footerBottomText .footerInnerText {
        margin-bottom: 20px;
        color: #fff;
    }

        #footerBottomText .footerInnerText h3 {
            margin-bottom: 10px;
            font-size: 18px;
        }

.themeOrange {
    color: #fc6b22;
}

#footerBottomText .footerInnerText p {
    font-size: 13px;
    color: #B2B2B2;
}



/******** fourm_btm_form********/
.emp-close {
    margin: 0px;
    z-index: 9999;
    right: 10px !important;
    top: 10px !important;
    position: absolute !important;
    left: auto !important;
}

.popup_signup .modal-body {
    padding: 0px;
}

.iti__selected-flag {
    height: 36px;
}

.error {
    color: red;
    width: 100%;
    margin: 5px 0;
    display: inline-block;
    font-size: 14px;
}

.btm_form {
    background: #F1B914;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
}

.fourm_btm_form {
    background: #fff;
    padding: 30px 40px 40px 40px;
}

    .fourm_btm_form .form-heading h3 {
        font-size: 22px;
        color: #425365;
        font-weight: 700;
        margin-bottom: 20px;
        line-height: 28px;
    }

        .fourm_btm_form .form-heading h3 span {
            color: #FC6B22;
        }

    .fourm_btm_form label {
        font-size: 14px;
        color: #1F353F;
        font-weight: 600;
    }

    .fourm_btm_form input, .fourm_btm_form select {
        border: 1px solid #b2b2b2;
        border-radius: 2px;
        margin-bottom: 0px !important;
        height: 38px;
        transition: all .2s linear 0s;
        padding: 0 10px;
        width: 100%;
        font-size: 14px;
    }

        .fourm_btm_form input[type=submit] {
            background: #f47408;
            color: #fff;
            padding: 8px 15px;
            border-radius: 4px;
            display: inline-block;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
            margin-top: 15px;
            border: 0;
            cursor: pointer;
        }

.btm_form_img img {
    display: block;
    margin: auto;
    margin-top: 25%;
}

.fourm_btm_form .fild_box {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
/******** fourm_btm_form ********/

.alertFadeBg {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    position: fixed;
    z-index: 1000000;
    top: 0;
}

.alertBg {
    max-width: 450px;
    height: auto;
    padding: 0;
    left: 0;
    right: 0px;
    margin: auto;
    position: fixed;
    top: 30%;
    z-index: 1000001;
    border-radius: 5px;
}

    .alertBg .alert {
        width: 100%;
        height: auto;
        background: #fff;
        border-radius: 5px;
        font-style: normal;
        padding: 0;
    }

        .alertBg .alert .heading {
            background: #fe980f;
            height: 28px;
            padding: 0 10px;
            line-height: 28px;
            color: #fff;
            font-size: 14px;
            border-radius: 5px 5px 0 0;
            width: 100% !important
        }

        .alertBg .alert p {
            padding: 5px 10px;
            color: #333;
            line-height: 18px;
            font-size: 13px;
        }

        .alertBg .alert table {
            margin: 0;
            padding: 0;
            width: 98%;
            margin: 0 auto;
            border-top: solid 1px #ccc;
            border-left: solid 1px #ccc;
        }

            .alertBg .alert table tr {
                margin: 0;
                padding: 0;
            }

                .alertBg .alert table tr th {
                    border-bottom: solid 1px #ccc;
                    padding: 0 5px;
                    height: 25px;
                    border-right: solid 1px #ccc;
                    color: #333;
                    line-height: 18px;
                    font-size: 12px;
                    font-weight: bold;
                    background: #f5f5f5;
                    text-align: left;
                }

                .alertBg .alert table tr td {
                    border-bottom: solid 1px #ccc;
                    padding: 0 5px;
                    height: 25px;
                    border-right: solid 1px #ccc;
                    color: #333;
                    line-height: 18px;
                    font-size: 12px;
                }

        .alertBg .alert .btnCont {
            height: 31px;
            width: 100%;
            border-top: solid 1px #ccc;
            margin-top: 5px;
            padding: 0 !important
        }

            .alertBg .alert .btnCont .btn {
                background: #ec7a00;
                height: 25px;
                line-height: 25px;
                border: none;
                min-width: 35px;
                width: auto;
                padding: 0 10px;
                text-align: center;
                font-size: 14px;
                color: #fff;
                margin-right: 10px;
                display: block;
                border-left: solid 1px #de7f00;
                border-right: solid 1px #de7f00;
                border-radius: 5px;
                margin-top: 3px !important;
                float: right;
                cursor: pointer;
                margin-bottom: 0;
                margin-left: 0;
                margin-bottom: 0px !important;
            }

                .alertBg .alert .btnCont .btn:hover {
                    opacity: 0.9;
                }

input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.alertBg .alert .closeAlrt {
    float: right;
    width: 15px;
    height: 15px;
    border-radius: 0 5px 0 5px;
    text-align: center;
    display: block;
    color: #303F5F;
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
    border-bottom: #000 solid 1px;
    border-left: #000 solid 1px;
    cursor: pointer;
    background: #fff;
}

    .alertBg .alert .closeAlrt:hover {
        background: #f5f5f5;
    }
/*alert popup*/



@media all and (max-width:767px) {
    .fourm_btm_form .form-heading h3 {
        font-size: 16px;
    }

    .fourm_btm_form {
        border-radius: 0;
        padding: 20px;
    }

    .btm_form_img img {
        display: none;
    }
}
/* new global css end */

/* one2one-style Page Start*/
.flt-right {
    float: right !important;
}

.pl-0 {
    padding-left: 0;
}

@media (max-width: 768px) {
    .pl-0 {
        padding-left: 15px !important;
    }
}

.abt-pl-0 {
    padding-left: 0;
}

.pr-0 {
    padding-right: 0;
}

@media (max-width: 768px) {
    .pr-0 {
        padding-right: 15px;
    }
}

@media (max-width: 768px) {
    .rpl-0 {
        padding-left: 0;
    }
}

@media (max-width: 768px) {
    .rpr-0 {
        padding-right: 0;
    }
}

.ptb-0100 {
    padding: 0 100px;
}

@media (max-width: 768px) {
    .ptb-0100 {
        display: none;
    }
}

.mtm-50 {
    margin-top: -50px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .mt-30 {
        margin-top: 10px;
    }
}

.pl-7 {
    padding-left: 7px;
}

.pr-7 {
    padding-right: 7px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-80 {
    margin-top: 80px !important;
}

@media (max-width: 768px) {
    .mt-80 {
        margin-top: 20px !important;
    }
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

a:hover {
    text-decoration: none;
}

a:focus {
    outline: none;
    text-decoration: none;
}

/*-------------- Subject Color--------------------*/
.phy {
    background-color: #ffecbb;
    color: #be8600 !important;
}

.chem {
    background-color: #ffcdc9;
    color: #fa5546 !important;
}

.bio {
    background-color: #b7fec8;
    color: #12942e !important;
}

.mat {
    background-color: #bee3ff;
    color: #1089e6 !important;
}

@media (min-width: 1200px) {
    .ask-wrapper .container {
        width: 1170px !important;
    }
}
/*------------------ Header Section ---------------*/
.header121-sec {
    width: 100%;
    background-image: url("https://www.askiitians.com/content/product-page/header-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0 47px;
}

@media (max-width: 768px) {
    .header121-sec {
        margin-bottom: 30px;
        padding: 50px 0 80px;
        background-position: bottom;
    }
}

.header121-sec .head-txt-sec {
    text-align: center;
}

@media (max-width: 768px) {
    .header121-sec .head-txt-sec {
        margin-top: 30px;
    }
}

.header121-sec .head-txt-sec .hts-txt {
    font-size: 44px;
    font-weight: bold;
    color: #ffffff;
    padding-bottom: 10px;
    line-height: 50px;
}

    .header121-sec .head-txt-sec .hts-txt span {
        color: #425365;
        font-size: 44px;
    }

@media (max-width: 768px) {
    .header121-sec .head-txt-sec .hts-txt {
        font-size: 36px;
        padding: 0px 20px;
        line-height: 35px;
    }
}

.header121-sec .head-txt-sec .hts-sub-txt {
    font-size: 24px;
    font-weight: normal;
    color: #ffffff;
}



.header121-sec .head-txt-sec .hts-sub-txt2 {
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    margin-top: 0px;
}

.header121-sec .head-txt-sec .hts-txt-tab {
    margin-top: 20px;
}

    .header121-sec .head-txt-sec .hts-txt-tab span {
        font-size: 16px;
        font-weight: normal;
        color: #425365;
        background-color: #ffe7d4;
        padding: 1px 8px;
        border-radius: 2px;
    }
@media (max-width: 768px) {
    .header121-sec .head-txt-sec .hts-sub-txt {
        font-size: 16px;
    }
    .header121-sec .head-txt-sec .hts-txt-tab {
        width: 75%;
        margin: 20px auto;
        line-height: 35px;
    }
}

.header121-sec .head-txt-sec .header-head-txt-arrow {
    text-align: center;
    padding-top: 20px;
}

    .header121-sec .head-txt-sec .header-head-txt-arrow .fa {
        font-size: 25px;
        color: #ffffff;
        -webkit-animation-name: animate-arrow;
        animation-name: animate-arrow;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

@-webkit-keyframes animate-arrow {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes animate-arrow {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

.header121-sec .head-txt-sec .hts-lt {
    font-size: 20px;
    font-weight: normal;
    color: #425365;
}

    .header121-sec .head-txt-sec .hts-lt span {
        color: #dd7c12;
    }

.header121-sec .head-txt-sec .hts-ep {
    font-size: 20px;
    font-weight: normal;
    color: #425365;
}

.header121-sec .head-txt-sec .hts-cta {
    padding: 18px 60px;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    color: #425365;
    text-transform: uppercase;
    margin-top: 45px;
    text-decoration: none;
    display: inline-block;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    transition: 0.5s;
    border: none;
    outline: none;
}
    .header121-sec .head-txt-sec .hts-cta:hover {
        background-color: #ffffffdb;
    }

    @media (max-width: 768px) {
        .header121-sec .header-img-sec {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .header121-sec .header-img-sec img {
        width: 100%;
    }
}

/*------------------ End Header Section ---------------*/
/*------------------ About The Course Section ---------------*/
.about-the-course {
    position: relative;
    background-color: #fff;
}

    .about-the-course .about-course-wrapper {
        border-radius: 12.4px;
        -webkit-box-shadow: 0 3.1px 21px 0 rgba(0, 0, 0, 0.25);
        box-shadow: 0 3.1px 21px 0 rgba(0, 0, 0, 0.25);
        border: dashed 1.6px #425365;
        background-color: #fff4cf;
        width: 100%;
        padding: 30px 35px;
        margin-top: -25px;
    }

@media (max-width: 768px) {
    .about-the-course .about-course-wrapper {
        padding: 30px 15px;
        margin-top: -50px;
    }
}

.about-the-course .about-course-wrapper .abt-head-txt {
    font-size: 36px;
    font-weight: bold;
    color: #425365;
    margin: 0;
    padding: 0;
}

@media (max-width: 768px) {
    .about-the-course .about-course-wrapper .abt-head-txt {
        font-size: 20px;
        text-align: center;
    }
}

.about-the-course .about-course-wrapper .abt-course-sec {
    margin-top: 40px;
}

    .about-the-course .about-course-wrapper .abt-course-sec .abt1121-mt {
        margin-top: 20px;
        display: block;
    }

@media (max-width: 768px) {
    .about-the-course .about-course-wrapper .abt-course-sec .abt1121-mt {
        margin-top: 0px;
    }
}

@media (max-width: 768px) {
    .about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec {
        margin-bottom: 20px;
    }
}

.about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec .abt-cs-img {
    background-image: url("https://files.askiitians.com/static_content/content/product-page/121-icons.png");
    width: 75px;
    height: 75px;
}

    .about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec .abt-cs-img img {
        width: 100%;
    }

.about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec #splt121Img1 {
    background-position: -10px -10px;
}

.about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec #splt121Img2 {
    background-position: -92px -10px;
}

.about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec #splt121Img3 {
    background-position: 164px -10px;
}

.about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec #splt121Img4 {
    background-position: 83px -10px;
}

.about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec #splt121Img5 {
    background-position: -10px -92px;
}

.about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec #splt121Img6 {
    background-position: -92px -92px;
}

.about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec .abt-cs-txt h3 {
    font-size: 22px;
    font-weight: 600;
    color: #425365;
    margin: 0;
    padding: 0;
}

@media (max-width: 768px) {
    .about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec .abt-cs-txt h3 {
        font-size: 14px;
    }
}

.about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec .abt-cs-txt p {
    opacity: 0.8;
    font-size: 16px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .about-the-course .about-course-wrapper .abt-course-sec .abt-cs-txt-sec .abt-cs-txt p {
        font-size: 12px;
        line-height: 20px;
        margin-top: 5px;
    }
}

/*------------------ End About The Course Section ---------------*/
/*------------------ Personal Assistance Section ---------------*/
.personal-assist {
    padding: 80px 0 60px;
}

@media (max-width: 768px) {
    .personal-assist {
        padding: 30px 0 0px;
    }
}

.personal-assist .personal-assist-wrapper .res-personal-aw-txt-sec {
    display: none;
}

    .personal-assist .personal-assist-wrapper .res-personal-aw-txt-sec h2 {
        font-size: 36px;
        color: #425365;
    }

@media (max-width: 768px) {
    .personal-assist .personal-assist-wrapper .res-personal-aw-txt-sec h2 {
        font-size: 20px;
        text-align: center;
    }
}

.personal-assist .personal-assist-wrapper .res-personal-aw-txt-sec h2 span {
    font-weight: bold;
}

@media (max-width: 768px) {
    .personal-assist .personal-assist-wrapper .res-personal-aw-txt-sec {
        display: block;
    }
}

.personal-assist .personal-assist-wrapper .personal-aw-img-sec img {
    width: 100%;
}

.personal-assist .personal-assist-wrapper .personal-aw-txt-sec h2 {
    font-size: 36px;
    color: #425365;
}

@media (max-width: 768px) {
    .personal-assist .personal-assist-wrapper .personal-aw-txt-sec h2 {
        display: none;
    }
}

.personal-assist .personal-assist-wrapper .personal-aw-txt-sec h2 span {
    font-weight: bold;
    font-size: 36px;
}

.personal-assist .personal-assist-wrapper .personal-aw-txt-sec p {
    font-size: 16px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .personal-assist .personal-assist-wrapper .personal-aw-txt-sec p {
        margin-top: 25px;
        text-align: justify;
        font-size: 14px;
    }
}

.personal-assist .personal-assist-wrapper .personal-aw-txt-sec .paw-txt-sec-list {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .personal-assist .personal-assist-wrapper .personal-aw-txt-sec .paw-txt-sec-list .paw-list-item {
        padding-bottom: 10px;
    }
}

.personal-assist .personal-assist-wrapper .personal-aw-txt-sec .paw-txt-sec-list .paw-list-item .fa {
    color: #f47408;
    font-size: 22px;
    line-height: 28px;
}

.personal-assist .personal-assist-wrapper .personal-aw-txt-sec .paw-txt-sec-list .paw-list-item p {
    font-size: 20px;
    font-weight: 500;
    color: #425365;
    margin-bottom: 0;
    margin-top: 0;
}

@media (max-width: 768px) {
    .personal-assist .personal-assist-wrapper .personal-aw-txt-sec .paw-txt-sec-list .paw-list-item p {
        font-size: 14px;
    }
}

/*------------------ End Personal Assistance Section ---------------*/
/*------------------ Design Course Section ---------------*/
.design-course-section {
    padding: 30px 0 40px;
}

@media (max-width: 768px) {
    .design-course-section {
        padding: 8px 0 0px;
    }
}

.design-course-section .design-cs-txt-sec .design-cs-head-txt h2 {
    font-size: 36px;
    color: #425365;
}
    .design-course-section .design-cs-txt-sec .design-cs-head-txt h2 span {
        font-weight: bold;
        font-size: 36px;
    }

@media (max-width: 768px) {
    .design-course-section .design-cs-txt-sec .design-cs-head-txt h2 {
        font-size: 20px;
        text-align: center;
        padding-bottom: 12px;
    }
        .design-course-section .design-cs-txt-sec .design-cs-head-txt h2 span {
            font-size: 20px;
        }
}



.design-course-section .design-cs-txt-sec .design-cs-head-txt p {
    font-size: 16px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .design-course-section .design-cs-txt-sec .design-cs-head-txt p {
        font-size: 14px;
        text-align: justify;
    }
}

.design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic li {
    position: relative;
}

    .design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic li span {
        display: inline-block;
        font-weight: 800;
        width: 2em;
        height: 2em;
        text-align: center;
        line-height: 2em;
        border-radius: 1em;
        background: #ffffff;
        color: #425365;
        border: 2px solid #f47408;
        position: relative;
    }

    .design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic li small {
        margin-left: 8px;
        font-size: 18px;
        font-weight: 500;
        color: #425365;
    }

@media (max-width: 768px) {
    .design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic li small {
        font-size: 14px;
    }
}

.design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic li::before {
    content: '';
    position: absolute;
    background: #425365;
    z-index: 0;
}

.design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic.vertical {
    padding: 0;
    margin: 0;
}

    .design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic.vertical li {
        list-style-type: none;
        text-align: left;
    }

        .design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic.vertical li span {
            margin: 10px 0;
            z-index: 2;
        }

        .design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic.vertical li::before {
            top: -35px;
            left: 13px;
            width: 2px;
            height: 4em;
        }

.design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic li:first-child::before {
    display: none;
}

.design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic .active {
    background: dodgerblue;
}

    .design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic .active ~ li {
        background: #f47408;
    }

        .design-course-section .design-cs-txt-sec .design-cs-head-txt .design-cs-list-item .list-ic .active ~ li::before {
            background: #f47408;
        }

.design-course-section .design-cs-img-sec {
    text-align: end;
    margin-top: 35px;
}

@media (max-width: 768px) {
    .design-course-section .design-cs-img-sec {
        display: none;
    }
}

@media (max-width: 768px) {
    .design-course-section .design-cs-img-sec img {
        width: 100%;
    }
}

/*------------------ End Design Course Section ---------------*/
/*------------------ Batch Details Section ---------------*/
.batch-details {
    padding: 50px 0 35px;
    background-color: #fff;
}

@media (max-width: 768px) {
    .batch-details {
        padding: 40px 0 0;
    }
}

.batch-details .bd-head-sec {
    text-align: center;
}

@media (max-width: 768px) {
    .batch-details .bd-head-sec {
        padding-bottom: 15px;
    }
}

.batch-details .bd-head-sec h2 {
    font-size: 36px;
    font-weight: bold;
    color: #425365;
}

@media (max-width: 768px) {
    .batch-details .bd-head-sec h2 {
        font-size: 20px;
        width: 90%;
        margin: auto;
    }
}

@media (max-width: 768px) {
    .batch-details .bd-img-sec img {
        display: none;
    }
}

.batch-details .bd-img-sec {
    text-align: center;
    padding-top: 20px;
}

@media (width: 768px) {
    .batch-details .bd-img-sec {
        display: none;
    }
}

.batch-details .res-bd-img-sec {
    display: none;
}

@media (max-width: 768px) {
    .batch-details .res-bd-img-sec {
        display: block;
    }
}

.batch-details .res-bd-img-sec img {
    display: none;
}

@media (max-width: 768px) {
    .batch-details .res-bd-img-sec img {
        display: block;
        margin: auto;
    }
}

.batch-details .batch-comparison-sec {
    text-align: center;
    padding-top: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-sec {
        padding-top: 15px;
        padding: 8px 10px;
    }
}

.batch-details .batch-comparison-sec .batch-comparison-cta {
    padding: 10px;
    width: 22%;
    display: inline-block;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px #2d4054;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#c5c5c5), color-stop(#ffffff), color-stop(#ffffff), color-stop(#ffffff), to(#cdcccc));
    background-image: linear-gradient(to top, #c5c5c5, #ffffff, #ffffff, #ffffff, #cdcccc);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #2d4054;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-sec .batch-comparison-cta {
        width: 70%;
        font-size: 12px;
    }
}

.batch-details .batch-comp-cta-sec {
    text-align: center;
    padding-top: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .batch-details .batch-comp-cta-sec {
        padding-top: 30px;
    }
}

.batch-details .batch-comp-cta-sec .bookfreedemo-cta {
    padding: 18px 60px;
    border-radius: 4px;
    background-color: #ff6a0b;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    transition: 0.5s;
    border: none;
    outline: none;
}
    .batch-details .batch-comp-cta-sec .bookfreedemo-cta:hover {
        background-color: #ff6a0bd9;
    }

    @media (max-width: 768px) {
        .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body {
        padding: 0;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .close {
    font-size: 21px;
    font-weight: 700;
    top: -19px;
    background-color: #fff;
    right: -19px;
    line-height: 1;
    background-color: #fff !important;
    position: absolute;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 1;
    width: 22px;
    height: 22px;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper {
        margin-top: 40px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl {
    margin-top: 0px;
    position: relative;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table-striped > tbody > tr:nth-of-type(odd) {
        background-color: #fefaf7;
        text-align: center;
    }

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table-striped > tbody > tr:nth-of-type(even) {
        background-color: #fff;
        text-align: center;
    }

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table thead tr th {
        font-size: 14px;
        width: 37%;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead {
    background-color: #fff4cf;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead tr th {
        width: 32%;
        text-align: center;
        padding: 10px;
    }

        .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead tr th span {
            display: block;
            font-size: 22px;
            font-weight: bold;
            color: #007f00;
        }

        .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead tr th h3 {
            font-size: 25px;
            font-weight: bold;
            color: #425365;
            margin: 0;
        }

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td {
    vertical-align: middle;
    padding: 4px 8px;
    height: 6vh;
    font-weight: 500;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .fa-check-circle {
        color: #3bb54a;
        font-size: 24px;
    }

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .fa-times-circle {
        color: #f44336;
        font-size: 24px;
    }

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td {
        width: 37%;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td p {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td p {
        font-size: 12px;
        line-height: 18px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td small {
    opacity: 0.8;
    font-size: 14px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td small {
        font-size: 12px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .del-price {
    display: inline-block;
    padding: 5px 15px;
    background-color: #ffe0e0;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    width: 61%;
    color: #ff0000;
    text-decoration: line-through;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .del-price {
        font-size: 12px;
        width: 92%;
        padding: 5px 10px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .del-price span,
.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .del-price .fas {
    color: #425365;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .note-txt {
        padding: 5px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .note-txt sup {
    color: #007f00;
    font-weight: bold;
    font-size: 13px;
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list {
    width: 30%;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table {
        position: absolute;
        width: 30%;
        top: -1px;
        right: 20px;
        border-radius: 6px;
        -webkit-box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.25);
        box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        border: none !important;
    }

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table {
        position: absolute;
        width: 34%;
        top: -47px;
        right: -10px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr {
    text-align: center;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th {
        vertical-align: middle;
        padding: 11px;
    }

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th {
        padding: 0;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .excl-txt {
    border: dashed 1px #007f00;
    background-color: #dcf3dc;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    color: #007f00;
    padding: 7px 7px 10px;
    margin-top: 5px;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .excl-txt {
        font-size: 14px;
        padding: 5px;
        text-align: center;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .havl-txt {
    display: block;
    width: 70%;
    margin: auto;
    padding: 2px 7px 2px 5px;
    border-radius: 10px;
    background-color: #e6e6f2;
    font-size: 12px;
    font-weight: 500;
    color: #4a4aff;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .havl-txt {
        width: 95%;
        font-size: 10px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .havl-txt .fa {
    font-size: 8px;
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .spl-fee {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    color: #425365;
    text-align: center;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .spl-fee {
        font-size: 14px;
        margin-bottom: 2px;
        margin-top: 0;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr {
    text-align: center;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td {
        vertical-align: middle;
        padding: 5px 0;
    }

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td {
        padding: 10px 0;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td .new-price {
    display: inline-block;
    padding: 5px 15px;
    background-color: #ffe0e0;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    width: 61%;
    color: #007f00;
    background-color: #dcf3dc;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td .new-price {
        width: 90%;
        padding: 3px 5px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td .jh-new-price {
    padding: 5px;
    width: 40%;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td .jh-new-price {
        padding: 0;
        width: 44%;
        font-size: 12px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .jh-table {
    position: absolute;
    width: 35%;
    top: -101px;
    right: 10px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .jh-table {
        position: absolute;
        width: 39%;
        top: -46px;
        right: -10px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content h3 {
    font-size: 25px;
    font-weight: bold;
    color: #425365;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content h3 {
        font-size: 16px;
        padding: 0 5px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content .table thead th {
    font-size: 16px;
    font-weight: 600;
    color: #425365;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content .table thead th {
        font-size: 12px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content .table tbody td {
    font-size: 16px;
    font-weight: 600;
    color: #7c2eff;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content .table tbody td {
        font-size: 12px;
    }
}

/*------------------ End Batch Details Section ---------------*/
/*------------------ We Provide Section ---------------*/
.we-provide-sec {
    padding: 60px 0;
    background-color:#fff;
}

@media (max-width: 768px) {
    .we-provide-sec {
        padding: 60px 0 22px;
    }
}

.we-provide-sec .we-provide-head-txt {
    text-align: center;
    padding-bottom: 30px;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-head-txt {
        padding-bottom: 22px;
    }
}

.we-provide-sec .we-provide-head-txt h2 {
    font-size: 36px;
    font-weight: bold;
    color: #425365;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-head-txt h2 {
        font-size: 20px;
    }
}

.we-provide-sec .we-provide-wrapper {
    background-image: url("https://files.askiitians.com/static_content/content/product-page/we-provide-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
}

@media (max-width: 786px) {
    .we-provide-sec .we-provide-wrapper {
        background-image: none;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper {
    padding: 43px 0;
}

@media (max-width: 786px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper {
        padding: 0;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-mt-12 {
    margin-top: 12px;
}

@media (max-width: 786px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-mt-12 {
        margin-top: -5px;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper {
    margin: 0px 40px 15px -30px;
}

@media (max-width: 786px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper {
        margin: 0px 0px 15px 0px;
    }
}

@media (max-width: 786px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-pr-5 {
        padding-right: 5px;
    }
}

@media (max-width: 786px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-pr-8 {
        padding-right: 8px;
    }
}

@media (max-width: 786px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-pl-5 {
        padding-left: 5px;
    }
}

@media (max-width: 786px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-pl-0 {
        padding-left: 0px;
    }
}

@media (max-width: 786px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-remove-res {
        display: none;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-remove-res .wps-remove-res-box {
    background-color: transparent;
}

    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-remove-res .wps-remove-res-box .wps-remove-res-img {
        margin-top: 15px;
    }

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box {
    text-align: center;
    background-color: #f3f3f3;
    padding: 12px 0;
    border-radius: 5px;
    height:26vh;
}

    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box .wps-inner-box-icon {
        background-image: url("https://files.askiitians.com/static_content/content/product-page/121-icons.png");
        width: 75px;
        height: 75px;
    }

    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img7 {
        background-position: -9px -172px;
        margin-left: 40px;
    }

@media (max-width: 768px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img7 {
        margin-left: 14px;
    }
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box {
        height: 22vh;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img8 {
    background-position: -91px -172px;
    margin-left: 40px;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img8 {
        margin-left: 14px;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img9 {
    background-position: -173px -172px;
    margin-left: 40px;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img9 {
        margin-left: 14px;
    }
}


.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img10 {
    background-position: -255px -172px;
    margin-left: 40px;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img10 {
        margin-left: 14px;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img11 {
    background-position: -9px -254px;
    margin-left: 40px;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img11 {
        margin-left: 14px;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img12 {
    background-position: -91px -254px;
    margin-left: 40px;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img12 {
        margin-left: 14px;
    }
}
@media (min-device-width : 768px) and (max-device-width : 1024px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img7,
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img8,
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img9,
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img10,
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img11,
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box #splt121Img12 {
        margin-left: 73px;
    }

    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box {
        height: 14vh;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box .wps-inner-box-txt {
    padding: 0 21px;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box .wps-inner-box-txt {
        padding: 0;
    }
}

.we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box .wps-inner-box-txt p {
    font-size: 14px;
    line-height: 22px;
    padding-top: 12px;
    color: #425365;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-wrapper .we-provide-inner-wrapper .wps-inner-wrapper .wps-inner-box .wps-inner-box-txt p {
        font-size: 12px;
    }
}

/*------------------ End We Provide Section ---------------*/
/*------------------ Test Series Section ---------------*/
.test-series {
    padding: 50px 0 30px;
}

    .test-series .test-series-head-txt h2 {
        font-size: 36px;
        font-weight: bold;
        color: #425365;
    }

/*------------------ End Test Series Section ---------------*/
/*------------------ Demo Class Section ---------------*/
.demo-class {
    padding: 40px 0;
}

@media (max-width: 768px) {
    .demo-class {
        padding: 0px 0 0;
    }
}

.demo-class .demo-class-head-txt h2 {
    font-size: 36px;
    font-weight: bold;
    color: #425365;
}

@media (max-width: 768px) {
    .demo-class .demo-class-head-txt h2 {
        font-size: 20px;
    }
}

.demo-class .demo-class-wrapper {
    padding: 30px 0;
}

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper {
        padding: 20px 0;
    }
}

.demo-class .demo-class-wrapper .dc-main-video-sec {
    border-radius: 5px;
    background-color: #f3f3f3;
}

    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-container iframe {
        width: 100%;
        height: auto;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-container iframe {
        height: auto;
    }
}

.demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec {
    padding: 10px 10px 20px;
}

    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec p {
        font-size: 17px;
        font-weight: 500;
        color: #425365;
        margin-bottom: 0;
    }

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec p {
        font-size: 16px;
        line-height: 24px;
    }
}

.demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec small {
    font-size: 17px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec small {
        font-size: 12px;
    }
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec {
    height: 51vh;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 5px;
    margin: 0 10px;
}

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec {
        height: 37vh;
    }
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list a {
    background-color: #f3f3f3;
    text-decoration: none;
    display: inline-block;
    margin-top: 5px;
    border-radius: 5px;
    margin-right: 10px;
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-thumbnail {
    padding: 10px;
}

    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-thumbnail img {
        width: 100%;
    }

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt {
    padding-top: 10px;
}

    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        color: #425365;
    }

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt p {
        font-size: 12px;
        line-height: 13px;
    }
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt small {
    font-size: 14px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt small {
        font-size: 10px;
    }
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .show-more-video-btn-sec {
    margin-top: 15px;
}

    .demo-class .demo-class-wrapper .dc-video-list-wrapper .show-more-video-btn-sec .show-more-video-btn {
        width: 95%;
        padding: 15px;
        background-color: #f3f3f3;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
        font-weight: normal;
        color: #242424;
        display: block;
        margin: auto;
    }

/*------------------ End Demo Class Section ---------------*/
/*------------------ Academic Expert Section ---------------*/
.academic-expert {
    padding: 15px 0 15px;
    background-color: #fff;
}

@media (max-width: 768px) {
    .academic-expert {
        padding: 5px 0 50px;
    }
}

.academic-expert .academic-expert-head-txt {
    text-align: center;
    padding-bottom: 20px;
}

    .academic-expert .academic-expert-head-txt h2 {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        color: #425365;
        margin: 0;
    }

@media (max-width: 768px) {
    .academic-expert .academic-expert-head-txt h2 {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .academic-expert .desk--aew-view {
        display: none;
    }
}

.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec {
    margin: 10px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

    .academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt {
        padding: 7px;
        border: 1px solid #e6e6e6;
        text-align: center;
    }

@media (max-width: 768px) {
    .academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt {
        padding: 0;
    }
}

.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt .cb-teacher-name {
    margin-bottom: 0;
}

.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt .cb-sub-name {
    margin-bottom: 0;
}

.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt .cb-colg-name {
    margin-bottom: 0;
}

.academic-expert .owl-theme .owl-dots .owl-dot.active span,
.academic-expert .owl-theme .owl-theme .owl-dots .owl-dot:hover span {
    background: #f47408;
}

@media (max-width: 768px) {
    .academic-expert .owl-theme .owl-dots,
    .academic-expert .owl-theme .owl-theme .owl-nav {
        margin-top: 30px;
    }
}

.academic-expert .owl-theme .owl-nav {
    position: relative;
}

@media (max-width: 768px) {
    .academic-expert .owl-theme .owl-nav {
        display: none;
    }
}

.academic-expert .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: -230px;
    left: -35px;
    background-color: #fff;
    border-radius: 50px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

    .academic-expert .owl-theme .owl-nav .owl-prev:focus {
        outline: none;
    }

    .academic-expert .owl-theme .owl-nav .owl-prev .fa {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 38px;
        color: #f47408;
    }

        .academic-expert .owl-theme .owl-nav .owl-prev .fa:hover {
            color: #f47408;
        }

.academic-expert .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: -230px;
    right: -35px;
    background-color: #fff;
    border-radius: 50px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

    .academic-expert .owl-theme .owl-nav .owl-next:focus {
        outline: none;
    }

    .academic-expert .owl-theme .owl-nav .owl-next .fa {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        color: #f47408;
    }

        .academic-expert .owl-theme .owl-nav .owl-next .fa:hover {
            color: #f47408;
        }

.academic-expert .academic-expert-wrapper {
    padding: 40px 0 30px;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper {
        padding: 10px 0 15px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container {
    border-radius: 5px;
    -webkit-box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container {
        margin: 5px 0;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-img {
    width: 100%;
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt {
    text-align: center;
}

    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt {
        border-bottom: 2px solid lightgray;
        padding: 20px 0;
    }

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt {
        padding: 20px 0 0;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-teacher-name {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    color: #425365;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-teacher-name {
        font-size: 15px;
        line-height: 18px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-subject-name {
    font-size: 14px;
    font-weight: 500;
    color: #425365;
    padding: 3px 10px;
    border-radius: 5px;
    margin-top: 4px;
    display: inline-block;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-subject-name {
        font-size: 12px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-exp {
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
    color: #425365;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-exp {
        font-size: 14px;
        margin-bottom: 5px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt {
    padding: 15px 0 10px;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt {
        padding: 5px 0 0px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt .ace-qualif {
    font-size: 20px;
    font-weight: 500;
    color: #425365;
    background-color: #dbe2eb;
    width: 90%;
    border-radius: 5px;
    padding: 6px 8px;
    text-align: center;
    margin: auto;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt .ace-qualif {
        font-size: 17px;
        padding: 1px 8px;
        margin-top: 3px;
    }
}

.academic-expert .ac-footer-cta {
    text-align: center;
    margin-top: 60px;
}

    .academic-expert .ac-footer-cta .acf-btn {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        padding: 16px 36px;
        border-radius: 4px;
        background-color: #ff6a0b;
    }

/*------------------ End Academic Expert Section ---------------*/
/*------------------ How to teach Section ---------------*/
.how-teach {
    background-image: url("https://files.askiitians.com/static_content/content/product-page/how-teach-bg.svg");
    background-repeat: no-repeat;
    height: 100%;
    padding: 30px 0;
    background-size: cover;
    margin-top: 55px;
    background-position: center;
}

@media (max-width: 768px) {
    .how-teach {
        margin-top: -15px;
    }
}

.how-teach .how-teach-wrapper {
    padding: 60px 0 70px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec {
        margin-top: 30px;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec h3 {
    background-image: -webkit-gradient(linear, left top, right top, from(#f99c3e), to(#ff564d));
    background-image: linear-gradient(90deg, #f99c3e, #ff564d);
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec h3 {
        font-size: 20px;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec p {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec p {
        display: none;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 25px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec ul {
        padding-top: 8px;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec ul li {
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    margin-top: 8px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec ul li {
        font-size: 14px;
        margin-top: 1px;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec ul li .fas {
    color: #eb5757;
    font-size: 14px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec ul li .fas {
        margin-top: 12px;
    }
}

.how-teach .how-teach-wrapper .how-teach-img-sec {
    margin-top: 15px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-img-sec {
        margin-top: 25px;
    }
}

.how-teach .how-teach-wrapper .how-teach-img-sec img {
    width: 100%;
}

/*------------------ End How to teach Section ---------------*/
/*------------------ You Believe Section ---------------*/
.you-believe {
    padding: 35px 0 110px;
    background-color: #fff;
}

@media (max-width: 768px) {
    .you-believe {
        padding: 0 0 90px;
        margin-top: -20px;
    }
}

.you-believe .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: 140px;
    left: 155px;
    background-color: #fff;
    border-radius: 50px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.you-believe .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 140px;
    right: 155px;
    background-color: #fff;
    border-radius: 50px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
    .you-believe .owl-theme .owl-nav .owl-prev, .you-believe .owl-theme .owl-nav .owl-next {
        display: none;
    }
}

.you-believe .owl-theme .owl-nav .owl-prev:focus, .you-believe .owl-theme .owl-nav .owl-next:focus {
    outline: none;
}

.you-believe .owl-theme .owl-nav .owl-prev .fa, .you-believe .owl-theme .owl-nav .owl-next .fa {
    font-size: 20px;
    width: 35px;
    height: 35px;
    line-height: 38px;
    color: #f47408;
}

.you-believe .owl-theme .owl-dots .owl-dot.active span, .you-believe .owl-theme .owl-theme .owl-dots .owl-dot:hover span {
    background: #f47408;
}

.you-believe .you-believe-head-txt h2 {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #425365;
}

@media (max-width: 768px) {
    .you-believe .you-believe-head-txt h2 {
        font-size: 20px;
    }
}

.you-believe .you-believe-cta-sec {
    text-align: center;
    padding-top: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

    .you-believe .you-believe-cta-sec .bookfreedemo-cta {
        padding: 18px 60px;
        border-radius: 4px;
        background-color: #ff6a0b;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        margin-top: 20px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
        transition: 0.5s;
        border: none;
        outline: none;
    }
        .you-believe .you-believe-cta-sec .bookfreedemo-cta:hover {
            background-color: #ff6a0bd9;
        }

        .you-believe .you-believe-carousal {
            padding: 40px 0 20px;
        }

@media (max-width: 768px) {
    .you-believe .you-believe-carousal {
        padding: 25px 0 10px;
    }
    .you-believe .you-believe-cta-sec {
        padding-top: 0px;
    }
}

.you-believe .you-believe-carousal .yb-warpper {
    width: 70%;
    margin: 5px auto;
    border-radius: 15px;
    -webkit-box-shadow: 0px 2px 18px rgba(95, 95, 95, 0.25);
    box-shadow: 0px 2px 18px rgba(95, 95, 95, 0.25);
    padding: 15px;
}

@media (max-width: 768px) {
    .you-believe .you-believe-carousal .yb-warpper {
        width: 90%;
    }
}

.you-believe .you-believe-carousal .yb-warpper .ybw-img1 {
    padding-top: 27%;
}

@media (max-width: 768px) {
    .you-believe .you-believe-carousal .yb-warpper .ybw-img1 {
        padding-top: 5%;
        padding-bottom: 4%;
    }
}

.you-believe .you-believe-carousal .yb-warpper .ybw-img1 img {
    width: 100%;
}

.you-believe .you-believe-carousal .yb-warpper .ybw-img2 img {
    width: 100%;
}

/*------------------ End You Believe Section ---------------*/
/*================== Testimonial Section =================*/
.testimonial-121 {
    background-color: #2c435b;
    padding: 30px 0;
    margin-bottom: 30px;
}

    .testimonial-121 .head-sec-121 {
        text-align: center;
    }

@media (max-width: 768px) {
    .testimonial-121 .head-sec-121 {
        width: 80%;
        margin: auto;
    }
}

.testimonial-121 .head-sec-121 h2 {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}

@media (max-width: 768px) {
    .testimonial-121 .head-sec-121 h2 {
        font-size: 20px;
    }
}

.testimonial-121 .head-sec-121 hr {
    margin: 15px auto 20px;
    border: 0;
    border-top: 4px solid #f7aa21;
    width: 10%;
}

.testimonial-121 .stud-testimonial-121-wrapper {
    padding: 30px 0;
}

    .testimonial-121 .stud-testimonial-121-wrapper .owl-theme .owl-dots .owl-dot.active span, .testimonial-121 .stud-testimonial-121-wrapper .owl-theme .owl-dots .owl-dot:hover span {
        background: #fc6118;
    }

    .testimonial-121 .stud-testimonial-121-wrapper .stud-testimonial-121-container .stdu-ttml-121-img .stud-testomonial-video {
        cursor:pointer;
    }

    .testimonial-121 .stud-testimonial-121-wrapper .stud-testimonial-121-container .stdu-ttml-121-img .stud-caption-txt {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
    }

@media (max-width: 768px) {
    .testimonial-121 .stud-testimonial-121-wrapper .stud-testimonial-121-container .stdu-ttml-121-img  .stud-caption-txt {
        font-size: 12px;
        line-height: 16px;
    }
}

.testimonial-121 .stud-testimonial-121-wrapper .stud-testimonial-121-container .stdu-ttml-121-img .stud-caption-txt span {
    color: #fc6118;
}

.testimonial-121 .stud-testimonial-121-wrapper .stud-testimonial-121-container .stdu-ttml-121-txt {
    margin-right: 18px;
}

    .testimonial-121 .stud-testimonial-121-wrapper .stud-testimonial-121-container .stdu-ttml-121-txt p {
        color: #fff;
        line-height: 22px;
        font-size: 14px;
        text-align: justify;
    }



.testimonial-121 .stud-testimonial-121-wrapper .stud-testimonial-121-container .stdu-ttml-121-txt p .fa {
    font-size: 22px;
    color: #fc6118;
}

.testimonial-121-video-modal .student-testimonial .modal .modal-body {
    background-color: #fff;
    border-radius: 5px;
}

    .testimonial-121-video-modal .student-testimonial .modal .modal-body .close {
        position: absolute;
        top: 16px;
        right: 16px !important;
        background-color: white;
        color: #333;
        -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
        width: 30px;
        height: 28px;
        border-radius: 5px;
        opacity: 1;
    }
@media (max-width: 768px) {
    .testimonial-121 .stud-testimonial-121-wrapper .stud-testimonial-121-container .stdu-ttml-121-txt p {
        font-size: 12px;
        line-height: 1.5;
    }

    .testimonial-121-video-modal .student-testimonial .modal .modal-body .close {
        position: absolute;
        top: 1px;
        right: 0px !important;
    }
}

/*================== End Testimonial Section =================*/
/*----------------- Result Number Section -------------------*/
.number-result-sec {
    padding: 60px 0 30px;
    background-color: #fff;
}

    .number-result-sec .number-result-wrapper {
        margin: 0 100px;
        border-bottom: 5px solid #fc6118;
        border-radius: 6px;
        -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
    }

@media (max-width: 768px) {
    .number-result-sec .number-result-wrapper {
        margin: 0;
    }
}

.number-result-sec .number-result-wrapper .nrs-wrapper {
    text-align: center;
}

    .number-result-sec .number-result-wrapper .nrs-wrapper .nrs-icon {
        text-align: center;
        background-image: url("https://files.askiitians.com/static_content/content/product-page/121-icons.png");
        width: 75px;
        height: 65px;
    }

    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img13 {
        background-position: 0px -330px;
        margin-left: 65px;
    }

    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img14 {
        background-position: -66px -330px;
        margin-left: 65px;
    }

    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img15 {
        background-position: -131px -330px;
        margin-left: 65px;
    }

    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img16 {
        background-position: -198px -330px;
        margin-left: 65px;
    }

    .number-result-sec .number-result-wrapper .nrs-wrapper .nrs-txt {
        text-align: center;
    }

        .number-result-sec .number-result-wrapper .nrs-wrapper .nrs-txt h3 {
            font-size: 41.2px;
            font-weight: bold;
            color: #fc6118;
        }

@media (max-width: 768px) {
    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img13,
    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img14,
    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img15,
    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img16 {
        margin-left: 45px;
    }
    .number-result-sec .number-result-wrapper .nrs-wrapper .nrs-txt h3 {
        font-size: 31.5px;
    }
}
@media (min-device-width : 768px) and (max-device-width : 1024px) {
    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img13,
    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img14,
    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img15,
    .number-result-sec .number-result-wrapper .nrs-wrapper #splt121Img16 {
        margin-left: 124px;
    }
} .number-result-sec .number-result-wrapper .nrs-wrapper .nrs-txt p {
    font-size: 15.5px;
    font-weight: normal;
    color: #18191f;
}

@media (max-width: 768px) {
    .number-result-sec .number-result-wrapper .nrs-wrapper .nrs-txt p {
        font-size: 12px;
    }
}

/*----------------- End Result Number Section -------------------*/
/*----------------- Student FAQ Section -------------------*/
.stud-faq-section {
    padding: 55px 0 40px;
}

    .stud-faq-section .ycs-head-txt h2 {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        color: #425365;
    }

@media (max-width: 768px) {
    .stud-faq-section .ycs-head-txt h2 {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .stud-faq-section {
        padding: 30px 0 40px;
    }
}

.stud-faq-section .stud-faq-wrapper {
    margin-top: 50px;
}

    .stud-faq-section .stud-faq-wrapper .panel-title > a:before {
        float: right !important;
        font-family: FontAwesome;
        content: "\f068";
        padding-right: 5px;
    }

    .stud-faq-section .stud-faq-wrapper .panel-title > a.collapsed:before {
        float: right !important;
        content: "\f067";
    }

    .stud-faq-section .stud-faq-wrapper .panel-title > a:hover,
    .stud-faq-section .stud-faq-wrapper .panel-title > a:active,
    .stud-faq-section .stud-faq-wrapper .panel-title > a:focus {
        text-decoration: none;
    }

    .stud-faq-section .stud-faq-wrapper .panel-default > .panel-heading {
        color: #333;
        background-color: #f5f5f5;
        border-bottom: 1px solid #f1eded;
        background-color: #ffffff;
        border: none;
        padding: 15px 24px 15px 15px;
    }

    .stud-faq-section .stud-faq-wrapper .panel-default {
        border-color: #fff;
        -webkit-box-shadow: 0 10px 29px 0 rgba(0, 0, 0, 0.06);
        box-shadow: 0 10px 29px 0 rgba(0, 0, 0, 0.06);
    }

    .stud-faq-section .stud-faq-wrapper .panel-default {
        border-color: #fff;
    }

    .stud-faq-section .stud-faq-wrapper .panel-group .panel + .panel {
        margin-top: 15px;
    }

    .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .list-group,
    .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .panel-body {
        border-top: none;
        padding: 0px 16px 15px;
    }

        .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .list-group ul,
        .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .panel-body ul {
            list-style-type: none;
        }
        @media (max-width:768px){
            .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .list-group,
            .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .panel-body {
                text-align: justify;
            }
        }

/*----------------- End Student FAQ Section -------------------*/
/*----------------- Footer Learn More Section -------------------*/
.footer-learn-more-section {
    margin: auto;
    width: 85%;
    -webkit-box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 45px;
}

@media (max-width: 768px) {
    .footer-learn-more-section {
        width: 100%;
    }
}

.footer-learn-more-section .flms-wrapper {
    border-radius: 8px;
    border: 2px solid #333;
}

    .footer-learn-more-section .flms-wrapper .flms-txt-sec {
        padding: 40px 0 40px 30px;
    }

@media (max-width: 768px) {
    .footer-learn-more-section .flms-wrapper .flms-txt-sec {
        padding: 30px 20px;
    }
}

.footer-learn-more-section .flms-wrapper .flms-txt-sec h3 {
    font-size: 28px;
    font-weight: bold;
    color: #21354b;
    margin: 0;
    padding: 0;
}

@media (max-width: 768px) {
    .footer-learn-more-section .flms-wrapper .flms-txt-sec h3 {
        font-size: 20px;
    }
}

.footer-learn-more-section .flms-wrapper .flms-txt-sec h3 span {
    color: #ff6a0b;
}

.footer-learn-more-section .flms-wrapper .flms-txt-sec .flms-cta {
    margin-top: 20px;
    padding: 16px 15px;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 21px 0 rgba(255, 106, 11, 0.52);
    box-shadow: 0 4px 21px 0 rgba(255, 106, 11, 0.52);
    background-color: #ff6a0b;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    display: block;
    width: 65%;
    transition: 0.5s;
    border: none;
    outline: none;
}
    .footer-learn-more-section .flms-wrapper .flms-txt-sec .flms-cta:hover {
        background-color: #ff6a0bd9;
    }

    @media (max-width: 768px) {
        .footer-learn-more-section .flms-wrapper .flms-txt-sec .flms-cta {
        width: 92%;
        padding: 16px;
    }
}

.footer-learn-more-section .flms-wrapper .flms-right-bg {
    background-color: #f7aa21;
    -webkit-clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%);
    clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%);
    height: 35vh;
    text-align: center;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

@media (max-width: 768px) {
    .footer-learn-more-section .flms-wrapper .flms-right-bg {
        -webkit-clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0 100%);
        clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0 100%);
        border-radius: 7px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        height: 25vh;
    }
}
@media (min-device-width : 768px) and (max-device-width : 1024px){
    .footer-learn-more-section .flms-wrapper .flms-right-bg {
        height: 30vh;
    }
    .footer-learn-more-section .flms-wrapper .flms-txt-sec {
        text-align: center;
    }
        .footer-learn-more-section .flms-wrapper .flms-txt-sec .flms-cta {
            width: 90%;
            margin: 35px auto 10px;
        }
}

.footer-learn-more-section .flms-wrapper .flms-right-bg img {
    width: 80%;
    margin: 10px;
}

/*----------------- End Footer Learn More Section -------------------*/
/*-------------- Exam Modal Popup ----------------------*/

    .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .emp-close {
        opacity: 1;
        color: #353232;
        float: right;
        outline: none;
        font-size: 28px;
        background: #ffbe13;
        padding: 3px 7px;
        border-radius: 50px;
        position: relative;
        top: -38px;
        left: 7px;
        margin-bottom: -40px;
        width: 35px;
        height: 35px;
        border: none;
        line-height: 32px;
    }
    .exam-modal-popup .modal .modal-dialog .modal-content .modal-body h2 {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #21354b;
    }
    .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec{
        padding: 20px 0;
    }
        .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first {
            text-align: center;
            width: 85%;
            margin: 0 auto;
        }
        
            .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .empb-cta-sec{
                text-align: center;
                margin: 30px 0 20px;
            }
                .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .empb-cta-sec .empb-cta {
                    padding: 16px 96px;
                    border-radius: 4px;
                    background-color: #ff6a0b;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    color: #ffffff;
                    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
                    transition: 0.5s;
                }
                    .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .empb-cta-sec .empb-cta:hover {
                        color: #ff6a0b;
                        border: 2px solid #ff6a0b;
                        background-color: #fff;
                    }
                    .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .nav-pills li {
                        display: block;
                        margin: 15px;
                        border-radius: 4px;
                        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
                        background-color: #ffffff;
                    }
            .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .nav-pills .empb-ml{
                margin-left: 100px !important;
            }

            .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .nav-pills li:not(:first-child){
                margin-left:30px;
            }
.exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .nav-pills li a {
    width: 140px;
    display: block;
    text-align: center;
    border: solid 2px #fff;
}

@media (max-width:768px) {
    .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first {
        width: 100%;
    }

        .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .nav-pills li {
            margin: 5px;
        }

            .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .nav-pills li a {
                width: 95px;
            }

            .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .nav-pills li:not(:first-child) {
                margin-left: 5px;
            }

        .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .nav-pills .empb-ml {
            margin-left: 4px !important;
        }
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
    color: #fff;
    background-color: #ffdeaa;
}
                .exam-modal-popup .modal .modal-dialog .modal-content .modal-body .exam-modal-popup-btn-sec .empb-first .nav-pills li a span {
                    font-size: 14px;
                    font-weight: 600;
                    text-align: center;
                    color: #425365;
                    display: block;
                    margin-top: 5px;
                }
/*-------------- End Exam Modal Popup ----------------------*/
.iti--separate-dial-code .iti__selected-dial-code {
    font-size: 14px;
}
.iti__flag-box, .iti__country-name {
    font-size: 14px;
}

#content{
    width: 100% !important;
}
.mobileDIV {
    padding: 0px 0 0px 100px !important;
    border-radius: 5px !important;
}
@media screen and (min-width: 0) and (max-width: 640px) {
    .clsroompadding {
        padding: 0 !important;
    }
}

.google_login_btn{
    text-align: center;
}
    .google_login_btn .or-txt{
        font-size: 20px;
    }
        .google_login_btn .or-txt:before, .google_login_btn .or-txt:after {
            background-color: #d0d0d0;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 44%;
        }
    .google_login_btn .or-txt:before {
        right: 0.5em;
        margin-left: -50%;
    }
    .google_login_btn .or-txt:after {
        left: 0.5em;
        margin-right: -50%;
    }

    .flt-right {
        float: right !important;
    }

.pl-0 {
    padding-left: 0;
}

@media (max-width: 768px) {
    .pl-0 {
        padding-left: 0px;
    }
}

.abt-pl-0 {
    padding-left: 0;
}

.pr-0 {
    padding-right: 0;
    margin-top: 0px !important;
}

@media (max-width: 768px) {
    .pr-0 {
        padding-right: 15px;
    }
}

@media (max-width: 768px) {
    .rpl-0 {
        padding-left: 0;
    }
}

@media (max-width: 768px) {
    .rpr-0 {
        padding-right: 0;
    }
}

.ptb-0100 {
    padding: 0 100px;
}

@media (max-width: 768px) {
    .ptb-0100 {
        display: none;
    }
}

.mtm-50 {
    margin-top: -50px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .mt-30 {
        margin-top: 10px;
    }
}

.pl-7 {
    padding-left: 7px;
}

.pr-7 {
    padding-right: 7px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-80 {
    margin-top: 80px !important;
}

@media (max-width: 768px) {
    .mt-80 {
        margin-top: 20px !important;
    }
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

a:hover {
    text-decoration: none;
}

a:focus {
    outline: none;
    text-decoration: none;
}

@media (min-width: 1200px) {
    .ask-wrapper .container, .footer-bottom-text .container {
        width: 1170px !important;
    }
}
h2{
    border-bottom: none !important;
    font-size: 1.8rem;
    margin: 0px 0px 22px;
}
h2:before{
    border-bottom: none !important;
}

/*-------------- Subject Color--------------------*/
.phy {
    background-color: #ffecbb;
    color: #be8600 !important;
}

.chem {
    background-color: #ffcdc9;
    color: #fa5546 !important;
}

.bio {
    background-color: #b7fec8;
    color: #12942e !important;
}

.mat {
    background-color: #bee3ff;
    color: #1089e6 !important;
}
.content-panel{
    display: none!important;
}
.header-fix .container h1{
    display: none !important;
}
/*------------------ Header Section ---------------*/
.cbse-bg {
    width: 100%;
    background-image: url("https://files.askiitians.com/static_content/content/product-page/cbse-header-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 70px 0;
}

@media (max-width: 768px) {
    .cbse-bg {
        margin-bottom: 30px;
        padding: 0px 0;
    }
}

@media (max-width: 768px) {
    .cbse-bg .head-txt-sec {
        margin-top: 30px;
    }
}

.cbse-bg .head-txt-sec .hts-txt {
    font-family: Poppins;
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    padding-bottom: 10px;
    line-height: 50px;
    padding: 0;
}

@media (max-width: 768px) {
    .cbse-bg .head-txt-sec .hts-txt {
        font-size: 24px;
        padding-bottom: 0px;
        line-height: 35px;
        padding:0;
    }
}

.cbse-bg .head-txt-sec .hts-sub-txt {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    color: #ffffff;
}

@media (max-width: 768px) {
    .cbse-bg .head-txt-sec .hts-sub-txt {
        font-size: 16px;
    }
}

.cbse-bg .head-txt-sec .hts-txt-tab span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    color: #425365;
    background-color: #ffe7d4;
    padding: 1px 8px;
    margin-top: 5px;
    border-radius: 2px;
    display: inline-block;
}

.cbse-bg .head-txt-sec .hts-lt {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    color: #425365;
}

    .cbse-bg .head-txt-sec .hts-lt span {
        color: #dd7c12;
    }

.cbse-bg .head-txt-sec .hts-ep {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    color: #425365;
}

.cbse-bg .head-txt-sec .hts-cta {
    padding: 16px 36px;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    color: #ff6a0b;
    text-transform: uppercase;
    margin-top: 10px;
    text-decoration: none;
    display: inline-block;
}

@media (max-width: 768px) {
    .cbse-bg .header-img-sec {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .cbse-bg .header-img-sec img {
        width: 100%;
    }
}

.header-section {
    width: 100%;
    background-image: url("https://files.askiitians.com/static_content/content/product-page/header-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 170px 0 80px;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .header-section {
        background-image: none;
        padding: 0px 0 30px;
    }
}

@media (max-width: 768px) {
    .header-section .head-txt-sec {
        margin-top: 30px;
    }
}

.header-section .head-txt-sec .hts-txt {
    font-family: Poppins;
    font-size: 40px;
    font-weight: bold;
    color: #425365;
    padding-bottom: 10px;
    line-height: 50px;
    border-bottom: none !important;
    padding: 0;
}
    .header-section .head-txt-sec .hts-txt:before{
        border-bottom: none !important;
    }

    @media (max-width: 768px) {
        .header-section .head-txt-sec .hts-txt {
        font-size: 24px;
        padding-bottom: 0px;
        line-height: 35px;
        padding: 0;
    }
}

.header-section .head-txt-sec .hts-txt span {
    color: #fc6118;
}

.header-section .head-txt-sec .hts-sub-txt {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .header-section .head-txt-sec .hts-sub-txt {
        font-size: 16px;
    }
}

.header-section .head-txt-sec .hts-txt-tab span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    color: #425365;
    background-color: #ffe7d4;
    padding: 1px 8px;
}

.header-section .head-txt-sec .hts-lt {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    color: #425365;
}

    .header-section .head-txt-sec .hts-lt span {
        color: #dd7c12;
    }

.header-section .head-txt-sec .hts-ep {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    color: #425365;
}

.header-section .head-txt-sec .hts-cta {
    padding: 16px 36px;
    border-radius: 4px;
    background-color: #ff6a0b;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 10px;
    text-decoration: none;
    display: inline-block;
}

@media (max-width: 768px) {
    .header-section .header-img-sec {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .header-section .header-img-sec img {
        width: 100%;
    }
}

/*------------------ End Header Section ---------------*/
/*------------------ About The Course Section ---------------*/
.about-course {
    border-radius: 12.4px;
    -webkit-box-shadow: 0 3.1px 21px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 3.1px 21px 0 rgba(0, 0, 0, 0.25);
    border: dashed 1.6px #425365;
    background-color: #fff4cf;
    width: 80%;
    margin: auto;
    position: relative;
    margin-top: -30px;
}

@media (max-width: 768px) {
    .about-course {
        margin-top: 0px;
        width: 95%;
    }
}

.about-course .about-course-wrapper {
    padding: 20px;
}

@media (max-width: 768px) {
    .about-course .about-course-wrapper {
        padding: 20px 0;
    }
}

.about-course .about-course-wrapper .acws-head-txt-res {
    display: none;
    border-bottom: 2px solid #ded7bc;
}

@media (max-width: 768px) {
    .about-course .about-course-wrapper .acws-head-txt-res {
        display: block;
        padding: 0 10px;
    }
}

.about-course .about-course-wrapper .acws-head-txt-res h2 {
    font-family: Poppins;
    font-size: 28px;
    font-weight: bold;
    color: #425365;
    margin: 0 0 10px;
}

@media (max-width: 768px) {
    .about-course .about-course-wrapper .acws-head-txt-res h2 {
        font-size: 20px;
    }
}

.about-course .about-course-wrapper .acws-head-txt-res p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .about-course .about-course-wrapper .acws-head-txt-res p {
        font-size: 12px;
        line-height: 24px;
    }
}
.about-course .about-course-wrapper .acw-txt-sec {
    margin-right: 30px;
}
.about-course .about-course-wrapper .acw-txt-sec .acws-head-txt {
    border-bottom: 2px solid #ded7bc;
}

@media (max-width: 768px) {
    .about-course .about-course-wrapper .acw-txt-sec .acws-head-txt {
        display: none;
    }
}

.about-course .about-course-wrapper .acw-txt-sec .acws-head-txt h2 {
    font-family: Poppins;
    font-size: 28px;
    font-weight: bold;
    color: #425365;
    margin: 0 0 10px;
}

@media (max-width: 768px) {
    .about-course .about-course-wrapper .acw-txt-sec .acws-head-txt h2 {
        font-size: 20px;
    }
}

.about-course .about-course-wrapper .acw-txt-sec .acws-head-txt p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .about-course .about-course-wrapper .acw-txt-sec .acws-head-txt p {
        font-size: 12px;
        line-height: 24px;
    }
}

.about-course .about-course-wrapper .acw-txt-sec .acws-hrs {
    padding-top: 20px;
}

    .about-course .about-course-wrapper .acw-txt-sec .acws-hrs p .prs-num {
        font-family: Poppins;
        font-size: 35.8px;
        font-weight: bold;
        color: #f47408;
    }

    .about-course .about-course-wrapper .acw-txt-sec .acws-hrs p .hrs {
        color: #5a5a5a;
        font-family: Poppins;
        font-size: 35.8px;
    }

    .about-course .about-course-wrapper .acw-txt-sec .acws-hrs p .hrs-txt {
        display: inline-block;
        width: 24%;
        font-family: Poppins;
        font-size: 14px;
        margin-left: 5px;
        font-weight: normal;
        line-height: 20px;
        color: #5a5a5a;
    }

.about-course .about-course-wrapper .acw-txt-sec .course-hrs-list {
    padding-top: 8px;
}

    .about-course .about-course-wrapper .acw-txt-sec .course-hrs-list .hrs-list-wrapper .prc-hrs {
        padding: 10px 0;
    }

@media (max-width: 768px) {
    .about-course .about-course-wrapper .acw-txt-sec .course-hrs-list .hrs-list-wrapper .prc-hrs {
        padding: 5px 0;
    }
}

.about-course .about-course-wrapper .acw-txt-sec .course-hrs-list .hrs-list-wrapper .prc-hrs p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    color: #f47408;
}

.about-course .about-course-wrapper .acw-txt-sec .course-hrs-list .hrs-list-wrapper .prc-hrs-txt {
    padding: 10px 0;
}

@media (max-width: 768px) {
    .about-course .about-course-wrapper .acw-txt-sec .course-hrs-list .hrs-list-wrapper .prc-hrs-txt {
        padding: 5px 0;
    }
}

.about-course .about-course-wrapper .acw-txt-sec .course-hrs-list .hrs-list-wrapper .prc-hrs-txt p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #5a5a5a;
}

.about-course .acw-price-sec {
    width: 77%;
    border-radius: 12.4px;
    -webkit-box-shadow: 0 1.6px 6.2px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1.6px 6.2px 0 rgba(0, 0, 0, 0.25);
    border: solid 1.6px #e9e9e9;
    background-color: #ffffff;
    padding: 3px 0 12px;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .about-course .acw-price-sec {
        width: 100%;
    }
}

.about-course .acw-price-sec .acw-head-txt p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    color: #425365;
    text-align: center;
    padding: 8px 0;
}

.about-course .acw-price-sec .acw-tab-section {
    padding: 5px 20px;
}

    /*.about-course .acw-price-sec .acw-tab-section .crascor-price-sec {
        -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
        padding: 10px 10px 5px;
        text-align: center;
        border: 2px solid #4cac04;
        border-radius: 5px;
    }*/
    .about-course .acw-price-sec .acw-tab-section .crascor-price-sec {
        padding: 8px 0px;
    }
    .about-course .acw-price-sec .acw-tab-section .ttl-cost {
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        color: #425365;
    }

        .about-course .acw-price-sec .acw-tab-section .crascor-price-sec .price-inr, .about-course .acw-price-sec .acw-tab-section .crascor-price-sec .price-usd {
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 600;
        }
        .about-course .acw-price-sec .acw-tab-section .crascor-price-sec .price-inr {
            background-color: #fff;
            display: inline-block;
            color: #68c721;
            font-family: Poppins;
            font-size: 20px;
            font-weight: bold;
        }
        .about-course .acw-price-sec .acw-tab-section .crascor-price-sec .price-usd {
            background-color: #fff;
            display: inline-block;
            padding-left: 10px;
            margin-left: 10px;
            border-left: 3px solid #68c721;
            color: #68c721;
            font-family: Poppins;
            font-size: 20px;
            font-weight: bold;
        }

        .about-course .acw-price-sec .acw-tab-section .nav-pills li {
            width: 48%;
        }

        .about-course .acw-price-sec .acw-tab-section .nav-pills li a {
            border-radius: 4px;
            font-family: Poppins;
            font-size: 15px;
            font-weight: bold;
            color: #425365;
            -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
            background-color: #ffffff;
            border: 2px solid #fff4cf;
            text-decoration: none;
            padding: 5px 10px !important;
        }

            .about-course .acw-price-sec .acw-tab-section .nav-pills li a small {
                display: block;
                font-family: Poppins;
                font-size: 12px;
                font-weight: normal;
                color: #515052;
            }

            .about-course .acw-price-sec .acw-tab-section .nav-pills li a span {
                font-family: Poppins;
                font-size: 15px;
                font-weight: bold;
                color: #425365;
                display: block;
            }

                .about-course .acw-price-sec .acw-tab-section .nav-pills li a span small {
                    display: inline-block;
                    font-size: 10px;
                    font-weight: bold;
                }

    .about-course .acw-price-sec .acw-tab-section .nav-pills > li.active > a,
    .about-course .acw-price-sec .acw-tab-section .nav-pills > li.active > a:focus,
    .about-course .acw-price-sec .acw-tab-section .nav-pills > li.active > a:hover {
        color: #425365;
        background-color: #e4ffcf;
        border: 2px solid #4cac04;
    }

.about-course .acw-price-sec .acw-bookdemo-cta {
    text-align: center;
    padding-top: 12px;
}

    .about-course .acw-price-sec .acw-bookdemo-cta .bookdemo-cta {
        padding: 12.4px 57.5px 12.4px 56px;
        border-radius: 3.1px;
        background-color: #ff6a0b;
        font-family: Poppins;
        font-size: 12.4px;
        font-weight: bold;
        font-stretch: normal;
        text-align: center;
        color: #ffffff;
        width: 88%;
        display: inline-block;
        text-transform: uppercase;
        text-decoration: none;
    }

    .about-course .acw-price-sec .acw-bookdemo-cta .addtocart-cta {
        padding: 12px 57.5px 12px 56px;
        border-radius: 3.1px;
        border: solid 1px #425365;
        background-color: #ffffff;
        font-family: Poppins;
        font-size: 12.4px;
        font-weight: bold;
        text-align: center;
        color: #425365;
        width: 88%;
        display: inline-block;
        text-transform: uppercase;
        margin-top: 12px;
        text-decoration: none;
    }

.about-course .acw-price-sec .acw-list-items {
    padding: 20px;
}

    .about-course .acw-price-sec .acw-list-items ul {
        list-style-type: none;
    }

        .about-course .acw-price-sec .acw-list-items ul li {
            margin-top: 6px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            color: #425365;
            letter-spacing: 0.5;
        }

            .about-course .acw-price-sec .acw-list-items ul li .fa {
                font-size: 14px;
                color: #f47408;
            }

/*------------------ End About The Course Section ---------------*/
/*------------------ Batch Details Section ---------------*/
.batch-details {
    padding: 60px 0;
    width: 1170px !important;
    margin: auto;
}

@media (max-width: 768px) {
    .batch-details {
        padding: 40px 0 0;
    }
}

.batch-details .bd-head-sec {
    text-align: center;
}

    .batch-details .bd-head-sec h2 {
        font-family: Poppins;
        font-size: 36px;
        font-weight: bold;
        color: #425365;
    }

@media (max-width: 768px) {
    .batch-details .bd-head-sec h2 {
        font-size: 20px;
        width: 90%;
        margin: auto;
    }
}

@media (max-width: 768px) {
    .batch-details .bd-img-sec img {
        display: none;
    }
}

.batch-details .bd-img-sec {
    text-align: center;
    padding-top: 20px;
}

@media (width: 768px) {
    .batch-details .bd-img-sec {
        display: none;
    }
}

.batch-details .res-bd-img-sec {
    display: none;
}

@media (max-width: 768px) {
    .batch-details .res-bd-img-sec {
        display: block;
    }
}

.batch-details .res-bd-img-sec img {
    display: none;
}

@media (max-width: 768px) {
    .batch-details .res-bd-img-sec img {
        display: block;
        margin: auto;
        width: -webkit-fill-available;
    }
   .hsc-owl-itm2 img{
    width: -webkit-fill-available;
   }
}

.batch-details .batch-comparison-sec {
    text-align: center;
    padding-top: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-sec {
        padding-top: 15px;
        padding: 8px 10px;
    }
}

.batch-details .batch-comparison-sec .batch-comparison-cta {
    padding: 10px;
    width: 25%;
    display: inline-block;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px #2d4054;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#c5c5c5), color-stop(#ffffff), color-stop(#ffffff), color-stop(#ffffff), to(#cdcccc));
    background-image: linear-gradient(to top, #c5c5c5, #ffffff, #ffffff, #ffffff, #cdcccc);
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #2d4054;
    text-decoration: none;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-sec .batch-comparison-cta {
        width: 70%;
        font-size: 12px;
    }
}

.batch-details .batch-comp-cta-sec {
    text-align: center;
    padding-top: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .batch-details .batch-comp-cta-sec {
        padding-top: 30px;
    }
}

.batch-details .batch-comp-cta-sec .bookfreedemo-cta {
    padding: 16px 36px;
    border-radius: 4px;
    background-color: #ff6a0b;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
    text-decoration: none;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body {
        padding: 0;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .close {
    font-size: 21px;
    font-weight: 700;
    top: -19px;
    background-color: #fff;
    right: -19px;
    line-height: 1;
    background-color: #fff !important;
    position: absolute;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 1;
    width: 22px;
    height: 22px;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper {
        margin-top: 40px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl {
    margin-top: 0px;
    position: relative;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table-striped > tbody > tr:nth-of-type(odd) {
        background-color: #fefaf7;
        text-align: center;
    }

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table-striped > tbody > tr:nth-of-type(even) {
        background-color: #fff;
        text-align: center;
    }

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table thead tr th {
        font-size: 14px;
        width: 37%;
    }
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead tr th h3 {
        font-size: 15px !important;
        padding-bottom: 5px;
    }
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead tr th span{
        font-size: 16px !important;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead {
    background-color: #fff4cf;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead tr th {
        width: 32%;
        text-align: center;
        padding: 10px;
    }

        .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead tr th span {
            display: block;
            font-family: Poppins;
            font-size: 22px;
            font-weight: bold;
            color: #007f00;
        }

        .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .table thead tr th h3 {
            font-family: Poppins;
            font-size: 25px;
            font-weight: bold;
            color: #425365;
            margin: 0;
        }

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td {
    vertical-align: middle;
    padding: 4px 8px;
    height: 6vh;
    font-weight: 500;
    text-align: center;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .fa-check-circle {
        color: #3bb54a;
        font-size: 24px;
    }

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .fa-times-circle {
        color: #f44336;
        font-size: 24px;
    }

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td {
        width: 40%;
        height: 10vh;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td p {
        font-size: 12px;
        line-height: 18px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td small {
    opacity: 0.8;
    font-family: Poppins;
    font-size: 14px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td small {
        font-size: 12px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .del-price {
    display: inline-block;
    padding: 5px 15px;
    background-color: #ffe0e0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    width: 61%;
    color: #ff0000;
    text-decoration: line-through;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .del-price {
        font-size: 12px;
        width: 92%;
        padding: 5px 10px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .del-price span,
.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl table tbody tr td .del-price .fas {
    color: #425365;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .note-txt {
        padding: 5px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .note-txt sup {
    color: #007f00;
    font-weight: bold;
    font-size: 13px;
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list {
    width: 30%;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table {
        position: absolute;
        width: 30%;
        top: -1px;
        right: 20px;
        border-radius: 6px;
        -webkit-box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.25);
        box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        border: none !important;
    }

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table {
        position: absolute;
        width: 28%;
        top: 21px;
        right: 0px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr {
    text-align: center;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th {
        vertical-align: middle;
        padding: 11px;
    }

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th {
        padding: 0;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .excl-txt {
    border: dashed 1px #007f00;
    background-color: #dcf3dc;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    color: #007f00;
    padding: 7px 7px 10px;
    margin-top: 5px;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .excl-txt {
        font-size: 14px;
        padding: 5px;
        text-align: center;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .havl-txt {
    display: block;
    width: 70%;
    margin: auto;
    padding: 2px 7px 2px 5px;
    border-radius: 10px;
    background-color: #e6e6f2;
    font-size: 12px;
    font-weight: 500;
    color: #4a4aff;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .havl-txt {
        width: 95%;
        font-size: 10px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .havl-txt .fa {
    font-size: 8px;
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .spl-fee {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    color: #425365;
    text-align: center;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table thead tr th .spl-fee {
        font-size: 14px;
        margin-bottom: 2px;
        margin-top: 0;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr {
    text-align: center;
}

    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td {
        vertical-align: middle;
        padding: 5px 0;
    }

@media (max-width: 768px) {
   
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .close {
        position: absolute;
        top: -31px !important;
        right: 8px !important;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td .new-price {
    display: inline-block;
    padding: 5px 15px;
    background-color: #ffe0e0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    width: 61%;
    color: #007f00;
    background-color: #dcf3dc;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td .new-price {
        width: 90%;
        padding: 3px 5px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td .jh-new-price {
    padding: 5px;
    width: 40%;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .table tbody tr td .jh-new-price {
        padding: 0;
        width: 44%;
        font-size: 12px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .jh-table {
    position: absolute;
    width: 35%;
    top: -101px;
    right: 10px;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .new-price-list .jh-table {
        position: absolute;
        width: 39%;
        top: -46px;
        right: -10px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content h3 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: bold;
    color: #425365;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content h3 {
        font-size: 16px;
        padding: 0 5px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content .table thead th {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    color: #425365;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content .table thead th {
        font-size: 12px;
    }
}

.batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content .table tbody td {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    color: #7c2eff;
}

@media (max-width: 768px) {
    .batch-details .batch-comparison-modal .modal .modal-dialog .modal-content .modal-body .pp-table-wrapper .ppt-tbl .jh-tbl-footer-content .table tbody td {
        font-size: 12px;
    }
}

/*------------------ End Batch Details Section ---------------*/
/*------------------ Feature Section ---------------*/
.feature-sec {
    padding: 50px 0 30px;
}

@media (max-width: 768px) {
    .feature-sec {
        padding: 40px 0 15px;
    }
}

.feature-sec .fc-head-txt h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    color: #425365;
}

@media (max-width: 768px) {
    .feature-sec .fc-head-txt h2 {
        font-size: 20px;
    }
}

.feature-sec .feature-sec-wrapper {
    padding-top: 30px;
}

@media (max-width: 768px) {
    .feature-sec .feature-sec-wrapper {
        padding-top: 10px;
    }
}

.feature-sec .feature-sec-wrapper .modal .modal-body .close {
    position: absolute;
    top: -28px;
    right: 0;
    background-color: white;
    color: #333;
    -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    width: 30px;
    height: 28px;
    border-radius: 5px;
    opacity: 1;
}

@media (max-width: 768px) {
    .feature-sec .feature-sec-wrapper .modal .modal-dialog {
        margin: 35px 10px;
    }
}

.feature-sec .feature-sec-wrapper .fsw-sec .fsw-sec-wrapper {
    margin-top: 10px;
    display: flex;
}

    .feature-sec .feature-sec-wrapper .fsw-sec .fsw-sec-wrapper img {
        width: 90%;
    }

@media (max-width: 768px) {
    .feature-sec .feature-sec-wrapper .fsw-sec .fsw-sec-wrapper img {
        width: 80%;
    }
}

.feature-sec .feature-sec-wrapper .fsw-sec .fsw-sec-wrapper p {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 500;
    color: #425365;
    margin: 0;
    line-height: 25px;
    margin-top: revert;
}

@media (max-width: 768px) {
    .feature-sec .feature-sec-wrapper .fsw-sec .fsw-sec-wrapper p {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .feature-sec .feature-sec-wrapper .desk-feature-sec {
        display: block;
    }
}

.feature-sec .feature-sec-wrapper .feature-sec-img {
    position: relative;
}

    .feature-sec .feature-sec-wrapper .feature-sec-img .video-icon-sec {
        position: absolute;
        top: 94px;
        border-radius: 50%;
        left: 123px;
        width: 40%;
        -webkit-box-shadow: 5px 5px 30px rgba(92, 92, 92, 0.8);
        box-shadow: 5px 5px 30px rgba(92, 92, 92, 0.8);
        cursor: pointer;
    }

        .feature-sec .feature-sec-wrapper .feature-sec-img .video-icon-sec img {
            width: 100%;
            border-radius: 50%;
        }

@media (max-width: 768px) {
    .feature-sec .feature-sec-wrapper .feature-sec-img {
        display: none;
    }
}

@media (max-width: 768px) {
    .feature-sec .feature-sec-wrapper .feature-sec-img img {
        width: 100%;
        display: none;
    }
}

.feature-sec .feature-sec-wrapper .res-feature-sec {
    display: none;
}

    .feature-sec .feature-sec-wrapper .res-feature-sec .video-icon-sec {
        position: absolute;
        top: 94px;
        border-radius: 50%;
        left: 123px;
        width: 40%;
        -webkit-box-shadow: 5px 5px 30px rgba(92, 92, 92, 0.8);
        box-shadow: 5px 5px 30px rgba(92, 92, 92, 0.8);
    }

@media (max-width: 768px) {
    .feature-sec .feature-sec-wrapper .res-feature-sec .video-icon-sec {
        position: absolute;
        top: 90px;
        border-radius: 50%;
        left: 134px;
        width: 29%;
    }
}

.feature-sec .feature-sec-wrapper .res-feature-sec .video-icon-sec img {
    width: 100%;
    border-radius: 50%;
}

@media (max-width: 768px) {
    .feature-sec .feature-sec-wrapper .res-feature-sec {
        display: block;
        margin-top: 10px;
        position: relative;
    }
}

/*------------------ End Feature Section ---------------*/
.we-provide-sec {
    padding: 30px 0;
}

@media (max-width: 768px) {
    .we-provide-sec {
        padding: 0;
    }
}

.we-provide-sec .we-provide-head-txt h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #425365;
}

@media (max-width: 768px) {
    .we-provide-sec .we-provide-head-txt h2 {
        font-size: 20px;
    }
}

.we-provide-sec .res-wps-content-sec {
    display: none;
}
.we-provide-sec .res-wps-content-sec .pr-7{
    padding: 10px;
}

@media (max-width: 768px) {
    .we-provide-sec .res-wps-content-sec {
        display: block;
    }
}

.we-provide-sec .res-wps-content-sec .res-wps-head-txt {
    margin-left: -7px;
}

    .we-provide-sec .res-wps-content-sec .res-wps-head-txt h3 {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        color: #425365;
        padding-bottom: 5px;
    }

        .we-provide-sec .res-wps-content-sec .res-wps-head-txt h3 span {
            color: #fe6a00;
        }

.we-provide-sec .res-wps-content-sec .res-wps-wrapper .res-wps-container {
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 15px 0 0;
}

    .we-provide-sec .res-wps-content-sec .res-wps-wrapper .res-wps-container .res-wps-icon {
        text-align: center;
    }

        .we-provide-sec .res-wps-content-sec .res-wps-wrapper .res-wps-container .res-wps-icon img {
            width: 50%;
        }

    .we-provide-sec .res-wps-content-sec .res-wps-wrapper .res-wps-container .res-wps-txt {
        padding: 10px 0 5px;
        text-align: center;
    }

        .we-provide-sec .res-wps-content-sec .res-wps-wrapper .res-wps-container .res-wps-txt p {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
            color: #425365;
            margin-bottom: 0;
            line-height: 20px;
        }

.we-provide-sec .wps-content-sec {
    padding-top: 30px;
}

    .we-provide-sec .wps-content-sec .flt-left {
        float: left !important;
    }

    .we-provide-sec .wps-content-sec .wps-wrapper {
        position: relative;
        float: right;
    }

        .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec, .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec2 {
            position: absolute;
            top: 28px;
            left: 163px;
            width: 71%;
        }

            .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec .wpts-conatiner, .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec2 .wpts-conatiner {
                background-color: #f3f3f3;
                border-radius: 8px;
                padding: 10px;
                text-align: center;
                margin-left: 8px;
            }

                .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec .wpts-conatiner .wpts-icon img, .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec2 .wpts-conatiner .wpts-icon img {
                    width: 60%;
                }

                .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec .wpts-conatiner .wpts-txt, .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec2 .wpts-conatiner .wpts-txt {
                    padding: 2px 10px;
                }

                    .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec .wpts-conatiner .wpts-txt p, .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec2 .wpts-conatiner .wpts-txt p {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 500;
                        text-align: center;
                        color: #425365;
                        line-height: 20px;
                    }

            .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec .wpts-box-txt, .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec2 .wpts-box-txt {
                padding: 18px 10px;
            }

                .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec .wpts-box-txt p, .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec2 .wpts-box-txt p {
                    font-family: Poppins;
                    font-size: 28px;
                    font-weight: 500;
                    margin-top: 25px;
                    color: #ffffff;
                    line-height: 34px;
                }

        .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec {
            top: 24px;
        }

        .we-provide-sec .wps-content-sec .wps-wrapper .we-provide-txt-sec2 {
            left: 20px;
            top: 22px;
        }

.we-provide-sec .wps-cta-sec {
    text-align: center;
    padding-top: 60px;
}

@media (max-width: 768px) {
    .we-provide-sec .wps-cta-sec {
        padding-top: 50px;
    }
}

.we-provide-sec .wps-cta-sec .wps-cta-btn {
    padding: 16px 36px;
    border-radius: 4px;
    background-color: #ff6a0b;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
}

/*------------------ End We Provide Section ---------------*/
/*------------------ Test Series Section ---------------*/
/*------------------ Test Series Header Section ---------------*/
.ts-header-section {
    width: 100%;
    background-image: url("https://files.askiitians.com/static_content/content/product-page/header-bg1.svg"); 
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0;
}

@media (max-width: 768px) {
    .ts-header-section {
        padding: 30px 0;
    }
}

@media (max-width: 768px) {
    .ts-header-section .head-txt-sec {
        margin-top: 30px;
    }
}

.ts-header-section .head-txt-sec ul {
    list-style-type: none;
    margin: 0;
    padding: 15px 0;
}

    .ts-header-section .head-txt-sec ul li {
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        color: #ffffff;
        line-height: 1.7;
    }

        .ts-header-section .head-txt-sec ul li .fa {
            font-size: 14px;
            color: #ff6a0b;
        }

.ts-header-section .head-txt-sec .hts-txt {
    font-family: Poppins;
    font-size: 40px;
    font-weight: bold;
    color: #425365;
    padding-bottom: 10px;
    line-height: 50px;
}

@media (max-width: 768px) {
    .ts-header-section .head-txt-sec .hts-txt {
        font-size: 24px;
        padding-bottom: 0px;
        line-height: 35px;
    }
}

.ts-header-section .head-txt-sec .hts-txt span {
    color: #fc6118;
}

.ts-header-section .head-txt-sec .hts-sub-txt {
    font-family: Poppins;
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 10px;
    line-height: 1.3;
}

@media (max-width: 768px) {
    .ts-header-section .head-txt-sec .hts-sub-txt {
        font-size: 24px;
    }
}

.ts-header-section .head-txt-sec ul li .list-txt {
    margin-left: -30px;
    margin-top: 0px;
}

@media (max-width: 768px) {
    .ts-header-section .head-txt-sec ul li .list-txt {
        margin-left: -3px;
        font-size: 16px;
        margin-bottom: 0;
    }
}

.ts-header-section .head-txt-sec .hts-txt-tab span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    color: #425365;
    background-color: #ffe7d4;
    padding: 1px 8px;
}

.ts-header-section .head-txt-sec .hts-lt {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    color: #425365;
}

    .ts-header-section .head-txt-sec .hts-lt span {
        color: #dd7c12;
    }

.ts-header-section .head-txt-sec .hts-ep {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    color: #425365;
}

.ts-header-section .head-txt-sec .hts-cta {
    padding: 16px 36px;
    border-radius: 4px;
    background-color: #ff6a0b;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 10px;
    text-decoration: none;
    display: inline-block;
}

.ts-header-section .header-img-sec {
    text-align: right;
}

@media (max-width: 768px) {
    .ts-header-section .header-img-sec {
        margin-top: 20px;
    }
}

.ts-header-section .header-img-sec img {
    width: 80%;
}

@media (max-width: 768px) {
    .ts-header-section .header-img-sec img {
        width: 100%;
        display: none;
    }
}

/*------------------ End Test Series Header Section ---------------*/
/*------------------ Why askIITians Test Series Section ---------------*/
.why-askiitians-ts {
    padding: 70px 0 90px;
}

@media (max-width: 768px) {
    .why-askiitians-ts {
        padding: 30px 0 45px;
    }
}

.why-askiitians-ts .why-askiitians-head-txt {
    padding-bottom: 20px;
}

    .why-askiitians-ts .why-askiitians-head-txt h2 {
        font-family: Poppins;
        font-size: 36px;
        font-weight: bold;
        color: #425365;
    }

@media (max-width: 768px) {
    .why-askiitians-ts .why-askiitians-head-txt h2 {
        font-size: 20px;
        text-align: center;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box {
    border-radius: 16px;
    -webkit-box-shadow: 1px 3px 9px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 1px 3px 9px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 15px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    height: 255px;
}

@media (max-width: 768px) {
    .why-askiitians-ts .why-askiitians-boxes .why-askiitians-box {
        padding: 8px;
        height: 39vh;
    }
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box:hover .why-askiitians-icon img {
    -webkit-box-shadow: 1px 3px 9px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 1px 3px 9px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box .why-askiitians-icon {
    background-image: url("https://files.askiitians.com/static_content/content/product-page/sprite-img.png");
    width: 80px;
    height: 80px;
}

    .why-askiitians-ts .why-askiitians-boxes .why-askiitians-box .why-askiitians-icon img {
        -webkit-transition: 0.5s;
        transition: 0.5s;
        border-radius: 50px;
    }

@media (max-width: 768px) {
    .why-askiitians-ts .why-askiitians-boxes .why-askiitians-box .why-askiitians-icon img {
        width: 40%;
    }
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box #spltImg1 {
    background-position: -8px -6px;
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box #spltImg2 {
    background-position: -88px -6px;
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box #spltImg3 {
    background-position: -168px -6px;
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box #spltImg4 {
    background-position: -248px -6px;
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box #spltImg5 {
    background-position: -8px -88px;
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box #spltImg6 {
    background-position: -88px -88px;
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box #spltImg7 {
    background-position: -168px -88px;
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box #spltImg8 {
    background-position: -248px -88px;
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box .why-askiitians-hd-txt h4 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    color: #425365;
    line-height: 1.2;
}

@media (max-width: 768px) {
    .why-askiitians-ts .why-askiitians-boxes .why-askiitians-box .why-askiitians-hd-txt h4 {
        font-size: 14px;
    }
}

.why-askiitians-ts .why-askiitians-boxes .why-askiitians-box .why-askiitians-sub-txt p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    color: #687584;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .why-askiitians-ts .why-askiitians-boxes .why-askiitians-box .why-askiitians-sub-txt p {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .why-askiitians-ts .why-askiitians-boxes .why-sec-row {
        margin-top: 0px;
    }
}

/*------------------ End Why askIITians Test Series Section ---------------*/
/*------------------ Test Series Section ---------------*/
.ts-test-series {
    padding: 40px 0 50px;
    border-radius: 13.4px;
    border: solid 1px #bfc4c9;
    background-color: #f4f4f4;
}

@media (max-width: 768px) {
    .ts-test-series {
        padding: 25px 0 50px;
    }
}

.ts-test-series .test-series-head-txt h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #425365;
    margin: 0;
    padding: 0;
}

@media (max-width: 768px) {
    .ts-test-series .test-series-head-txt h2 {
        font-size: 20px;
    }
}

.ts-test-series .test-series-box1 {
    border-radius: 18px;
    border: solid 2px #f7aa21;
    background-color: #ffffff;
    padding: 20px;
    margin-top: 30px;
}

@media (max-width: 768px) {
    .ts-test-series .test-series-box1 {
        border-radius: 0px;
        border: none;
        background-color: #f4f4f4;
        padding: 0px;
        margin-top: 10px;
    }
}

.ts-test-series .test-series-box1 .tsb1-txt .ts-sec-icon {
    background-image: url("https://files.askiitians.com/static_content/content/product-page/sprite-img.png");
    width: 60px;
    height: 60px;
}

.ts-test-series .test-series-box1 .tsb1-txt #tsb1Icon1 {
    background-position: 0 -165px;
}

.ts-test-series .test-series-box1 .tsb1-txt #tsb1Icon2 {
    background-position: -60px -165px;
}

.ts-test-series .test-series-box1 .tsb1-txt #tsb1Icon3 {
    background-position: -120px -165px;
}

.ts-test-series .test-series-box1 .tsb1-txt #tsb1Icon4 {
    background-position: -192px -165px;
}

.ts-test-series .test-series-box1 .tsb1-txt #tsb1Icon5 {
    background-position: 5px -527px;
}

.ts-test-series .test-series-box1 .tsb1-txt #tsb1Icon6 {
    background-position: -58px -527px;
}

.ts-test-series .test-series-box1 .tsb1-txt #tsb1Icon7 {
    background-position: -127px -527px;
}

.ts-test-series .test-series-box1 .tsb1-txt #tsb1Icon8 {
    background-position: -185px -527px;
}

.ts-test-series .test-series-box1 .tsb1-txt p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #425365;
    margin-top: 18px;
    margin-left: -20px;
}

@media (max-width: 768px) {
    .ts-test-series .test-series-box1 .tsb1-txt p {
        font-size: 14px;
        margin-top: 13px;
    }
}

.ts-test-series .test-series-box1 .tsb1-txt .tsb1-pmt {
    margin-top: 12px;
}

.ts-test-series .test-series-box1 .tsb1-txt img {
    width: 100%;
}

@media (max-width: 768px) {
    .ts-test-series .test-series-box1 .tsb1-txt img {
        width: 90%;
    }
}

.ts-test-series .test-series-box1 .tsb1-mt {
    margin-top: 12px;
}

.ts-test-series .test-series-box2 {
    background-color: #2c435b;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 20px;
    width: 85%;
    margin: auto;
}

@media (max-width: 768px) {
    .ts-test-series .test-series-box2 {
        padding: 5px;
        width: 100%;
    }
}

.ts-test-series .test-series-box2 .tsb2-head-txt {
    text-align: center;
    padding: 10px 0 25px;
}

    .ts-test-series .test-series-box2 .tsb2-head-txt h3 {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        margin: 0;
        padding: 0;
    }

.ts-test-series .test-series-box2 .demo-vodeo-box {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
}

@media (max-width: 786px) {
    .ts-test-series .test-series-box2 .demo-vodeo-box {
        margin-bottom: 10px;
    }
}

.ts-test-series .test-series-box2 .demo-vodeo-box .demo-vdo-img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

    .ts-test-series .test-series-box2 .demo-vodeo-box .demo-vdo-img a {
        text-decoration: none;
    }

@media (max-width: 786px) {
    .ts-test-series .test-series-box2 .demo-vodeo-box .demo-vdo-img a img {
        width: 100%;
    }
}

.ts-test-series .test-series-box2 .demo-vodeo-box:hover .demo-vdo-img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    border-radius: 8px;
}

.ts-test-series .test-series-box2 .demo-vodeo-box .demo-vdo-txt {
    padding-top: 20px;
}

    .ts-test-series .test-series-box2 .demo-vodeo-box .demo-vdo-txt h3 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        color: #425365;
        margin: 0;
    }

@media (max-width: 786px) {
    .ts-test-series .test-series-box2 .demo-vodeo-box .demo-vdo-txt h3 {
        margin: 0;
        font-size: 14px;
    }
}

.ts-test-series .test-series-box2 .demo-vodeo-box .demo-vdo-txt p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    margin: 8px 0 0 0;
}

@media (max-width: 786px) {
    .ts-test-series .test-series-box2 .demo-vodeo-box .demo-vdo-txt p {
        margin: 0;
        font-size: 12px;
        line-height: 18px;
    }
}

.ts-test-series .test-series-cta-sec {
    text-align: center;
    margin-top: 25px;
}

    .ts-test-series .test-series-cta-sec .ts-request-cta {
        padding: 16px 60px;
        border-radius: 4px;
        -webkit-box-shadow: 0 4px 21px 0 rgba(255, 106, 11, 0.52);
        box-shadow: 0 4px 21px 0 rgba(255, 106, 11, 0.52);
        background-color: #ff6a0b;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        margin-left: 10px;
        text-decoration: none;
    }

@media (max-width: 786px) {
    .ts-test-series .test-series-cta-sec .ts-request-cta {
        margin-left: 0;
        margin-top: 20px;
        display: inline-block;
    }
}

/*------------------ End Test Series Section ---------------*/
/*------------------ How to teach Section ---------------*/
.ts-how-teach {
    background-image: url("https://files.askiitians.com/static_content/content/product-page/how-teach-bg.svg");
    background-repeat: no-repeat;
    height: 100%;
    padding: 30px 0;
    background-size: cover;
    margin: 85px 0;
    background-position: center;
}

@media (max-width: 768px) {
    .ts-how-teach {
        margin-top: -15px;
        margin: 20px 0 0;
    }
}

.ts-how-teach .how-teach-wrapper {
    padding: 60px 0 70px;
}

@media (max-width: 768px) {
    .ts-how-teach .how-teach-wrapper .how-teach-txt-sec {
        margin-top: 30px;
    }
}

.ts-how-teach .how-teach-wrapper .how-teach-txt-sec h3 {
    background-image: linear-gradient(65deg, #f99c3e, #ff564d);
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
    .ts-how-teach .how-teach-wrapper .how-teach-txt-sec h3 {
        font-size: 20px;
    }
}

.ts-how-teach .how-teach-wrapper .how-teach-txt-sec p {
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    padding-top: 30px;
}

@media (max-width: 768px) {
    .ts-how-teach .how-teach-wrapper .how-teach-txt-sec p {
        font-size: 16px;
        padding-top: 10px;
    }
}

.ts-how-teach .how-teach-wrapper .how-teach-txt-sec p span {
    color: #fe6a00;
}

.ts-how-teach .how-teach-wrapper .how-teach-img-sec {
    text-align: center;
    margin-top: 18px;
}

    .ts-how-teach .how-teach-wrapper .how-teach-img-sec p {
        text-align: center;
    }

        .ts-how-teach .how-teach-wrapper .how-teach-img-sec p a {
            padding: 16px 36px;
            border-radius: 4px;
            -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            background-color: #ff6a0b;
            text-decoration: none;
            letter-spacing: 0.32px;
            text-align: center;
            color: #ffffff;
            font-family: Poppins;
            font-size: 16px;
            font-weight: bold;
        }

/*------------------ End How to teach Section ---------------*/



/*------------------ End Test Series Section ---------------*/
/*------------------ Demo Class Section ---------------*/
.demo-class {
    padding: 40px 0;
}

@media (max-width: 768px) {
    .demo-class {
        padding: 0px 0 0;
    }
}

.demo-class .demo-class-head-txt h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    color: #425365;
}

@media (max-width: 768px) {
    .demo-class .demo-class-head-txt h2 {
        font-size: 20px;
    }
}

.demo-class .demo-class-wrapper {
    padding: 30px 0;
}

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper {
        padding: 20px 0;
    }
}

.demo-class .demo-class-wrapper .dc-main-video-sec {
    border-radius: 5px;
    background-color: #f3f3f3;
    border: 2px solid lightgray;
}

    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-container iframe {
        width: 100%;
        height: auto;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-container iframe {
        height: auto;
    }
}

.demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec {
    padding: 10px 10px 20px;
    display: none;
}

    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec p {
        font-family: Poppins;
        font-size: 17px;
        font-weight: 500;
        color: #425365;
        margin-bottom: 0;
    }

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec p {
        font-size: 16px;
        line-height: 24px;
    }
}

.demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec small {
    font-family: Poppins;
    font-size: 17px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-main-video-sec .dc-video-txt-sec small {
        font-size: 12px;
    }
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec {
    height: auto;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 5px;
    margin: 0 10px;
}

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec {
        height: 37vh;
    }
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list a {
    background-color: #f3f3f3;
    text-decoration: none;
    display: inline-block;
    margin-top: 5px;
    border-radius: 5px;
    margin-right: 10px;
}
    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .active-link {
        border: 2px solid #eb7f00;
        background-color: #eb7f0014;
    }
#accordion li {
    background: #ffffff !important;
    font-weight: lighter;
    font-size: 14px !important;
    margin: 1px;
    cursor: pointer;
    padding: 8px 0px !important;
    box-shadow: none !important;
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-thumbnail {
    padding: 10px;
}

    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-thumbnail img {
        width: 100%;
    }

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt {
    padding-top: 10px;
}

    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt p {
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        color: #425365;
    }

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt p {
        font-size: 12px;
        line-height: 13px;
    }
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt small {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    color: #425365;
}

@media (max-width: 768px) {
    .demo-class .demo-class-wrapper .dc-video-list-wrapper .dc-video-list-sec .dc-video-list .dc-video-list-txt small {
        font-size: 10px;
    }
}

.demo-class .demo-class-wrapper .dc-video-list-wrapper .show-more-video-btn-sec {
    margin-top: 15px;
    display: none;
}

    .demo-class .demo-class-wrapper .dc-video-list-wrapper .show-more-video-btn-sec .show-more-video-btn {
        width: 95%;
        padding: 15px;
        background-color: #f3f3f3;
        border-radius: 5px;
        font-family: Poppins;
        font-size: 14px;
        text-align: center;
        font-weight: normal;
        color: #242424;
        display: block;
        margin: auto;
        text-decoration: none;
    }

/*------------------ End Demo Class Section ---------------*/
/*------------------ Academic Expert Section ---------------*/
.academic-expert {
    /* padding: 0px 0 15px; */
    display: none;
}

@media (max-width: 768px) {
    .academic-expert {
        padding: 5px 0 15px;
    }
}

.academic-expert .academic-expert-head-txt {
    text-align: center;
}

    .academic-expert .academic-expert-head-txt h2 {
        font-family: Poppins;
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        color: #425365;
    }

@media (max-width: 768px) {
    .academic-expert .academic-expert-head-txt h2 {
        font-size: 20px;
    }
    #boardTeacherDetails .cbse-teacher-info-sec {
        padding: 0 5px;
    }
}

.academic-expert .cbse-academic-expert {
    margin-top: 50px;
    display: none;
}

@media (max-width: 768px) {
    .academic-expert .cbse-academic-expert {
        display: none;
    }
}

.academic-expert .cbse-academic-expert .cbse-teacher-info-sec {
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

    .academic-expert .cbse-academic-expert .cbse-teacher-info-sec .ctis-img img {
        width: 100%;
    }

    .academic-expert .cbse-academic-expert .cbse-teacher-info-sec .ctis-txt {
        padding: 5px;
        text-align: center;
    }

        .academic-expert .cbse-academic-expert .cbse-teacher-info-sec .ctis-txt .cb-teacher-name {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            color: #425365;
            margin-bottom: 0;
            text-align: center;
        }

        .academic-expert .cbse-academic-expert .cbse-teacher-info-sec .ctis-txt .cb-sub-name {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            color: #425365;
            padding: 3px 10px;
            border-radius: 5px;
            margin-top: 3px;
            margin-bottom: 0;
            text-align: center;
        }

        .academic-expert .cbse-academic-expert .cbse-teacher-info-sec .ctis-txt .cb-colg-name {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            color: #425365;
            font-size: 18px;
            width: 90%;
            border-radius: 5px;
            padding: 0px 8px;
            text-align: center;
            margin: 8px auto 5px;
        }



.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt {
    padding: 7px;
    border: 1px solid #e6e6e6;
    text-align: center;
}

@media (max-width: 768px) {
    .academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt {
        padding: 0;
    }
}

.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt .cb-teacher-name {
    margin-bottom: 0;
}

.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt .cb-sub-name {
    margin-bottom: 0;
}

.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt .cb-colg-name {
    margin-bottom: 0;
}

.academic-expert .owl-theme .owl-dots .owl-dot.active span,
.academic-expert .owl-theme .owl-theme .owl-dots .owl-dot:hover span {
    background: #f47408;
}

@media (max-width: 768px) {
    .academic-expert .owl-theme .owl-dots,
    .academic-expert .owl-theme .owl-theme .owl-nav {
        margin-top: 30px;
    }
}

.academic-expert .owl-theme .owl-nav {
    position: relative;
}

@media (max-width: 768px) {
    .academic-expert .owl-theme .owl-nav {
        display: none;
    }
}

.academic-expert .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: -230px;
    left: -35px;
    background-color: #fff;
    border-radius: 50px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

    .academic-expert .owl-theme .owl-nav .owl-prev:focus {
        outline: none;
    }

    .academic-expert .owl-theme .owl-nav .owl-prev .fa {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 38px;
        color: #f47408;
    }

        .academic-expert .owl-theme .owl-nav .owl-prev .fa:hover {
            color: #f47408;
        }

.academic-expert .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: -230px;
    right: -35px;
    background-color: #fff;
    border-radius: 50px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

    .academic-expert .owl-theme .owl-nav .owl-next:focus {
        outline: none;
    }

    .academic-expert .owl-theme .owl-nav .owl-next .fa {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        color: #f47408;
    }

.academic-expert .owl-theme .owl-nav .owl-next .fa:hover {
    color: #f47408;
}

@media (max-width: 768px) {
    .academic-expert .desk--aew-view {
        display: none;
    }
}
.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec{
    margin: 5px;
}
.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec {
    margin: 10px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
}
/* .academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec img{
    width:100% !important;
} */
#boardTeacherDetails2 {
    display: block; /* Default for Owl Carousel */
    width: 100%; /* Make it take full available width */
    overflow: hidden; /* Ensure no horizontal overflow */
  }
  
  /* Style the individual carousel items */
  #boardTeacherDetails2 > .item {
    width: fit-content; /* Or use width: auto for more flexibility */
  }

.academic-expert #boardTeacherDetails .cbse-teacher-info-sec .ctis-txt {
    padding: 7px;
    border: 1px solid #e6e6e6;
    text-align: center;
}

.academic-expert #boardTeacherDetails .cbse-teacher-info-sec {
    margin: 10px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
}
.academic-expert #boardTeacherDetails .cbse-teacher-info-sec img{
   width:100%;
}

.academic-expert #boardTeacherDetails .cbse-teacher-info-sec .ctis-txt .cb-teacher-name {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 16px;
}

.academic-expert #boardTeacherDetails .cbse-teacher-info-sec .ctis-txt .cb-sub-name {
    font-weight: 600;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 4px;
    padding: 6px 0;
}

.academic-expert #boardTeacherDetails .cbse-teacher-info-sec .ctis-txt .cb-colg-name {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 16px;
}
.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt .cb-teacher-name,
.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt .cb-sub-name,
.academic-expert #boardTeacherDetails2 .cbse-teacher-info-sec .ctis-txt .cb-colg-name{
    font-size: 16px;
}

.academic-expert .academic-expert-wrapper, .academic-expert .res-academic-expert-wrapper {
    padding-top: 40px;
}



.academic-expert .academic-expert-wrapper, .academic-expert .academic-expert-wrapper {
    display: none;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper, .academic-expert .res-academic-expert-wrapper {
        padding-top: 10px;
        display: block ;
    }
    #teacherDetails .ae-container .row{
        margin:0px;
    }
    #teacherDetails .ae-container .row div:first-child{
        margin: 0px;
    }


    .academic-expert .academic-expert-wrapper, .academic-expert .academic-expert-wrapper {
        display: none ;
    }

}

.academic-expert .academic-expert-wrapper .ae-container, .academic-expert .res-academic-expert-wrapper .ae-container {
    border-radius: 5px;
    -webkit-box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.2);
    margin: 10px;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container, .academic-expert .res-academic-expert-wrapper .ae-container {
        margin: 10px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-img, .academic-expert .res-academic-expert-wrapper .ae-container .aec-img {
    width: 100%;
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt {
    text-align: center;
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-head-txt {
    border-bottom: 2px solid lightgray;
    padding: 20px 0;
}



@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-head-txt {
        padding: 20px 0 0;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-teacher-name, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-teacher-name {
    margin-bottom: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #425365;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-teacher-name, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-teacher-name {
        font-size: 15px;
        line-height: 18px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-subject-name, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-subject-name {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #425365;
    padding: 3px 10px;
    border-radius: 5px;
    margin-top: 4px;
    display: inline-block;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-subject-name, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-subject-name {
        font-size: 12px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-exp, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-exp {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
    color: #425365;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-exp, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-head-txt .ace-exp {
        font-size: 14px;
        margin-bottom: 5px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt {
    padding: 15px 0 10px;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt {
        padding: 5px 0 0px;
    }
}

.academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt .ace-qualif, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt .ace-qualif {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    color: #425365;
    background-color: #dbe2eb;
    width: 90%;
    border-radius: 5px;
    padding: 6px 8px;
    text-align: center;
    margin: auto;
}

@media (max-width: 768px) {
    .academic-expert .academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt .ace-qualif, .academic-expert .res-academic-expert-wrapper .ae-container .aec-txt .aec-fooyter-txt .ace-qualif {
        font-size: 17px;
        padding: 1px 8px;
        margin-top: 3px;
    }
}

.academic-expert .ac-footer-cta {
    text-align: center;
    margin-top: 60px;
}

    .academic-expert .ac-footer-cta .acf-btn {
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        padding: 16px 36px;
        border-radius: 4px;
        background-color: #ff6a0b;
        text-decoration: none;
    }

/*------------------ End Academic Expert Section ---------------*/
/*------------------ You Believe Section ---------------*/
.ts-you-believe {
    padding: 35px 0 20px;
}

@media (max-width: 768px) {
    .ts-you-believe {
        padding: 0;
        margin-top: -20px;
    }
}

.ts-you-believe .you-believe-head-txt h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #425365;
}

@media (max-width: 768px) {
    .ts-you-believe .you-believe-head-txt h2 {
        font-size: 20px;
    }
}

.ts-you-believe .you-believe-carousal {
    padding: 40px 0;
}

    .ts-you-believe .you-believe-carousal .yb-warpper {
        width: 70%;
        margin: auto;
        border-radius: 15px;
        -webkit-box-shadow: 0px 2px 18px rgba(95, 95, 95, 0.25);
        box-shadow: 0px 2px 18px rgba(95, 95, 95, 0.25);
        padding: 15px;
    }

@media (max-width: 768px) {
    .ts-you-believe .you-believe-carousal .yb-warpper {
        width: 90%;
    }
}



    .ts-you-believe .you-believe-carousal .yb-warpper .ybw-img1 img {
        width: 100%;
    }

.ts-you-believe .you-believe-carousal .yb-warpper .ybw-img2 img {
    width: 100%;
}

/*------------------ End You Believe Section ---------------*/
/*------------------ How to teach Section ---------------*/
.how-teach {
    background-image: url("https://files.askiitians.com/static_content/content/product-page/how-teach-bg.svg");
    background-repeat: no-repeat;
    height: 100%;
    padding: 30px 0;
    background-size: cover;
    margin-top: 55px;
    background-position: center;
}

@media (max-width: 768px) {
    .how-teach {
        margin-top: -15px;
    }
}

.how-teach .how-teach-wrapper {
    padding: 60px 0 70px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec {
        margin-top: 30px;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec h3 {
    background-image: -webkit-gradient(linear, left top, right top, from(#f99c3e), to(#ff564d));
    background-image: linear-gradient(90deg, #f99c3e, #ff564d);
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec h3 {
        font-size: 20px;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec p {
        display: none;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 25px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec ul {
        padding-top: 8px;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec ul li {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    margin-top: 8px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec ul li {
        font-size: 14px;
        margin-top: 1px;
    }
}

.how-teach .how-teach-wrapper .how-teach-txt-sec ul li .fas {
    color: #eb5757;
    font-size: 14px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-txt-sec ul li .fas {
        margin-top: 12px;
    }
}

.how-teach .how-teach-wrapper .how-teach-img-sec {
    margin-top: 15px;
}

@media (max-width: 768px) {
    .how-teach .how-teach-wrapper .how-teach-img-sec {
        margin-top: 25px;
    }
}

.how-teach .how-teach-wrapper .how-teach-img-sec img {
    width: 100%;
}

/*------------------ End How to teach Section ---------------*/
/*------------------ You Believe Section ---------------*/
.you-believe {
    padding: 35px 0 20px;
}

@media (max-width: 768px) {
    .you-believe {
        padding: 0;
        margin-top: -20px;
    }
}

.you-believe .you-believe-head-txt h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #425365;
}

@media (max-width: 768px) {
    .you-believe .you-believe-head-txt h2 {
        font-size: 20px;
    }
}

.you-believe .you-believe-carousal .hsc-carousel .hsc-owl-itm {
    padding: 20px 130px;
    text-align: center;
}

@media (max-width: 768px) {
    .you-believe .you-believe-carousal .hsc-carousel .hsc-owl-itm {
        padding: 0;
        display: none;
    }
}

.you-believe .you-believe-carousal .hsc-carousel .hsc-owl-itm2 {
    display: none;
}

@media (max-width: 768px) {
    .you-believe .you-believe-carousal .hsc-carousel .hsc-owl-itm2 {
        padding: 0;
        display: block;
        margin-top: 0px;
        text-align: center;
    }
}

.you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-dots .owl-dot.active span,
.you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-theme .owl-dots .owl-dot:hover span {
    background: #f47408;
}

@media (max-width: 768px) {
    .you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-dots,
    .you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-theme .owl-nav {
        margin-top: 30px;
    }
}

.you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav {
    position: relative;
}

@media (max-width: 768px) {
    .you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav {
        display: none;
    }
}

.you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: -270px;
    left: 125px;
    background-color: #fff;
    border-radius: 50px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

    .you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav .owl-prev:focus {
        outline: none;
    }

    .you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav .owl-prev .fa {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 38px;
        color: #f47408;
    }

        .you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav .owl-prev .fa:hover {
            color: #f47408;
        }

.you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: -270px;
    right: 125px;
    background-color: #fff;
    border-radius: 50px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

    .you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav .owl-next:focus {
        outline: none;
    }

    .you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav .owl-next .fa {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        color: #f47408;
    }

        .you-believe .you-believe-carousal .hsc-carousel .owl-theme .owl-nav .owl-next .fa:hover {
            color: #f47408;
        }

/*------------------ End You Believe Section ---------------*/
/*================== Testimonial Section =================*/
.testimonial {
    padding: 40px 0px;
    background-color: #fefaf7;
    margin-top: 50px;
}

.testimonial #thumbs .owl-stage{
    transform: translate3d(0px, 0px, 0px) !important;
}

.testimonial #big .owl-item {
    padding: 0 20px;
    min-height: 310px;
}

@media (max-width: 768px) {
    .testimonial {
        padding: 30px 0 55px;
        margin-top: 35px;
    }
}

.testimonial .testimonial-head-txt {
    text-align: center;
    margin-bottom: 55px;
}

@media (max-width: 768px) {
    .testimonial .testimonial-head-txt {
        margin-bottom: 0px;
    }
}

.testimonial .testimonial-head-txt h2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #425365;
}

@media (max-width: 768px) {
    .testimonial .testimonial-head-txt h2 {
        font-size: 20px;
        width: 70%;
        margin: auto;
    }
}

@media (max-width: 768px) {
    .testimonial .testimonial-content-sec {
        text-align: center;
        padding: 0;
    }
}

.testimonial .testimonial-content-sec .test-video-sec .test-vert-line {
    margin-top: 8px;
}

@media (max-width: 768px) {
    .testimonial .testimonial-content-sec .test-video-sec .test-vert-line {
        display: none;
    }
}

@media (max-width: 768px) {
    .testimonial .testimonial-content-sec .test-video-sec .test-vert-line {
        display: none;
    }
}

.testimonial .testimonial-content-sec .test-video-sec .test-vert-line img {
    width: 80%;
}

.testimonial .testimonial-content-sec .test-text-sec .test-content-sec {
    margin-top: 20px;
    text-align: left;
    color: #425365;
}

.owl-theme .owl-controls .owl-page span{
    background:#ff6a0b !important;
}

@media (max-width: 768px) {
    .testimonial .testimonial-content-sec .test-text-sec .test-content-sec {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .testimonial .testimonial-content-sec .test-text-sec .test-content-sec .test-video-box a {
        display: block;
        width: 180px;
        margin: auto;
    }
}

.testimonial .testimonial-content-sec .test-text-sec .test-content-sec .test-video-box a img {
    width: 90%;
}

@media (max-width: 768px) {
    .testimonial .testimonial-content-sec .test-text-sec .test-content-sec .test-video-box a img {
        margin: auto;
    }
}

.testimonial .testimonial-content-sec .test-text-sec .test-content-sec .head-txt {
    font-size: 18px;
    font-weight: bold;
}

.testimonial .testimonial-content-sec .test-text-sec .test-vert-line {
    margin-top: 14px;
}

@media (max-width: 768px) {
    .testimonial .testimonial-content-sec .test-text-sec .test-vert-line {
        display: block;
    }
}

.testimonial .testimonial-content-sec .test-text-sec .test-vert-line img {
    display: block;
    width: 80%;
}

.testimonial .testimonial-content-sec .test-text-sec .test-content-sec .testimonial-txt {
    font-size: 15px;
    text-align: justify;
    height: 20px;
    width: 20px;
    display: inline;
    line-height: 32px;
}

@media (max-width: 768px) {
    .testimonial .testimonial-content-sec .test-text-sec .test-content-sec .testimonial-txt {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .testimonial .testimonial-content-sec .test-text-sec #thumbs .owl-stage {
        width: 100% !important;
    }
}

p.testimonial-txt svg {
    width: 21px;
}

p.testimonial-txt svg.fa-quote-left {
    position: relative;
    top: -4px;
}

.testimonial .testimonial-content-sec .test-text-sec #thumbs .item {
    height: 90px;
    line-height: 70px;
    padding: 0px;
    margin: 2px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    opacity: 0.6;
}

    .testimonial .testimonial-content-sec .test-text-sec #thumbs .item .test-content-sec a {
        text-decoration: none;
        display: inline-block;
    }

        .testimonial .testimonial-content-sec .test-text-sec #thumbs .item .test-content-sec a img {
            width: 100%;
        }

    .testimonial .testimonial-content-sec .test-text-sec #thumbs .item h1 {
        font-size: 18px;
    }

.testimonial .testimonial-content-sec .test-text-sec #thumbs .current .item {
    opacity: 1;
}

    .testimonial .testimonial-content-sec .test-text-sec #thumbs .current .item a img {
        border: 4px solid #fb821a;
        border-radius: 50px;
    }


.testimonial .testimonial-content-sec .test-text-sec #big.owl-theme {
    position: relative;
}



.director-sec .director-video .modal .modal-body .close, .testimonial .testimonial-content-sec .test-video-sec .student-testimonial .modal .modal-body .close {
    position: absolute;
    top: -28px;
    right: 0;
    background-color: white;
    color: #333;
    -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    width: 30px;
    height: 28px;
    border-radius: 5px;
    opacity: 1;
}

.student-testimonial .modal .modal-dialog {
    margin-top: 35px;
}

    .student-testimonial .modal .modal-dialog .modal-body {
        background-color: #fff;
        border-radius: 5px;
    }

.director-sec .director-video .modal .modal-body .close:focus, .testimonial .testimonial-content-sec .test-video-sec .student-testimonial .modal .modal-body .close:focus, .director-sec .director-video .modal .modal-body .close:active, .testimonial .testimonial-content-sec .test-video-sec .student-testimonial .modal .modal-body .close:active {
    outline: none;
}


.testimonial-content-sec .owl-nav{
    display:none;
}



/*================== End Testimonial Section =================*/
/*----------------- Student FAQ Section -------------------*/
.stud-faq-section {
    padding: 55px 0 40px;
}

    .stud-faq-section .ycs-head-txt h2 {
        font-family: Poppins;
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        color: #425365;
    }

@media (max-width: 768px) {
    .stud-faq-section .ycs-head-txt h2 {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .stud-faq-section {
        padding: 30px 0 40px;
    }
}

.stud-faq-section .stud-faq-wrapper {
    margin-top: 50px;
}

    .stud-faq-section .stud-faq-wrapper .panel-title > a:before {
        float: right !important;
        font-family: FontAwesome;
        content: "\f068";
        padding-right: 5px;
    }

    .stud-faq-section .stud-faq-wrapper .panel-title > a.collapsed:before {
        float: right !important;
        content: "\f067";
    }

    .stud-faq-section .stud-faq-wrapper .panel-title > a:hover,
    .stud-faq-section .stud-faq-wrapper .panel-title > a:active,
    .stud-faq-section .stud-faq-wrapper .panel-title > a:focus {
        text-decoration: none;
    }

    .stud-faq-section .stud-faq-wrapper .panel-default > .panel-heading {
        color: #333;
        background-color: #f5f5f5;
        border-bottom: 1px solid #f1eded;
        background-color: #ffffff;
        border: none;
        padding: 18px 24px 17px 15px;
    }

    .stud-faq-section .stud-faq-wrapper .panel-default {
        border-color: #fff;
        -webkit-box-shadow: 0 10px 29px 0 rgba(0, 0, 0, 0.06);
        box-shadow: 0 10px 29px 0 rgba(0, 0, 0, 0.06);
    }

    .stud-faq-section .stud-faq-wrapper .panel-default {
        border-color: #fff;
    }

    .stud-faq-section .stud-faq-wrapper .panel-group .panel + .panel {
        margin-top: 15px;
    }

    .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .list-group, .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .panel-body {
        border-top: none;
        padding: 0px 15px 15px;
        font-size: 14px;
    }

        .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .list-group ul, .stud-faq-section .stud-faq-wrapper .panel-group .panel-heading + .panel-collapse > .panel-body ul {
            list-style-type: none;
        }

/*----------------- End Student FAQ Section -------------------*/

/*----------------- Footer Learn More Section -------------------*/
.footer-learn-more-section {
    margin: auto;
    width: 85%;
    -webkit-box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 40px;
}

@media (max-width: 768px) {
    .footer-learn-more-section {
        width: 100%;
    }
}

.footer-learn-more-section .flms-wrapper {
    border-radius: 8px;
    border: 2px solid #333;
}

    .footer-learn-more-section .flms-wrapper .flms-txt-sec {
        padding: 40px 0 40px 30px;
    }

@media (max-width: 768px) {
    .footer-learn-more-section .flms-wrapper .flms-txt-sec {
        padding: 30px 20px;
    }
}

.footer-learn-more-section .flms-wrapper .flms-txt-sec h3 {
    font-family: Poppins;
    font-size: 28px;
    font-weight: bold;
    color: #21354b;
    margin: 0;
    padding: 0;
}

@media (max-width: 768px) {
    .footer-learn-more-section .flms-wrapper .flms-txt-sec h3 {
        font-size: 20px;
    }
}

.footer-learn-more-section .flms-wrapper .flms-txt-sec h3 span {
    color: #ff6a0b;
}

.footer-learn-more-section .flms-wrapper .flms-txt-sec .flms-cta {
    margin-top: 20px;
    padding: 16px 25px;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 21px 0 rgba(255, 106, 11, 0.52);
    box-shadow: 0 4px 21px 0 rgba(255, 106, 11, 0.52);
    background-color: #ff6a0b;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    display: block;
    width: 65%;
    text-decoration:none;
}

@media (max-width: 768px) {
    .footer-learn-more-section .flms-wrapper .flms-txt-sec .flms-cta {
        width: 92%;
        padding: 16px;
    }
}

.footer-learn-more-section .flms-wrapper .flms-right-bg {
    background-color: #f7aa21;
    -webkit-clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%);
    clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%);
    height: auto;
    text-align: center;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

@media (max-width: 768px) {
    .footer-learn-more-section .flms-wrapper .flms-right-bg {
        -webkit-clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0 100%);
        clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0 100%);
        border-radius: 7px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        height: 25vh;
    }
}

.footer-learn-more-section .flms-wrapper .flms-right-bg img {
    width: 80%;
    margin: 10px;
}

/*----------------- End Footer Learn More Section -------------------*/
/*----------------- User Form -------------------*/

@media (max-width: 768px) {
    .user-form {
        padding: 0;
    }
}

.user-form #userFormModal .modal-body .user-form-wrapper {
    background-color: #ffffff;
    border-radius: 5px;
}
.user-form #userFormModal .modal-content, .user-form #userFormModal .modal-content .modal-body, .user-form #successMessageModal .modal-content,
.user-form #successMessageModal .modal-content .modal-body {
    box-shadow: none;
    background-color: transparent;
    border: none;
}

    .user-form #successMessageModal .modal-content .modal-body .form-submit-success-mesg p {
        text-align: center;
    }
.user-form #userFormModal .modal-body .user-form-wrapper .close {
    position: absolute;
    top: 5px;
    right: 22px;
    z-index:9;
    background-color: white;
    color: #333;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    width: 30px;
    height: 28px;
    border-radius: 5px;
    opacity: 1;
}
@media (max-width:768px){
    .user-form #userFormModal .modal-body .user-form-wrapper .close {
        position: absolute;
        top: 0px;
        right: 15px;
    }
}


    .user-form #userFormModal .modal-body .user-form-wrapper .user-form-container {
        padding: 20px 20px;
        width: 90%;
    }

@media (max-width: 768px) {
    .user-form #userFormModal .modal-body .user-form-wrapper .user-form-container {
        width: 100%;
    }
}

.user-form #userFormModal .modal-body .user-form-wrapper .user-form-container .form-heading {
    padding-bottom: 15px;
}

    .user-form #userFormModal .modal-body .user-form-wrapper .user-form-container .form-heading h3 {
        font-family: Poppins;
        font-size: 22px;
        font-weight: bold;
        color: #425365;
    }

@media (max-width: 768px) {
    .user-form #userFormModal .modal-body .user-form-wrapper .user-form-container .form-heading h3 {
        font-size: 19px;
    }
}

.user-form #userFormModal .modal-body .user-form-wrapper .user-form-container form label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #1f353f;
}

.user-form #userFormModal .modal-body .user-form-wrapper .user-form-container form input, .user-form #userFormModal .modal-body .user-form-wrapper .user-form-container form select {
    height: 45px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: solid 1px rgba(0, 0, 0, 0.5);
}

.user-form #userFormModal .modal-body .user-form-wrapper .user-form-container .footer-form-cta {
    padding: 10px !important;
    background-color: #fc6118;
    color: #ffffff;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
}

.user-form #userFormModal .modal-body .user-form-wrapper .user-form-bg {
    background-image: url("/resources/images/product-page/https://files.askiitians.com/static_content/content/product-page/user-form-bg.svg");
    width: 100%;
    height: 66vh;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.inputError{
    color: red;
}
.user-form .form-submit-success-mesg {
    padding: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 29px 0 rgba(190, 190, 190, 0.4);
    box-shadow: 0 10px 29px 0 rgba(190, 190, 190, 0.4);
}

    .user-form .form-submit-success-mesg .success-mesg-icon {
        text-align: center;
        width: 60px;
        height: 60px;
        margin: auto;
        border-radius: 50%;
        background-color: #13a713;
        margin-bottom: 10px;
    }
        .user-form .form-submit-success-mesg .closeBtn{
            margin-top: 25px;
        }

        .user-form .form-submit-success-mesg .success-mesg-icon .fa {
            font-size: 35px;
            line-height: 56px;
            color: #fff;
            text-align: center;
        }

    .user-form .form-submit-success-mesg p {
        font-size: 20px;
        color: #333;
    }

@media (max-width: 768px) {
    .user-form #userFormModal .modal-body .user-form-wrapper .user-form-bg {
        display: block;
    }
}
.footer-bottom-text {
    padding: 10px 0 40px;
}
    .footer-bottom-text .moreless-button {
        display: none;
        text-decoration: none;
    }

@media (max-width:768px) {
    .footer-bottom-text {
        padding: 0px 15px 30px;
    }
    .footer-txt-read-more {
        display: none;
    }

    .footer-bottom-text .moreless-button {
        display: inline-block;
        font-size: 15px;
        color: #000;
        font-weight: 600;
    }
}
    .footer-bottom-text .footer-inner-txt {
        padding: 20px 0 10px;
    }

        .footer-bottom-text .footer-inner-txt .footer-head-txt {
            font-size: 20px;
            font-weight: 600;
        }
        .footer-bottom-text .footer-inner-txt:first-child{
            padding-bottom:0px;
        }

        .footer-bottom-text .footer-inner-txt p {
            font-size: 14px;
            padding: 10px 0;
            text-align: justify;
            line-height:22px;
        }
        .footer-bottom-text .footer-inner-txt ul {
            padding: 0px 15px;
            margin: 7px 15px;
        }
            .footer-bottom-text .footer-inner-txt ul li {
                font-size: 16px;
            }

.panel-heading .panel-title  a{
    color: unset;
    text-decoration: none !important;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
  }
  a.icon_collapse:before{
    content: "\f067" !important;
    transform: rotate(90deg) !important;
}
a.icon_close:before{
    content: "\f068" !important;
    transform: rotate(180deg) !important;
}
.col-xs-1,.col-xs-10,.col-xs-11,.col-xs-12,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9 {
    float: left
}

.col-xs-11 {
    width: 91.66666667%
}

.col-xs-10 {
    width: 83.33333333%
}

.col-xs-9 {
    width: 75%
}

.col-xs-8 {
    width: 66.66666667%
}

.col-xs-7 {
    width: 58.33333333%
}

.col-xs-6 {
    width: 50%
}

.col-xs-5 {
    width: 41.66666667%
}

.col-xs-4 {
    width: 33.33333333%;
}

.col-xs-3 {
    width: 25%
}

.col-xs-2 {
    width: 16.66666667%
}

.col-xs-1 {
    width: 8.33333333%
}

.col-xs-pull-12 {
    right: 100%
}

.col-xs-pull-11 {
    right: 91.66666667%
}

.col-xs-pull-10 {
    right: 83.33333333%
}

.col-xs-pull-9 {
    right: 75%
}

.col-xs-pull-8 {
    right: 66.66666667%
}

.col-xs-pull-7 {
    right: 58.33333333%
}

.col-xs-pull-6 {
    right: 50%
}

.col-xs-pull-5 {
    right: 41.66666667%
}

.col-xs-pull-4 {
    right: 33.33333333%
}

.col-xs-pull-3 {
    right: 25%
}

.col-xs-pull-2 {
    right: 16.66666667%
}

.col-xs-pull-1 {
    right: 8.33333333%
}

.col-xs-pull-0 {
    right: auto
}

.col-xs-push-12 {
    left: 100%
}

.col-xs-push-11 {
    left: 91.66666667%
}

.col-xs-push-10 {
    left: 83.33333333%
}

.col-xs-push-9 {
    left: 75%
}

.col-xs-push-8 {
    left: 66.66666667%
}

.col-xs-push-7 {
    left: 58.33333333%
}

.col-xs-push-6 {
    left: 50%
}

.col-xs-push-5 {
    left: 41.66666667%
}

.col-xs-push-4 {
    left: 33.33333333%
}

.col-xs-push-3 {
    left: 25%
}

.col-xs-push-2 {
    left: 16.66666667%
}

.col-xs-push-1 {
    left: 8.33333333%
}

.col-xs-push-0 {
    left: auto
}

.col-xs-offset-12 {
    margin-left: 100%
}

.col-xs-offset-11 {
    margin-left: 91.66666667%
}

.col-xs-offset-10 {
    margin-left: 83.33333333%
}

.col-xs-offset-9 {
    margin-left: 75%
}

.col-xs-offset-8 {
    margin-left: 66.66666667%
}

.col-xs-offset-7 {
    margin-left: 58.33333333%
}

.col-xs-offset-6 {
    margin-left: 50%
}

.col-xs-offset-5 {
    margin-left: 41.66666667%
}

.col-xs-offset-4 {
    margin-left: 33.33333333%
}

.col-xs-offset-3 {
    margin-left: 25%
}

.col-xs-offset-2 {
    margin-left: 16.66666667%
}

.col-xs-offset-1 {
    margin-left: 8.33333333%
}

.col-xs-offset-0 {
    margin-left: 0
}

@media(min-width: 768px) {
    .col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9 {
        float:left
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-11 {
        width: 91.66666667%
    }

    .col-sm-10 {
        width: 83.33333333%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-8 {
        width: 66.66666667%
    }

    .col-sm-7 {
        width: 58.33333333%
    }

    .col-sm-6 {
        width: 50%
    }

   
    .col-sm-4 {
        width: 33.33333333%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-2 {
        width: 16.66666667%
    }

    .col-sm-1 {
        width: 8.33333333%
    }

    .col-sm-pull-12 {
        right: 100%
    }

    .col-sm-pull-11 {
        right: 91.66666667%
    }

    .col-sm-pull-10 {
        right: 83.33333333%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-8 {
        right: 66.66666667%
    }

    .col-sm-pull-7 {
        right: 58.33333333%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-5 {
        right: 41.66666667%
    }

    .col-sm-pull-4 {
        right: 33.33333333%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-2 {
        right: 16.66666667%
    }

    .col-sm-pull-1 {
        right: 8.33333333%
    }

    .col-sm-pull-0 {
        right: auto
    }

    .col-sm-push-12 {
        left: 100%
    }

    .col-sm-push-11 {
        left: 91.66666667%
    }

    .col-sm-push-10 {
        left: 83.33333333%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-8 {
        left: 66.66666667%
    }

    .col-sm-push-7 {
        left: 58.33333333%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-5 {
        left: 41.66666667%
    }

    .col-sm-push-4 {
        left: 33.33333333%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-2 {
        left: 16.66666667%
    }

    .col-sm-push-1 {
        left: 8.33333333%
    }

    .col-sm-push-0 {
        left: auto
    }

    .col-sm-offset-12 {
        margin-left: 100%
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%
    }

    .col-sm-offset-0 {
        margin-left: 0
    }
}

@media(min-width: 992px) {
    /* .col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9 {
        float:left
    }
    .col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9 {
        float:left
    } */
    .col-md-2{
        width: 16.66666667%;
    }
    .col-md-3{
        width: 25%;
    }
    .col-md-7 {
        width: 58.33333333%
    }
   
   .why-askiitians-boxes .col-md-3{
        width: 25%;
    }
 
    .col-md-pull-12 {
        right: 100%
    }

    .col-md-pull-11 {
        right: 91.66666667%
    }

    .col-md-pull-10 {
        right: 83.33333333%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-8 {
        right: 66.66666667%
    }

    .col-md-pull-7 {
        right: 58.33333333%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-5 {
        right: 41.66666667%
    }

    .col-md-pull-4 {
        right: 33.33333333%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-2 {
        right: 16.66666667%
    }

    .col-md-pull-1 {
        right: 8.33333333%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-push-12 {
        left: 100%
    }

    .col-md-push-11 {
        left: 91.66666667%
    }

    .col-md-push-10 {
        left: 83.33333333%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-8 {
        left: 66.66666667%
    }

    .col-md-push-7 {
        left: 58.33333333%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-5 {
        left: 41.66666667%
    }

    .col-md-push-4 {
        left: 33.33333333%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-2 {
        left: 16.66666667%
    }

    .col-md-push-1 {
        left: 8.33333333%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-offset-12 {
        margin-left: 100%
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%
    }

    .col-md-offset-0 {
        margin-left: 0
    }
}

@media(min-width: 1200px) {
  
    .col-lg-pull-12 {
        right: 100%
    }

    .col-lg-pull-11 {
        right: 91.66666667%
    }

    .col-lg-pull-10 {
        right: 83.33333333%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-8 {
        right: 66.66666667%
    }

    .col-lg-pull-7 {
        right: 58.33333333%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-5 {
        right: 41.66666667%
    }

    .col-lg-pull-4 {
        right: 33.33333333%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-2 {
        right: 16.66666667%
    }

    .col-lg-pull-1 {
        right: 8.33333333%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-push-12 {
        left: 100%
    }

    .col-lg-push-11 {
        left: 91.66666667%
    }

    .col-lg-push-10 {
        left: 83.33333333%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-8 {
        left: 66.66666667%
    }

    .col-lg-push-7 {
        left: 58.33333333%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-5 {
        left: 41.66666667%
    }

    .col-lg-push-4 {
        left: 33.33333333%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-2 {
        left: 16.66666667%
    }

    .col-lg-push-1 {
        left: 8.33333333%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-offset-12 {
        margin-left: 100%
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }
}
.why-askiitians-boxes .row .col-xs-6{
    margin-top: 30px;
}
.ppt-tbl .table svg{
    height: 20px;
}
.batch-comparison-modal  .modal .modal-dialog .modal-content .modal-body{
    padding-top: 30px;
}
.bd-img-sec .bd-res-img{
    max-width: 100%;
}
@media (max-width:280px){
    .stud-testomonial-video img{
        width: -webkit-fill-available;;
    }
    .feature-sec .feature-sec-wrapper .res-feature-sec .video-icon-sec{
        top:68px;
        left:103px;
        width: 26%;
    }
}