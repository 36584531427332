/* You can add global styles to this file, and also import other style files */
.global_btn .inspired_results_btn, .global_btn .country_btn, .global_btn .courses_btn, .global_btn .res_courses_btn {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 10px 0px 10px 0px;
    /* background: #FEEFE7; */
    background: transparent;
    border-radius: unset;
    font-weight: 500;
}