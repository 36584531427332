/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

/* .lexend-strongFont {
  font-family: "Lexend Deca", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
} */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--poppins);
    user-select: none;
}

.m-t-5-per {
    margin-top: 5% !important;
}

p {
    margin-bottom: 15px;
}

.current-box-date p {
    color: #8D8B8B !important;
}

tbody, td, tfoot, th, thead, tr {
    border-width: 1px;
    padding: 0px 10px;
}

th, thead {
    padding: 20px 10px !important;
    text-align: center !important;
}

body {
    background-color: #ffffff;
    padding-top: 110px;
    overflow-x: hidden;

}

:root {
    --primary: #f65f12;
    --primary-second: #f87f41;
    --black-f: #494645;
    --color-a: #1b1816;
    --border-color: #e8e8e8;
    --poppins: "Poppins", sans-serif;
    --white: #ffffff;
    --bg-f: #ebf1dc;
    --p-color: #767473;
    --see-color: #8d8b8b;
    --inspir-bg: #f5f8ed;
    --owl-bg-color: #feefe7;
    --btn-padding: 12px 32px;
    --resourse-dark: #dce9b9;
    --resourse-font: #242e0a;
}

a {
    text-decoration: none;
}

/* CODE REUSABLE */

.btn1 {
    padding: var(--btn-padding);
    border: 0;
    background-color: var(--primary);
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: var(--white);
    margin-left: 7px;
    border-radius: 7px;
}

.container .row .left h2 {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: var(--color-a);
    margin-bottom: 10px;
}

.container .row .left p {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--p-color);
    padding-right: 150px;
}

.container .row .right {
    position: relative;
}

.container .row .right button {
    position: absolute;
    right: 22px;
    top: 20px;
}

.container .row .right button::after {
    content: "";
    position: absolute;
    border: 2px solid var(--primary);
    width: 100%;
    height: 50px;
    left: 10px;
    top: 10px;
}

.container .row .owl_1 {
    padding-top: 30px;
    padding-bottom: 40px;
}

.owl_1 .owl-carousel .item {
    padding: 12px 28px;
    background-color: var(--owl-bg-color);
    display: inline-block;
    width: auto;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: var(--color-a);
}


.header123 {
    width: 100%;
    height: auto;
    background-color: #fff;
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    -webkit-transition-duration: 0.7s;
    transition-duration: 0.5s;
    -moz-transition-property: height, top;
    -o-transition-property: height, top;
    -webkit-transition-property: height, top;
    transition-property: height, top;
    -moz-box-shadow: rgba(30, 50, 50, 0.25) 0 0.05em 0.5em;
    -webkit-box-shadow: rgba(30, 50, 50, 0.25) 0 0.05em 0.5em;
    box-shadow: rgba(30, 50, 50, 0.25) 0 0.05em 0.5em;
}

.header123.scrolling {
    height: auto;
}

.header123.hide {
    top: -140px;
}

section.register {
    padding: 30px;
}

@media (min-width: 1000px) {
    .container {
        width: 1160px !important;
    }
}
.container-fluid.topmenu {
    padding-left: 12px;
}
.container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}



.contact {
    border-bottom: 1px solid var(--border-color);
    padding: 2.5px 0;
}

.contact .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact .container a {
    text-decoration: none;
    color: var(--black-f);
    font-family: var(--poppins);
    font-size: 12px !important;
    font-weight: 500;
}

.contact .container a img {
    margin-right: 12px;
}

header nav {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

header nav .navbar-brand img {
    margin-right: 14px !important;
}

header nav .header-ul {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    list-style: none;
}

.click-items {
    display: none;
}

header nav .header-ul li {
    padding: 0 12.5px;
}

header nav .header-ul li a {
    padding: 0 !important;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    line-height: 24px;
    color: var(--color-a);
}

header nav .menu a {
    margin-right: 17.5px;
}

.first-line {
    transform: rotate(45deg);
    position: relative;
    top: 3px;
    transition: 0.5s ease;
    background-color: var(--primary) !important;
}

.last-line {
    transform: rotate(-45deg);
    bottom: 3px;
    position: relative;
    transition: 0.5s ease;
    background-color: var(--primary) !important;
}

.mid-line {
    display: none;
    transition: 0.5s ease;
    background-color: var(--primary) !important;
}

.line {
    width: 20px;
    height: 2px;
    background-color: black;
    margin: 5px 0;
    transition: 0.5s ease;
}

.lines {
    margin-right: 12px;
    display: none;
}

.my-instruction {
    display: flex;
    align-items: center;
    margin-right: 19px;
}

header nav .header_search_box {
    position: relative;
}

.header_search_box button.btn1 {
    /* background: #87dfe6; */
    color: #004d8d !important;
    border: #fff 1px solid;
    padding: 8px 42px;
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
}

.header_search_box button.btn1:hover {
    background-position: 0 0;
}

header nav input {
    width: 250px !important;
    padding: 15px 20px 15px 44px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
    height: 50px !important;
}

header nav .img-f {
    position: absolute;
    left: 15px;
}


/* SECTION 1 */
.section-1 {
    /* background-color: #FFEBE0; */
    background-color: #fcf6c4;
    width: 100%;
    position: relative;
    height: 302px;
}

.section-1 .shape {
    position: absolute;
    bottom: -1px;
    width: 100%;
    z-index: 1;
}

.section-1 .left {
    padding-top: 30px;
    padding-left: 33px;
}

.section-1 span {
    color: var(--primary);
}

.section-1 h1 {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: var(--color-a);
    line-height: 40px;
}

.section-1 p {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 10px !important;
    line-height: 32px;
    color: var(--p-color);
}

.section-1 .btn1 {
    margin-top: 14px !important;
}

.section-1 .btn1::after {
    content: "";
    position: absolute;
    border: 2px solid var(--primary);
    width: 100%;
    height: 50px;
    left: 10px;
    top: 10px;
}

.section-1 .right {
    padding-top: 12px;
}

.section-1 .owl_7 .item .right .img .img-fluid {
    height: 297px;
}


.section-2 {
    padding-top: 80px;
}

.section-2 .container .row .owl_2 .owl-carousel .item {
    padding: 20px 10px;
    width: 93%;
    min-height: 201px;
    margin: auto;
    /* border: 10px solid #fff; */
    border-radius: 25px;
    background: #eceff5;
    border-top: none;
    border-bottom: none;
    transition: all 0.5s ease-in-out;
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}

/* .section-2 .container .row .owl_2 .owl-carousel .item:hover {
    background: #c9cbcf;
} */

.section-2 .container .row .owl_2 .owl-carousel .item .box img {
    width: 70px;
    margin: auto;
    height: 70px;
}

.section-2 .container .row .owl_2 .owl-carousel .item .box {
    text-align: center;
}

.section-2 .container .row .owl_2 .owl-carousel .item:hover .box a {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    /* color: var(--white); */
    color: var(--primary);
    transition: 0.5s;
}

.section-2 .container .row .owl_2 .owl-carousel .item:hover {
    background-position: 0 0;
    /* background-image: linear-gradient(16deg, #ffd5cc 0%, #fff0ed 99%, #ffc6ba 100%);
    color: #000 !important;
    border: none; */
}

.section-2 .container .row .owl_2 .owl-carousel .item .box p {
    font-family: var(--poppins);
    margin: 18px 0px 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    /* height: 20px; */
}

.section-2 .container .row .owl_2 .owl-carousel .item .box a {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: var(--see-color);
    cursor: pointer;
    text-decoration: none;
}

.product_btn_spacing {
    padding: 5px 0px 7px;
}


.section-3 {
    padding-top: 45px;
    padding-bottom: 60px;
}

.section-3 .container .row .first {
    padding: 30px 25px !important;
    /*New changes 26-12-2022*/
    /* background: var(--resourse-dark); */

    /* new change 16-07-2024 */
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 285px;
}

.section-3 .container .row .first h2 {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: var(--resourse-font);
}

.section-3 .container .row .first p {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--resourse-font);
    margin-top: 10px;
}

/* .section-3 .container .row .first div {
    position: absolute;
    bottom: -20px;
    width: 100%;
    text-align: center;
    left: 0;
} */

.section-3 .container .row .first div a {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #242e0a;
}

.section-3 .container .row .second {
    /* background-color: #f5f8ed; */
    padding: 20px 20px 15px 20px;
}

.section-3 .container .row .second .row .col-lg-4 .box {
    display: flex;
    align-items: center;
    height: 80px;
    width: 260px;
    transition: 0.5s ease-in-out;
    border-radius: 40px;
    margin-bottom: 13px;
}

.section-3 .container .row .second .row .col-lg-4 .box:hover {
    /* background-color: var(--primary); */
    /* background-color: #d8e8ac; */
    background-color: #b4eafc;
}

.section-3 .container .row .second .row .col-lg-4 .box:hover .img {
    /* background-color: var(--primary-second); */
    background-color: #ffffffeb;
    border-radius: 100%;
}

.section-3 .container .row .second .row .col-lg-4 .box .img {
    margin-right: 10px;
    margin-left: 10px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease-in-out;
    /* background: #dce9b9; */
    background: #b4eafc;
    border-radius: 60px;
}

.section-3 .container .row .second .row .col-lg-4 .box .img img {
    width: 30px;
    height: 30px;
}

.section-3 .container .row .second .row .col-lg-4 .box:hover p {
    /* font-size: 17px; */
    /* color: var(--white); */
    color:var(--resourse-font);
}

.section-3 .container .row .second .row .col-lg-4 .box p {
    margin: 0;
    padding: 0;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-a);
    width: 180px;
}

.section-4 {
    padding: 60px 0;
}

.section-4 .container .row .left {
    margin-bottom: 10px;
}

.section-4 .container .row .right {
    position: relative;
    margin-bottom: 40px;
}

.section-4 .read {
    padding: 0px 20px;
}

.section-4 .read span {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--p-color);
}

.section-4 .container .row .card1 .box .read p {
    font-family: var(--poppins);
    font-style: normal;
    font-size: 18px;
    line-height: 27px;
    margin-top: 4px;
    margin-bottom: 30px;
    color: var(--color-a);
}

.section-4 .box .read a {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-decoration: none;
    position: relative;
    color: var(--color-a);
}

.section-4 .box .read a::after {
    content: "";
    position: absolute;
    width: 85%;
    height: 2px;
    background-color: var(--color-a);
    left: 0;
    bottom: -2px;
}

.section-4 .card1:hover {
    box-shadow: 0 0 30px rgba(27, 24, 22, 0.1);
}

@media (min-width: 992px) {
    .section-4 .owl_8 {
        max-width: 3000px;
        width: calc(100% + 60px);
    }

    .section-4 .owl_8 .item {
        padding: 15px;
    }
}

.section-4 .box:hover .read p {
    text-shadow: 0 0 1px #000;
}

.section-4 .box:hover .read a {
    color: var(--primary);
}

.section-4 .box:hover .read a::after {
    background: var(--primary);
}

/* SECTION 5 */

.section-5 {
    background-color: var(--inspir-bg);
    padding: 80px 0;
}

.section-5 .container .row .owl_1 .owl-carousel .item {
    padding: 8px 32px !important;
}

.section-5 .col-lg-12 .row .col-lg-9 h3 {
    margin-top: 25px;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
}

.section-5 .col-lg-12 .row .col-lg-9 h3 span {
    font-weight: 700;
}

.section-5 .col-lg-12 {
    margin-top: 40px;
}

.section-5 .container .row .owl_3 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item {
    padding: 8px 32px;
    background-color: #feefe7;
}

.section-5 .container .row .owl_4 h2 {
    font-family: var(--poppins);
    font-weight: 500;
    line-height: 45px;
    font-size: 22px;
    font-style: normal;
}

.section-5 .container .row .owl_4 h2 span {
    font-weight: 800;
}

.section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item {
    text-align: center;
}

.section-5 .container .row .owl_4 {
    margin-top: 40px;
}

.section-5 .container .row .top {
    margin-top: 40px;
}

.section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item img {
    width: 80px;
    height: 80px;
    margin: auto;
    border: 3px solid #fff;
    border-radius: 500px;
    background: #ffc851;
}

.section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .text {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 15px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
}

.section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .text1 {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #767473;
    margin-top: 5px;
    margin-bottom: 0px;
    height: 19px;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .rank {
    /* padding: 6px 20px;
    background-color: #494645;
    color: var(--white);
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline-table; */
    margin-top: 15px;
    padding: 6px 20px;
    background-color: rgb(255 255 255 / 70%);
    color: #f65f12;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline-table;
    border-radius: 16px;
    width: 100%;
}

.section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .rank span {
    font-weight: 700;
}

.section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .rank span.coursNam {
    color: #333;
    font-weight: 500;
}

.section-5 .container .row .row {
    /* padding-top: 35px; */
    margin-top: 0px;
    padding-bottom: 0px;
}

.section-5 .container .row .row .col-lg-3 {
    text-align: center;
}

.section-5 .container .row .row .col-lg-3 img {
    margin: 8px 15px 10px 15px;
}

.section-5 .container .row .row .col-lg-3 p {
    /* font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-a);
    margin-bottom: 0px; */

    margin: 10px 0px 3px;
    font-weight: 700;
    font-size: 20px;
    color: var(--color-a);
    line-height: 21px;
    text-transform: capitalize;
    letter-spacing: normal;
}

.section-5 .container .row .row .col-lg-3 p span {
    /* font-weight: 700;
    font-size: 28px;
    margin-right: 10px;
    color: var(--color-a);
    line-height: 42px; */
    font-weight: 700;
    font-size: 22px;
    margin-right: 6px;
    color: var(--color-a);
    /* line-height: 21px; */
}

.section-5 .container .row .row .col-lg-3 span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* color: var(--p-color); */
    color: #f65f12;
    letter-spacing: 1px;
}

#inspired_owl .owl-carousel .owl-stage-outer .owl-stage .owl-item {
    width: auto !important;
}

#product_btn .owl-carousel .owl-stage-outer .owl-stage .owl-item {
    width: auto !important;
}

/* SECTION 6 */

.section-6 {
    padding: 60px 0 47px;
}

.section-6 .container #teacher_data {
    text-align: center;
}

.section-6 .container .content h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
}

.section-6 .container .content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: var(--p-color);
    margin-bottom: 16px;
    padding: 0 300px;
}
.section-6 .container .item {
    padding: 20px;
    margin: 0px 0px;
    height: 300px;
    margin-bottom: 20px;
    border-radius: 10px 35px 10px 35px;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    text-align: center;
}
/* Overlay for loader */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6); /* Slightly darker background */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Spinner container */
  .spinner {
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  /* Inner spinning circles with gradient borders */
  .spinner div {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 8px solid transparent;
    border-radius: 50%;
    background: conic-gradient(from 0deg, #ffda22, #ff5858, #ffda22);
    -webkit-mask: radial-gradient(circle, transparent 60%, black 61%);
    mask: radial-gradient(circle, transparent 60%, black 61%);
    animation: spin 1.2s linear infinite;
  }
  
  .spinner div:nth-child(2) {
    background: conic-gradient(from 0deg, #ffea00, #fb8601, #ff1100);
    animation: spin 1.2s linear infinite reverse;
  }
  
  /* Keyframes for spinning animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
.section-6 .container .item img {
    margin:0px auto 15px;
    border-radius: 10px 20px 10px 20px;
    box-shadow: 0px 4px 10px #dbdfe3;
    width: auto;
}

.section-6 .container .item h6 {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #333;
}

.section-6 .container .item p {
    margin: 10px 0px 3px !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #808080;
}

.section-6 .container .item span {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #f65f12;
    letter-spacing: 1px;
}

/* SECTION 8 */

.section-8 {
    padding: 60px 0;
}

.section-8 .container .row .left-1 {
    padding: 40px 50px 25px 50px;
    background: #ffc5b5;
}

.section-8 .left-1 span {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: var(--color-a);
}

.section-8 .left-1 h5 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
    margin-bottom: 10px;
}

.section-8 .left-1 .p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--black-f);
    margin-bottom: 50px;
}

.section-8 .left-1 .app-logo img {
    margin-right: 20px;
}

@media (max-width:280px) {
    .section-8 .left-1 .app-logo {
        display: block !important;
        text-align: center;
    }

    .section-8 .left-1 .app-logo img {
        margin: 0;
        margin-bottom: 10px;
    }

    .section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .text1 {
        font-size: 12px;
        line-height: 16px;
    }

    .section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .rank {
        padding: 5px 7px !important;
        font-size: 11px !important;
        line-height: 21px !important;
    }

    .coursebutton,
    .main .container #course_data .hi .packageDetail_gradeID_data .content .button .show {
        padding: 9px 12px !important;
    }
}

/* SECTION 9 */

.section-9 {
    padding-bottom: 40px;
}


.faq_section {
    padding: 50px 0px 30px;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

@media screen and (min-width: 1024px) {
    #global_owl .owl-carousel .owl-stage-outer .owl-stage .owl-item {
        width: 364px !important;

    }
}

.section-9 .row .owl-carousel .owl-stage-outer .owl-stage .owl-item .item {
    padding: 10px 28px;
}

.section-9 .global h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
}

.section-9 .global p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 30px;
    color: #767473;
    font-style: normal;
}

.section-9 .owl_6 .item {
    background-color: var(--owl-bg-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--color-a);
}

.section-9 .cards-1 {
    margin-top: 26px;
}

.section-9 .cards-1 .card {
    padding: 20px 25px !important;
    border: 0px solid #a4a3a2;
    border-radius: 25px;
    /* background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); */
    background: #eceff5;
    transition: all 0.3s ease-in-out;
}

.section-9 .cards-1 .card h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-a);
    margin-bottom: 10px;
    display: -webkit-box;
    height: 53px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.section-9 .cards-1 .card p {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    padding-right: 0px;
    color: var(--p-color);
    margin-bottom: 15px;
    height: 40px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.section-9 .cards-1 .card a {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    position: relative;
    color: var(--primary);
    cursor: pointer;
}

.section-9 .cards-1 .card a::after {
    display: none;
    content: "";
    position: absolute;
    width: 85%;
    height: 2px;
    background-color: var(--color-a);
    left: 0;
    bottom: -2px;
}

.section-9 .cards-1 .card:hover {
    border-color: var(--primary);
    background-color: lightgoldenrodyellow;
}

.section-9 .cards-1 .card:hover h5 {
    color: var(--primary);
}

.section-9 .cards-1 .card:hover p {
    color: var(--black-f);
}

.section-9 .cards-1 .card:hover a {
    color: var(--primary);
}

.section-9 .cards-1 .card:hover a::after {
    background-color: var(--primary);
}

.active-card {
    border-color: var(--primary);
}

.active-card h5 {
    color: var(--primary) !important;
}

.active-card a {
    color: var(--primary) !important;
}

.active-card a::after {
    background-color: var(--primary);
}

/* SECTION 10 */

.section-10 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.section-10 .questions h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
}

.section-10 .questions p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--p-color);
    margin-bottom: 50px;
}

.section-10 .accordion {
    padding: 0 130px;
}

.section-10 .accordion .accordion-item {
    border: 2px solid #f4f3f3;
    margin-bottom: 20px;
    box-shadow: none;
}

.section-10 .accordion .accordion-item .open {
    border: 2px solid #FEE7DB !important;
    box-shadow: 0px 0px 20px rgb(27 24 22 / 10%) !important;
}

.section-10 .accordion h2 {
    padding: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-a);
}

.section-10 .accordion-button:focus {
    box-shadow: none !important;
    border-color: red !important;
}

.section-10 .accordion-button {
    padding: 0;
}

.section-10 .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: var(--primary);
}

.section-10 .accordion-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #808080;
}

.section-10 .accordion .accordion-collapse {
    padding-left: 20px;
    padding-right: 40px;
    transition: ease-in-out 0.5s;
}

.faq_desc {
    padding: 0 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000;
}

/* footer */

footer {
    /* background: var(--color-a); */
    background: #3d3d3d;
    padding-top: 40px;
}

footer .container .row p {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    /* color: var(--p-color); */
    color: #a5a5a5;
    padding-top: 12px;
    padding-right: 13%;
}

footer .container .row .col-lg-4 {
    margin-bottom: 50px;
}

footer .container .row h5 {
    font-weight: 600;
    font-size: 19px;
    /* line-height: 26px; */
    color: #ffffff;
    font-family: var(--poppins);
    margin: 11px 0px 12px;
}

.aiCaption p {
    font-weight: 400;
    font-size: 13px !important;
    line-height: 20px;
    /* color: var(--p-color); */
    color: #a5a5a5;
    padding-top: 0px !important;
    padding-right: 0px !important;
}

footer .container .row ul li {
    margin-bottom: 0;
    padding: 5px 0px;
}

.list-unstyled .footer.fa {
    color: #a5a5a5!important;
    font-size: 13px !important;
    padding: 4px 0px !important;
    margin-right: 8px;
    text-align: center;
    border: 1px solid #5f5f5f;
    height: 25px;
    width: 25px;
    border-radius: 40px;
}

footer .container .row .second {
    padding-left: 65px;
}

footer .container .row .second ul li a {
    font-weight: 400;
    font-size: 13px;
    line-height: 23px;
    /* color: var(--p-color); */
    color: #a5a5a5;
    text-decoration: none;
}

footer .container .row .third ul li a {
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: #a5a5a5;
    text-decoration: none;
}

footer .container .row .third ul li a img {
    margin-right: 15px;
}

footer .container .row .col-lg-12 {
    border-top: 1px solid #4f4f4f;
    padding-top: 22px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0px;
}

footer .container .row .col-lg-12 .one {
    display: flex;
}

footer .container .row .col-lg-12 .one div {
    width: 29px;
    height: 29px;
    background: #636363;
    border-radius: 50%;
    margin-left: 15px;
    display: grid;
    place-items: center;
    cursor: pointer;
}

footer .container .row .col-lg-12 .one div:hover {
    background: var(--primary);
}

footer .container .row .col-lg-12 .two a,
footer .container .row .col-lg-12 .two p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #8d8b8b;
    text-decoration: none;
}

footer .container .row .second ul li a:hover {
    color: var(--primary);
}

footer .container .row .second ul li .active {
    color: var(--primary);
}

footer .container .row .third ul li a:hover {
    color: var(--primary);
}

/* owl-carousel settings */
.my-owl-dots button {
    border: 0;
    outline: 0;
    background-color: transparent;
    margin: 0 2.5px;
}

.my-owl-dots span {
    width: 4px;
    height: 4px;
    background: #d1d1d0;
    display: inline-block;
    line-height: 30px;
    cursor: pointer;
    transition: ease-in-out 0.5s;
}

.my-owl-dots .active span {
    width: 20px;
    background: var(--primary);
    transition: ease-in-out 0.5s;
}

.my-owl-arrow {
    height: 30px;
    cursor: pointer;
    z-index: 100;
}

.my-owl-arrow.my-owl-left svg {
    margin-top: 14px;
    z-index: 100;
}

.my-owl-arrow.my-owl-right svg {
    margin-top: -3px;
    z-index: 100;
}

/* ==========================================
               PRODUCT PAGE START
    =========================================    */

.main {
    padding-top: 30px;
    padding-bottom: 30px;
}

.cmstopMain {
    padding-top: 0px !important;
}

main .container .row .best {
    margin-bottom: 0px;
}

.main .container .row .left-1 {
    padding-right: 100px;
}

.main .container .row .left-1 h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
    margin-top: 10px;
}

.main .container .row .left-1 p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--see-color);
    margin-bottom: 40px;
}

.main .container .row .right-1 {
    position: relative;
}

.main .container .row .owl_10 {
    margin-bottom: 30px;
}

.main .container .row .owl_10 .owl-carousel .owl-stage-outer .owl-stage .owl-item {
    width: auto !important;
}

.main .container .row .owl_10 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item {
    padding: 8px 32px;
    background-color: #feefe7;
}

.main .container .row .right-1 select {
    background-color: #FFF;
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23F65F12' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); */
    border-radius: 0px;
    border: 1px solid var(--black-f);
    color: var(--color-a);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    width: 150px;
    padding: 8px 30px 8px 20px;
    position: absolute;
    right: 12px;
    border-radius: 50px;
}

.main .container .row .col-lg-4 {
    margin-bottom: 0px;

}

#course_data .hi .packageDetail_gradeID_data {
    width: 31%;
    margin: 20px 10px 25px;
    padding: 20px 20px 10px 20px;
    border: 1px solid #e5e5e5;
    border-radius: 30px;
}

#course_data .hi .content .coursePrice {
    display: flex;
}

#course_data .hi .packageDetail_else_data {
    width: 370px;
    margin: 0 0 25px;
    padding: 0 12px;
}

.main .container #course_data .hi .packageDetail_gradeID_data .content,
.main .container #course_data .hi .packageDetail_else_data .content {
    padding: 20px 0px;
}

.main .container #course_data .hi .packageDetail_gradeID_data .content div,
.main .container #course_data .hi .packageDetail_else_data .content div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .main .container #course_data .hi .packageDetail_gradeID_data .content .one h4,
.main .container #course_data .hi .packageDetail_else_data .content .one h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary);
} */
.main .container #course_data .hi .packageDetail_gradeID_data .content .one h4,
.main .container #course_data .hi .packageDetail_else_data .content .one h4 {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--white);
    /* background: #e55f6c; */
    background: linear-gradient(-45deg,#23a6d5, #23d5ab);
    padding: 2px 8px;
    border-radius: 5px;
    text-transform: uppercase;
}

.main .container #course_data .hi .packageDetail_gradeID_data .content .one div,
.main .container #course_data .hi .packageDetail_else_data .content .one div {
    padding: 4px 10px !important;
    border-radius: 20px;
    background: #f4f3f3;
}

.main .container #course_data .hi .packageDetail_gradeID_data .content .one div p,
.main .container #course_data .hi .packageDetail_else_data .content .one div p {
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: var(--color-a);
    margin: 0;
}

.main .container #course_data .hi .packageDetail_gradeID_data .content p,
.main .container #course_data .hi .packageDetail_else_data .content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333;
}

.main .container #course_data .hi .packageDetail_gradeID_data .content .two h4,
.main .container #course_data .hi .packageDetail_else_data .content .two h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--see-color);
}

.main .container #course_data .hi .packageDetail_gradeID_data .content .BatchSizes {
    display: flex;
    justify-self:start;

}

.main .container #course_data .hi .packageDetail_gradeID_data .content .two p,
.main .container #course_data .hi .packageDetail_else_data .content .two p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--see-color);
    margin-bottom: 8px;
    margin: 0px 0px 8px 8px;
}

.main .container #course_data .hi .packageDetail_gradeID_data .content h1,
.main .container #course_data .hi .packageDetail_else_data .content h1 {
    font-family: "Lexend Deca", sans-serif !important;
    color: #000;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 15px;
}

.main .container #course_data .hi .packageDetail_gradeID_data .content .button button,
.main .container #course_data .hi .packageDetail_else_data .content .button button {
    padding: 9px 21.5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--black-f);
    margin-right: 2px;
    border: 1px solid #d1d1d0;
}

/* .main .container #course_data .hi .packageDetail_gradeID_data .content .button .show,
.main .container #course_data .hi .packageDetail_else_data .content .button .show {
    line-height: 22px;
    font-weight: 600;
    font-size: 17px;
    background-color: #ff6a0b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    display: block;
    border-radius: 45px;
    border: 0px solid #fff;
    background-image: linear-gradient(120deg,#ffda22 0%,#ff5858 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
} */

/* #course_data .hi .packageDetail_gradeID_data:hover{
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(27, 24, 22, 0.05);
} */

/* .main .container #course_data .hi .packageDetail_gradeID_data .content:hover,
.main .container #course_data .hi .packageDetail_else_data .content:hover {
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(27, 24, 22, 0.05);
} */

/* package details desc fix height */
.main .container #course_data .hi .packageDetail_gradeID_data .content .package_desc,
.main .container #course_data .hi .packageDetail_else_data .content .package_desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 50px;
    margin-bottom: 10px;
}

.package_box {
    display: flex;
}

/* MAIN SECTION END */
/* SECTION 11 START */
.section-11 .accordion .accordion-item {
    border: 2px solid #f4f3f3;
}

.section-11 .accordion h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-a);
}

.section-11 .accordion-button:focus {
    box-shadow: none !important;
    border-color: red !important;
}

.section-11 .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: var(--primary);
}

.section-11 .accordion-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #808080;
    padding-top: 0px;
}

.section-11 .accordion .accordion-collapse {
    padding: 0 20px 0px 20px !important;
}

.section-11 {
    padding-top: 30px;
    padding-bottom: 80px;
}

.section-11 .container .row .col-lg-12 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
}

.section-11 .container .row .col-lg-12 h4 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
    margin-bottom: 10px;
}

.section-11 .container .row .col-lg-12 p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--p-color);
}

.main-2 {
    background: #fff7f3;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 0;
}

.main-2 .container .row .left-2 h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
}

.main-2 .container .row .left-2 p {
    margin-bottom: 15px;
}

.main-2 .container .row .left-2 h1 span {
    color: var(--primary);
}

/* .main-2 .container .row .left-2 .btn1::after {
    content: "";
    position: absolute;
    border: 2px solid var(--primary);
    width: 100%;
    height: 50px;
    left: 10px;
    top: 10px;
} */

.main-2 .container .row .left-2 .row .item {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-a);
    padding: 6px 8px;
    margin-right: 15px;
}

.main-2 .container .row .owl_11 .owl-carousel .owl-stage-outer .owl-stage .owl-item {
    width: auto !important;
}

.main-2 .container .row .owl_11 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item,
.main-2 .container .row .owl_11 .global_btn .country_btn {
    padding: 6px 15px;
    background-color: #feefe7;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border: none;
}

.main-2 .container .row .owl_11 .global_btn .country_btn {
    background-color: #f65f12;
    color: #fff;
}

.main-2 .container .row .left-2 p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: var(--black-f);
}

.main-2 .container .row .right-2 {
    margin-top: 15px;
}

.feature {
    padding-top: 80px;
    padding-bottom: 80px;
    background: white !important;
}

.feature .container .row .top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.feature .container .row .top h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
}

.feature .container .row .top p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--p-color);
    padding-right: 300px;
    padding-left: 300px;
    text-align: center;
}

.feature .container .row .col-lg-12 {
    display: grid;
    place-items: center;
}

.provider {
    padding-top: 80px;
}

.provider .container .row .picture {
    position: relative;
    margin-bottom: 80px;
}

.provider .container .row .picture #first {
    position: absolute;
    top: 0px;
    right: 0px;
}

.provider .container .row .picture #second {
    position: absolute;
    top: 270px;
    left: 0px;
}

.provider .container .row .picture #third {
    position: absolute;
    top: 540px;
    right: 0px;
}

/*  PROVIDER SECTION END*/

/*  DEMO SECTION START*/

.demo {
    padding-top: 80px;
    padding-bottom: 80px;
    background: #f5f8ed;
}

.demo .container .row .top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
}

.demo .container .row .top h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
}

.demo .container .row .top p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--p-color);
    padding-right: 300px;
    padding-left: 300px;
    text-align: center;
}

.demo .container .row .col-lg-6 .first {
    display: flex;
    align-items: center;
}

.demo .container .row .col-lg-6 .first:active {
    background-color: var(--primary);
    border-radius: 5px;
}

.demo .container .row .col-lg-6 .first h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-a);
}

.demo .container .row .col-lg-6 .first p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-a);
}

.sec {
    background: var(--white);
}

.about-The-course .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-The-course .bottom h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
}

.about-The-course .bottom p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--p-color);
    padding-right: 230px;
    padding-left: 230px;
    text-align: center;
    margin-bottom: 20px;
}

.about-The-course table {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #b9b9b9;
}

.about-The-course table thead tr th:first-child {
    color: var(--color-a);
    background: rgba(27, 24, 22, 0.02);
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: #b9b9b9;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.about-The-course table thead tr th:nth-child(2),
.about-The-course table thead tr th:nth-child(3) {
    color: var(--color-a);
    text-align: center;
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: #b9b9b9;
    background: rgba(27, 24, 22, 0.02);
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.about-The-course table tbody tr td:nth-child(1) {
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: #b9b9b9;
    font-weight: 400;
    font-size: 1 6px;
    width: 540px;
    line-height: 24px;
}

.about-The-course table tbody tr td:nth-child(2),
.about-The-course table tbody tr td:nth-child(3) {
    color: var(--color-a);
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: #b9b9b9;
    width: 310px;
}

.about-The-course table tbody tr td:nth-child(2) del,
.about-The-course table tbody tr td:nth-child(3) del {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #bbbab9;
}

.about-The-course table tr:hover,
.about-The-course table tr:hover {
    background: rgba(27, 24, 22, 0.02);
}

.about-The-course table tbody tr td:nth-child(2) .rupee,
.about-The-course table tbody tr td:nth-child(3) .rupee {
    font-weight: 600;
    font-size: 24px !important;
    line-height: 36px;
}

/* .about-The-course table tbody tr td:nth-child(2) button,
.about-The-course table tbody tr td:nth-child(2) span {
    padding: 13px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: calc(100% - 40px);
    text-align: center;
    background: rgba(255, 255, 255, 0.002);
    border-width: 1px;
    margin: 20px;
    border-style: solid;
    border-color: #b9b9b9;
    border-radius: unset;
    background-color: var(--primary);
    color: var(--white);
} */


.about-The-course table tbody tr td:nth-child(2) button,
.about-The-course table tbody tr td:nth-child(2) span {
    width: calc(100% - 40px);
    line-height: 22px;
    margin: 20px auto 10px;
    font-weight: 600;
    font-size: 17px;
    background-color: #ff6a0b;
    color: #fff;
    border: none;
    padding: 14px 20px;
    display: block;
    border-radius: 45px;
    border: 0px solid #fff;
    background-image: linear-gradient(120deg, #ffda22 0%, #ff5858 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
}

/* .about-The-course table tbody tr td:nth-child(3) button,
.about-The-course table tbody tr td:nth-child(3) span {
    padding: 13px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: calc(100% - 40px);
    text-align: center;
    background: rgba(255, 255, 255, 0.002);
    border-width: 1px;
    margin: 20px;
    border-style: solid;
    border-color: #b9b9b9;
    background-color: var(--primary);
    color: var(--white);
    border-radius: unset;
} */

.about-The-course table tbody tr td:nth-child(3) button,
.about-The-course table tbody tr td:nth-child(3) span {
    width: calc(100% - 40px);
    line-height: 22px;
    margin: 20px auto 10px;
    font-weight: 600;
    font-size: 17px;
    background-color: #ff6a0b;
    color: #fff;
    border: none;
    padding: 14px 20px;
    display: block;
    border-radius: 45px;
    border: 0px solid #fff;
    background-image: linear-gradient(120deg, #ffda22 0%, #ff5858 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
}


.about-The-course table tbody tr td:nth-child(2) button:hover,
.about-The-course table tbody tr td:nth-child(2) span:hover,
.about-The-course table tbody tr td:nth-child(3) button:hover,
.about-The-course table tbody tr td:nth-child(3) span:hover {
    background-position: 0 0;
}

.about-The-course table tbody tr td:nth-child(1) .change-color {
    color: var(--primary);
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
}

/* ONE TO ONE PAGE START */
.one-to-one {
    background: #fff7f3;
    padding-top: 80px;
}

.one-to-one .container .col-lg-2 {
    padding-top: 100px;
}

.one-to-one h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: var(--color-a);
}

.one-to-one h1 span {
    color: var(--primary);
}

.one-to-one p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: var(--black-f);
    margin: 0px;
}

.one-to-one .para {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 21px;
}

.one-to-one .col-lg-8 {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.one-to-one .col-lg-8 .col-auto .item-1 {
    padding: 6px 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-a);
    background-color: #feefe7;
    margin-bottom: 40px;
}

.one-to-one .col-lg-8 .col-auto .item-1:hover {
    background: var(--primary);
    color: var(--white);
}

.one-to-one .col-lg-8 .col-lg-12 .btn2 {
    position: relative;
    padding: 12px 32px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: var(--white);
    border: 1px solid var(--primary);
    background: var(--primary);
}

.one-to-one .col-lg-8 .col-lg-12 .btn2::after {
    content: "";
    position: absolute;
    border: 2px solid var(--primary);
    width: 100%;
    height: 50px;
    left: 10px;
    top: 10px;
}

.more-course {
    padding-top: 80px;
    padding-bottom: 50px;
    background: #f5f5f5;
}

.more-course .left,
.right {
    margin-bottom: 30px;
}

.more-course .col-lg-6 {
    margin-bottom: 30px;
}

.more-course .col-lg-6 .img {
    margin-right: 20px;
}

.more-course .col-lg-6 h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-a);
}

.more-course .col-lg-6 p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-a);
}

/* ASSITANCE SECTION START */

.assitance {
    padding-top: 80px;
    padding-bottom: 80px;
    background: #fff7f3;
}

.assitance .space {
    margin-bottom: 80px;
}

.assitance .col-lg-6 h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
}

.assitance .col-lg-6 .para {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--p-color);
    margin-bottom: 21px;
}

.assitance .col-lg-6 .circle {
    margin-bottom: 21px;
}

.assitance .col-lg-6 .d-flex p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-a);
    padding-left: 13px;
}

.assitance .col-lg-6 .sign-img {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.assitance .col-lg-6 .sign-img .space-2 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.provide .chapter-section .bg-info {
    height: 193px !important;
    position: relative;
    border-top-left-radius: 120px;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
    /* background: #feefe7 !important; */
    background:#e9ead1!important;
}

.provide {
    padding: 80px 0;
}

.provide .chapter-section {
    padding-top: 67px;
}

.provide .chapter-section .bg-info .row .col-2 {
    width: auto;
}

.provide .chapter-section .bg-info .row .col-2 .items-chapters {
    width: calc(180px - 20px);
    text-align: center;
    padding: 40px 10px;
}

.provide .chapter-section .bg-info .row .col-2 .items-chapters img {
    filter: drop-shadow(0px 3px 6px #cecfbb);
}

.provide .chapter-section .bg-info .row .col-2 .items-chapters div {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 15px;
}

.provide .chapter-section .bg-info .row .col-2 .items-chapters span {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    /* color: var(--primary); */
    color: #5c5f25;
}

.provide .chapter-section .bg-info .row .col-2 .border-chapter {
    position: relative;
}

.provide .chapter-section .bg-info .row .col-2 .border-chapter::after {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 56.5px);
    width: 1px;
    height: 113px;
    background-color: #c2c3ac;
}

.provide .chapter-section .mid {
    height: 193px !important;
    position: relative;
    border-top-right-radius: 120px;
    border-top-left-radius: 20px !important;
    /* background: #feefe7 !important; */
    background:#e9ead1!important;
    border-bottom-left-radius: 20px !important;
}

.provide .chapter-section .bg-info .row .col-2:nth-child(1) .chapter-girl-img {
    position: absolute;
    top: -57px;
    left: -30px;
}

.provide .chapter-section .bg-info .row .col-2 .border-chapter1 {
    position: relative;
}

.provide .chapter-section .bg-info .row .col-2 .border-chapter1::after {
    content: "";
    position: absolute;
    right: 0%;
    top: calc(50% - 56.5px);
    width: 1px;
    height: 113px;
    background-color: #c2c3ac;
    /* background-color: #ffd1b8; */
}

.provide .chapter-section .bg-info .row .col-2:nth-child(5) .chapter-girl-img {
    position: absolute;
    top: -57px;
    right: -30px;
}

.provide .chapter-section1 {
    background: #feefe7;
}

.provide .chapter-section1 .text-mobile {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
}

.provide .chapter-section1 {
    padding: 10px 12px;
}

.provide .chapter-section1 img {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}

.provide .top-provide {
    margin-top: 22px;
}

.provide .chapter-section1 .row .col-4 {
    text-align: center;
}

.provide .chapter-section1 .row .col-4 h4 {
    font-weight: 500;
    font-size: 9px;
    line-height: 14px;
    text-align: center;

    color: #000000;
}

.test-series {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #fff7f3;
}

.test-series h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    padding-right: 60px;
    margin-bottom: 16.5px;
    color: var(--color-a);
}

.test-series h1 span {
    color: var(--primary);
}

.test-series .btn1 {
    margin-bottom: 40px;
}

.test-series .btn1::after {
    content: "";
    position: absolute;
    left: 10px;
    top: 10px;
    width: 100%;
    height: 50px;
    border: 2px solid var(--primary);
}

.test-series .boxes {
    padding: 15px 0;
    background: #fee7db;
    width: 380px;
    justify-content: space-between;
}

.test-series .boxes .box {
    margin: 0 20px;
    text-align: center;
}

.test-series .boxes .box span {
    color: var(--primary);
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
}

.test-series .boxes .box h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #322f2d;
}

.test-series .owl-carousel .owl-item img {
    width: auto;
}

.study-package .container .row .right-11 {
    position: relative;
}

.study-package .right-11 select {
    position: absolute;
    right: 12px;
    box-sizing: border-box;
    padding: 14px 50px 14px 15px;
    background: #fff8f3;
    border: 1.6px solid #f65f12;
    color: var(--primary);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 150px;
    border-radius: 0;
}

.study-package .right-11 input {
    padding: 15px 15px 15px 44px;
    border: 1.4px solid #d1d1d0;
    outline: none;
    margin-right: 40px !important;
}

.study-package .right-11 .img-f {
    position: absolute;
    top: 18px;
    left: 15px;
}

.study-package .row .subjects {
    margin-top: 40px;
    margin-bottom: 30px;
}

.study-package .row .subjects div div {
    padding: 15px;
    border: 1px solid lightgray;
    width: 275px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--p-color);
}

.study-package .row .subjects div div:hover {
    background: #fff7f3;
    color: var(--primary);
    border: 1px solid var(--primary);
}

.study-package .row .col-lg-4 .material {
    padding: 20px;
}

.study-package .row .col-lg-4 .material h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-a);
}

.study-package .row .col-lg-4 .material .price {
    margin-bottom: 15px;
}

.study-package .row .col-lg-4 .material .price h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: var(--primary);
    margin: 0px;
    margin-right: 10px;
}

.study-package .row .col-lg-4 .material .price span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fbaf88;
    text-decoration-line: line-through;
}

.study-package .row .col-lg-4 .material .name {
    margin-bottom: 10px;
    margin-top: 15px;
}

.study-package .row .col-lg-4 .material .name span {
    padding: 4px 10px;
    background: lightgray;
    border-radius: 20px;
    margin-right: 10px;
}

.study-package .row .col-lg-4 .material .info {
    margin-bottom: 20px;
}

.study-package .row .col-lg-4 .material .info p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #8d8b8b;
    margin-bottom: 0px;
}

.study-package .row .col-lg-4 .material .info a {
    color: var(--primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.study-package .row .col-lg-4 .material .button button {
    padding: 9px 34px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--black-f);
    border: 1px solid lightgray;
}

.study-package .row .col-lg-4 .material .button .second {
    background: var(--primary);
    color: White;
    margin-left: 7px;
}

.self-main {
    padding-top: 50px;
    padding-bottom: 50px;
}

.self-main .container .row .info h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: var(--color-a);
}

.self-main .container .row .info .rating_box {
    margin-bottom: 20px;
    display: flex;
}

.self-main .container .row .info .total_enrolled {
    font-size: 15px;
    color: gray;
    margin: auto 10px;
}

.self-main .container .row .info .price .span {
    text-decoration-line: line-through;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #bbbab9;
}

.self-main .container .row .info .price {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-a);
    margin-bottom: 0px;
}

.self-main .container .row .info h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: var(--primary);
    margin-bottom: 5px;
}

.self-main .container .row .info .para {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--black-f);
    text-align: justify;
    margin-bottom: 5px;
}

.self-main .container .row .info .para span {
    color: var(--primary);
    cursor: pointer;
}

.self-main .container .row .info .pre {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--black-f);
}

.self-main .container .row .info .teacher span {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #5f5d5c;
}

.self-main .container .row .info .teacher span span {
    text-decoration: underline;
    margin-right: 5px;
}

.self-main .container .row .info .teacher span:hover span {
    color: var(--primary);
    cursor: pointer;
}

.self-main .container .row .info .teacher .first {
    margin-right: 80px;
}

.self-main .container .row .info .button button {
    padding: 13px 61px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
}

.self-main .container .row .info .button .first {
    color: var(--color-a);
    border: 1px solid lightgray;
}

.self-main .container .row .info .button .second {
    color: white;
    background: var(--primary);
    border: 1px solid var(--primary);
}

.course-feature .container .row .col-lg-7 .row {
    padding-bottom: 20px;
    border-bottom: 2px solid #e8e8e8;
}

.course-feature .container .row .col-lg-7 h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-a);
    margin-bottom: 20px;
}

.course-feature .container .row .col-lg-7 .left-3 {
    padding-left: 30px;
}

.course-feature .container .row .col-lg-7 .col-lg-6 .image {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-a);
    cursor: pointer;
}

.course-feature .container .row .col-lg-7 .col-lg-6 .image div {
    margin-right: 15px;
}

.course-feature .container .row .col-lg-7 .second-row .accordion-body {
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
}

.course-feature .container .row .col-lg-7 .second-row h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-a);
    margin-top: 20px;
    margin-bottom: 20px;
}

.course-feature .col-lg-7 .second-row h2 button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.course-feature .col-lg-7 .second-row .accordion-body .text {
    padding: 10px;
}

.course-feature .col-lg-7 .second-row .accordion-body .text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #8d8b8b;
    margin-bottom: 5px;
}

.course-feature .col-lg-7 .second-row .accordion-body .text h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-a);
    margin-bottom: 10px;
}

.course-feature .col-lg-7 .second-row .accordion-body .text .preview {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary);
    padding: 5px 10px;
    background: #feefe7;
}

.course-feature .col-lg-7 .third-row {
    padding-top: 20px;
}

.course-feature .col-lg-7 .third-row h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-a);
    margin-bottom: 20px;
}

.course-feature .col-lg-7 .third-row h2 button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-a);
}

.course-feature .col-lg-7 .third-row .accordion-item {
    margin-bottom: 0px;
}

.course-feature .col-lg-7 .third-row .accordion .accordion-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-a);
    padding-top: 0;
}

.course-feature .col-lg-7 .third-row .accordion-body a {
    color: var(--primary);
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: transparent !important;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:focus {
    z-index: 3;
    border-color: black !important;
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.course-feature .col-lg-7 .fourth-row h4 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-a);
}

.course-feature .col-lg-7 .fourth-row .bio h6 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.02em;
    color: black;
    margin-bottom: 10px;
}

.course-feature .col-lg-7 .fourth-row .bio p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: black;
    margin-bottom: 5px;
}

.course-feature .col-lg-7 .fourth-row .bio span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: black;
}

.course-feature .col-lg-7 .fourth-row .bio .star {
    margin-top: 10px;
}

.course-feature .col-lg-7 .fourth-row .item {
    border: 2px solid #d1d1d0;
    padding: 20px;
}

.course-feature .col-lg-7 .fourth-row .profile h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #494645;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
}

.course-feature .col-lg-7 .fourth-row .profile a {
    padding: 13px 30px;
    border: 1px solid #d1d1d0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--black-f);
    margin: auto;
    text-decoration: none;
}

.course-feature .col-lg-7 .fifth-row {
    padding-top: 20px;
    padding-bottom: 20px;
}

.course-feature .col-lg-7 .fifth-row h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-a);
}

.course-feature .col-lg-7 .fifth-row .rating .image {
    margin-bottom: 20px;
}

.course-feature .col-lg-7 .fifth-row .rating p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-a);
    margin-bottom: 20px;
}

.course-feature .col-lg-7 .fifth-row .feedback {
    border-bottom: 2px solid #d1d1d0;
    margin-bottom: 10px;
}

.course-feature .col-lg-7 .fifth-row .profile-info h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-a);
    margin-bottom: 5px;
}

.course-feature .col-lg-7 .fifth-row .profile-info p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--black-f);
    margin-top: 10px;
}

.course-feature .col-lg-7 .fifth-row .prev-next-button {
    margin-bottom: 80px;
}

.course-feature .col-lg-7 .fifth-row .prev-next-button button {
    padding: 7px 14px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    border: 2px solid #e8e8e8;
    background: white;
}

.active-mode {
    background: var(--primary) !important;
    border: 2px solid var(--primary) !important;
    color: white;
}

.course-feature .col-lg-7 .fifth-row .prev-next-button .btn-4:focus {
    background: #fee7db;
    color: var(--primary);
    border: 2px solid var(--primary);
}

.course-feature .container .row .col-lg-5 h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-a);
    margin-bottom: 20px;
}

.course-feature .container .row .col-lg-5 .box {
    border-bottom: 2px solid #e8e8e8;
    margin-bottom: 20px;
}

.course-feature .container .row .col-lg-5 .info h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-a);
    margin-bottom: 15px;
}

.course-feature .container .row .col-lg-5 .info .rupee p {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: var(--color-a);
    margin-top: 20px;
    margin-bottom: 0;
}

.course-feature .container .row .col-lg-5 .info .rupee p span {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #bbbab9;
    text-decoration-line: line-through;
}

.course-feature .container .row .col-lg-5 .info .rupee h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: var(--primary);
}

.course-feature .container .row .col-lg-5 .info .rupee button {
    padding: 15px 45px;     
    /*05-01-2023*/
    border: 1px solid lightgray;
}

.course-feature .container .row .col-lg-5 .info .rupee button:hover {
    color: var(--white);
    background-color: var(--primary);
}


.resourceAccord .accordion-item {
    border-bottom: 1px solid #e9e9e9 !important;
    border-radius: 0px !important;
}

.ngbAccordion_padding .owl-item .item {
    width: 220px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.mind-map_page .ngbAccordion_padding .owl-item .item {
    width: auto !important;
    padding: 0 15px;
}



.Our-Resources .top- {
    padding-top: 30px;
    padding-bottom: 40px;
}

.Our-Resources .top- h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--a-color);
}

.Our-Resources .position-relative .select-1 {
    padding: 13px 30px 13px 15px;
    width: 150px;
}

.Our-Resources .position-relative select {
    position: absolute;

    right: 12px;
    background-color: #fff8f3;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23F65F12' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    border-radius: 0px;
    border: 1px solid var(--primary);
    color: var(--primary);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
    box-shadow: none !important;
    margin-top: 20px;
}

.Our-Resources .top- p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8d8b8b;
}

.Our-Resources .item {
    border: 1px solid #b6b6b6;
    padding: 15px;
    background: transparent;
}

.Our-Resources .item:hover {
    background: #fff7f3;
    border: 1px solid var(--primary);
    color: var(--primary);
}

.Our-Resources .item .image {
    margin-right: 10px;
}

.Our-Resources .left-resource {
    margin-top: 40px;
}

.Our-Resources .left-resource h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
    color: var(--a-color);
}

.Our-Resources .left-resource p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--a-color);
}

.Our-Resources .preview-result {
    margin-top: 20px;
}

.Our-Resources .preview-result h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--a-color);
}

.Our-Resources .preview-result .table thead tr th {
    background: #feefe7;
    border-width: 1.4px 1.4px 1.4px 1.4px;
    border-style: solid;
    border-color: #e8e8e8;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.Our-Resources .preview-result .table thead tr th:nth-child(2),
.Our-Resources .preview-result .table thead tr th:nth-child(3) {
    padding: 13px 15px;
}

.Our-Resources .preview-result .table tbody tr td {
    border-width: 0px 1.4px 1.4px 1.4px;
    border-style: solid;
    border-color: #e8e8e8;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
}

.Our-Resources .preview-result .table {
    margin-top: 20px;
    margin-bottom: 30px;
}

.Our-Resources .preview-result .table tbody tr td:nth-child(2),
.Our-Resources .preview-result .table tbody tr td:nth-child(3) {
    padding: 13px 15px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fa9f71;
}

.Our-Resources .preview-result .table tbody tr:hover td {
    background: #feefe7;
}

.Our-Resources .old-paper h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--a-color);
    margin-bottom: 20px;
}

.Our-Resources .old-paper .table thead tr th {
    background: #feefe7;
    border-width: 1.4px 1.4px 1.4px 1.4px;
    border-style: solid;
    border-color: #e8e8e8;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.Our-Resources .old-paper .table thead tr th:nth-child(2),
.Our-Resources .old-paper .table thead tr th:nth-child(3) {
    padding: 13px 15px;
}

.Our-Resources .old-paper .table tbody tr td {
    border-width: 0px 1.4px 1.4px 1.4px;
    border-style: solid;
    border-color: #e8e8e8;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
}

.Our-Resources .preview-result .table {
    margin-top: 20px;
    margin-bottom: 30px;
}

.Our-Resources .old-paper .table tbody tr td:nth-child(2),
.Our-Resources .old-paper .table tbody tr td:nth-child(3) {
    padding: 13px 15px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fa9f71;
}

.Our-Resources .old-paper .table tbody tr:hover td {
    background: #feefe7;
}

.Our-Resources .content- {
    margin-bottom: 20px;
}

.Our-Resources .content- p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--a-color);
    margin-bottom: 20px;
}

.Our-Resources .content- h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--a-color);
}

.Our-Resources .content- ul {
    color: var(--primary);
}

.Our-Resources .content- ul li {
    padding: 10px 0;
}

.Our-Resources .content- ul li a {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
}

.Our-Resources .right-resourse {
    margin-top: 40px;
}

.Our-Resources .right-resourse .right-1 {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
}

.Our-Resources .right-resourse .right-1 .img {
    display: flex;
    margin-bottom: 15px;
}

.Our-Resources .right-resourse .right-1 .img div {
    margin-right: 10px;
}

.Our-Resources .right-resourse .right-1 .img h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
}

.Our-Resources .right-resourse .right-1 p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--black-f);
    margin-bottom: 10px;
}

.Our-Resources .right-resourse .right-1 .img p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--black-f);
    margin-top: 4px;
}

.Our-Resources .right-resourse .right-1 .btn-1 {
    margin-bottom: 20px;
    margin-top: 20px;
}

.Our-Resources .right-resourse .right-1 button {
    padding-top: 15px;
    padding-bottom: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid var(--primary);
    background: #fff;
    color: var(--primary);
}

.Our-Resources .right-resourse .right-1 button:hover {
    background: var(--primary);
    color: #fff;
}

.Our-Resources .right-resourse .video {
    margin-bottom: 20px;
    margin-top: 20px;
}

.Our-Resources .right-resourse .video {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.Our-Resources .right-resourse .video .lecture-info {
    padding: 20px;
}

.Our-Resources .right-resourse .video .lecture-info p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8d8b8b;
    margin-bottom: 5px;
}

.Our-Resources .right-resourse .video .lecture-info h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
    margin-bottom: 10px;
}

.Our-Resources .right-resourse .video .lecture-info span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #a4a3a2;
}

.Our-Resources .right-resourse .row .col-lg-6 .back-shadow {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.Our-Resources .right-resourse .row h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-a);
    margin-bottom: 20px;
}

.Our-Resources .right-resourse .row .col-lg-6 .material {
    padding: 10px;
}

.Our-Resources .right-resourse .row .col-lg-6 .material p {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #8d8b8b;
    margin: 0;
}

.Our-Resources .right-resourse .row .col-lg-6 .material h6 {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: var(--color-a);
    margin-top: 5px;
    margin-bottom: 8px;
}

.Our-Resources .right-resourse .row .col-lg-6 .material a {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-a);
}

.Our-Resources .right-resourse .row .col-lg-6:hover div a,
.Our-Resources .right-resourse .row .col-lg-6:hover h6 {
    color: var(--primary);
}

.Our-Resources .right-resourse .right-2 {
    padding: 20px;
    border: 1px solid var(--p-color);
    border-radius: 12px;
    background: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Our-Resources .right-resourse .right-2 h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
}

.Our-Resources .right-resourse .right-2 .para {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-a);
    margin-bottom: 15px;
}

.form-check-input:checked[type="radio"] {
    background-image: none;
    box-shadow: 0 0 0 3px #fff, 0 0 0 4.6px var(--primary);
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

.form-check-input {
    width: 12px;
    height: 12px;
}

.Our-Resources .right-resourse .right-2 .form-check {
    margin-bottom: 10px;
}

.Our-Resources .right-resourse .right-2 .form-check label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
}

.form-check-input:checked+label {
    color: var(--primary);
}

.tox .tox-tbtn {
    width: 28px !important;
    margin: 8px 0 !important;
}

.tox .tox-toolbar__group {
    padding: 0 !important;
}

.Our-Resources .right-resourse .right-3 {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.Our-Resources .right-resourse .right-3 h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--primary);
    margin-bottom: 5px;
}

.Our-Resources .right-resourse .right-3 p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--black-f);
    margin-bottom: 20px;
}

.Our-Resources .right-resourse .right-3 .btn-3 {
    padding: 15px 0;
    background: var(--primary);
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
    border: 1px solid var(--primary);
    margin-top: 20px;
}

.Our-Resources .right-resourse .right-3 .tox-tinymce {
    height: 250px !important;
}

.Our-Resources .right-resourse .right-3 .tox-statusbar {
    display: none !important;
}

.Our-Resources .right-resourse .right-1 {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 20px;
}

.Our-Resources .right-resourse .right-1 .img {
    display: flex;
    margin-bottom: 15px;
}

.Our-Resources .right-resourse .right-1 .img div {
    margin-right: 10px;
}

.Our-Resources .right-resourse .right-1 .img h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
}

.Our-Resources .right-resourse .right-1 button {
    padding-top: 15px;
    padding-bottom: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid var(--primary);
    background: #fff;
    color: var(--primary);
}

@media (min-width: 992px) {
    .card1 {
        width: auto;
    }
}

.form-select:focus {
    box-shadow: none !important;
}

.tox .tox-notification--warn,
.tox .tox-notification--warning {
    display: none !important;
}

.tox .tox-toolbar-overlord .tox-toolbar:not(.tox-toolbar--scrolling):first-child,
.tox .tox-toolbar-overlord .tox-toolbar__primary {
    background: #f4f3f3 !important;
}

.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-bottom .tox-editor-header {
    border: none !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
    padding: 0 !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-item {
    border: none !important;
    border-bottom: 1px solid #bbc2c7 !important;
    background-color: white !important;
    border-radius: 15px !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}

/* 
body {
    background-color: #ffffff;
    overflow-x: hidden;
} */

:root {
    --primary: #f65f12;
    --primary-second: #f87f41;
    --black-f: #494645;
    --color-a: #1b1816;
    --border-color: #e8e8e8;
    --poppins: "Poppins", sans-serif;
    --white: #ffffff;
    --bg-f: #ebf1dc;
    --p-color: #767473;
    --see-color: #8d8b8b;
    --inspir-bg: #f5f8ed;
    --owl-bg-color: #feefe7;
    --btn-padding: 12px 32px;
    --resourse-dark: #dce9b9;
    --resourse-font: #242e0a;
}


.lines1 {
    position: absolute;
    left: 24px;
    top: calc(65% - 13px);
    cursor: pointer;

}

.toggle-desktop {
    /* background: #EEF2F5; */
    background: rgb(255 255 255 / 95%);
    box-shadow: 1px 0px 15px rgb(0 0 0 / 15%);
    overflow: hidden;
    width: 55px;
    transition: 0.5s ease-in-out;
    height: 100%;
    z-index: 15;
    /* opacity: 0.8; */
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
}

.toggle-desktop .desktop-content {
    width: 240px;
    padding-top: 15px;
}

.toggle-desktop .desktop-content ul {
    list-style: none;
    padding: 0;
}

.toggle-desktop .desktop-content ul li {
    padding: 15px 18px;
}

.toggle-desktop .desktop-content a {
    padding: 0 !important;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    line-height: 24px;
    color: var(--color-a);
}

.toggle-desktop svg {
    margin-right: 25px;
}

.toggle-desktop a:hover path {
    fill: #f65f12;
}

.desktop-content a:hover {
    color: #f65f12;
}

.tushar {
    width: 250px;
    background: rgb(255 255 255 / 70%);
}

.my-instruction svg {
    margin-right: 24px;
}

.my-instruction a:hover path {
    fill: #f65f12;
}

.my-instruction a:hover {
    color: #f65f12;
}

.my-instruction .active {
    color: #f65f12;
}

.btn1 {
    padding: var(--btn-padding);
    border: 0;
    background-color: var(--primary);
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: var(--color-a) !important;
    margin-left: 7px;
    cursor: pointer;
    background: #ffdf6d;
    border-radius: 25px;
    background-image: linear-gradient(120deg, #ff8484 0%, #ffdf6e 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
}

button.btn1:hover {
    background-position: 0 0;
}

.active_1,
.active_subject,
.grade_active {
    /* background: var(--primary) !important; */
    background: var(--color-a) !important;
    color: var(--white) !important;
    border: 1px solid #000 !important;
}

.container .row .left h2 {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: var(--color-a);
}

.container .row .left p {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--p-color);
    padding-right: 150px;
}


.container .row .right {
    position: relative;
}

.container .row .right button,
.container .row .right span {
    position: absolute;
    right: 22px;
}

.container .row .right button::after,
.container .row .right span::after {

    border: none;
    display: none; 

    /* content: "";
    position: absolute;
    border: 2px solid var(--primary);
    width: 100%;
    height: 50px;
    left: 10px;
    top: 10px;*/
}

.container .row .owl_1 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.owl_1 .owl-carousel .item {
    padding: 12px 28px;
    background-color: var(--owl-bg-color);
    display: inline-block;
    width: auto;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: var(--color-a);
}

.header123 {
    width: 100%;
    height: auto;
    background-color: #fff;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-duration: 0.7s;
    -o-transition-duration: 0.7s;
    -webkit-transition-duration: 0.7s;
    transition-duration: 0.5s;
    -moz-transition-property: height, top;
    -o-transition-property: height, top;
    -webkit-transition-property: height, top;
    transition-property: height, top;
    -moz-box-shadow: rgba(30, 50, 50, 0.25) 0 0.05em 0.5em;
    -webkit-box-shadow: rgba(30, 50, 50, 0.25) 0 0.05em 0.5em;
    box-shadow: rgba(30, 50, 50, 0.25) 0 0.05em 0.5em;
}

/* @media (min-width: 1000px) {
    .container {
        width: 1160px !important;
    }
} */

#auto-hide-show-owl-carousel {
    width: 100%;
    height: 100px;
    position: fixed;
    left: 0;
    top: -120px;

    transition: all 1s;
    z-index: 100000;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

#auto-hide-show-owl-carousel .top-owl,
#auto-hide-show-owl-carousel .bottom-owl {
    width: calc(100% - 80px);
    height: 50px;
    margin: auto;
    position: relative;
}

#auto-hide-show-owl-carousel .bottom-owl .item {
    line-height: 34px;
    margin-top: 8px;
    background: #fff7f3;
    padding: 0 7px;
}

#auto-hide-show-owl-carousel .left-arrow {
    position: absolute;
    top: 13px;
    left: -28px;
}

#auto-hide-show-owl-carousel .right-arrow {
    position: absolute;
    top: 13px;
    right: -28px;
}

#auto-hide-show-owl-carousel .top-owl .item {
    font-weight: 600;
    font-size: 12px;
    line-height: 50px;
    color: #767473;
}

#auto-hide-show-owl-carousel .active-arrow {
    color: var(--primary);
}

.start {
    padding-top: 30px;
    padding-bottom: 0px;
}

.start .container .row .col-lg-6 h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
    margin-bottom: 10px;
}

.start .container .row .col-lg-6 p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8d8b8b;
    margin-bottom: 20px;
}

.start .container .row .col-lg-6 select {
    position: absolute;
    right: 12px;
    background-color: #FFF;
    /* background-color: #fff8f3;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23F65F12' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); */
    border-radius: 50px;
    border: 1px solid var(--black-f);
    color: var(--color-a);
    /* border: 1px solid var(--primary);
    color: var(--primary); */
    padding: 13px 30px 13px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.start .container .row .col-lg-6 .select-1 {
    padding: 13px 30px 13px 15px;
    width: 150px;
}

.start .container .row .col-lg-12 .item {
    border: 1.6px solid #d1d1d1;
    padding: 15px;
    background: transparent;
    margin: 0px 5px;
    border-radius: 11px;
    /* new add 26-12-2022 */
}

.start .container .row .col-lg-12 .item:hover {
    background: #fff7f3;
    border: 1px solid var(--primary);
    color: var(--primary);
}


.active_resources {
    background: #fff7f3 !important;
    border: 1px solid var(--primary) !important;
    color: var(--primary);
}


.start .container .row .col-lg-12 .item .image {
    width: 30px;
}

.start .resourse-owl-1 .item .resource_name {
    margin-left: 25px;
}

.resourse-1 .container .row .col-lg-8 h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: var(--color-a);
    margin-bottom: 20px;
}

.resourse-1 .container .row .col-lg-8 .subject {
    margin-bottom: 40px;
}

.resourse-1 .container .row .col-lg-8 .subject div {
    padding: 15px;
    background: #fff7f3;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-a);
}

.resourse-1 .container .row .col-lg-8 .subject div:hover {
    background: #fddfd0;
    color: var(--primary);
}

.resourse-1 .row .accordion .accordion-body .item .text span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #a4a3a2;
}

.resourse-1 .row .accordion h2 button {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    background-color: transparent !important;
}

.resourse-1 .row .accordion-body .item .text p.dot_data_video_desc,
.right-2 .icon_background .dot_data_video_desc {

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 7px;
    margin-top: 5px;

}

.resourse-1 .row .accordion-body .item .text p.box_data_desc {
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: justify !important;
    line-height: 21px !important;
    margin-top: 0 !important;
}

.resourse-1 .row .accordion-body .item :hover {
    color: var(--primary);
}

.resourse-1 .row .accordion-body .item .text h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-a);
    margin-bottom: 10px;
    text-align: justify;
}

.resourse-1 .row .accordion-body .item .text h5 a {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-a);
    margin-bottom: 10px;
    text-align: justify;
    text-decoration: none;
    cursor: pointer;
}

.resourse-1 .row .accordion-body .item .text h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--color-a);
    text-align: justify;
}

.resourse-1 .row .ngbAccordion_padding .item .img .img-fluid {
    width: 220px;
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: transparent !important;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:focus {
    z-index: 3;
    border-color: black !important;
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.login_page.completRegt {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
    position: relative;
}

.login_page.completRegt .country-code {
    font-size: 14px;
    margin-left: 5px;
    position: relative;
    margin-top: auto;
}

.login_page.completRegt input.ng-untouched.ng-pristine.ng-valid  {
    margin: 0px 0px 0px 10px !important;
    border: none;
    width: 100%;
    height: 21px;
    padding: 5px;
}

.login_page.completRegt .search-container input  {
    margin: 0px 0px !important;
}

.resourse-1 .row .col-lg-4 .right-1 {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
}

.resourse-1 .row .col-lg-4 .right-1 .img {
    display: flex;
    margin-bottom: 0px;
}

.resourse-1 .row .col-lg-4 .right-1 .img div {
    margin-right: 10px;
}

.resourse-1 .row .col-lg-4 .right-1 .img h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
}

.resourse-1 .row .col-lg-4 .right-1 p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--black-f);
    margin-bottom: 10px;
}

.resourse-1 .row .col-lg-4 .right-1 .img p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--black-f);
    margin-top: 4px;
}

.resourse-1 .row .col-lg-4 .right-1 .btn-1 {
    margin-bottom: 20px;
    margin-top: 20px;
}

.bottom-fix {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.bottom-fix .img {
    display: flex;
    margin-bottom: 17px;
    gap: 10px;
}

.bottom-fix .img h5 {
    color: var(--primary);
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin-top: 3px;
}

.bottom-fix button {
    padding-top: 15px;
    padding-bottom: 15px;
    font-style: normal;

    border: 1px solid var(--primary);
    background: var(--white) !important;
    color: var(--primary) !important;
    font-weight: 600;
    font-size: 10px !important;
    line-height: 15px;
}

.bottom-fix button:hover {
    background: var(--primary) !important;
    color: var(--white) !important;
}

.hover-button button {
    /* padding-top: 15px;
    padding-bottom: 15px;
    font-style: normal;
    border: 1px solid var(--primary);
    background: var(--white);
    color: var(--primary);
    font-weight: 700;
    font-size: 14px;
    line-height: 21px; */

    /* line-height: 22px;
    margin-top: 16px;
    font-weight: 600;
    font-size: 17px;
    background-color: #ff6a0b;
    color: #FFF;
    border: none;
    padding: 14px 20px;
    display: block;
    border-radius: 45px;
    border: 0px solid #fff;
    width: 20%;
    background-image: linear-gradient(120deg, #ffda22 0%, #ff5858 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s; */

    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    padding: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    border: none;
    /* background: #fff; */
    color: #097356;
    border-radius: 40px;
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
}


.resourse-1 .row .col-lg-4 .right-1 button:hover {
    /* background: var(--primary);
    color: #fff; */
    background-position: 0 0;
}

.resourse-1 .row .col-lg-4 .right-2 {
    padding: 20px;
    border: 1.4px solid #e8e8e8;
    border-radius: 12px;
    background: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

.resourse-1 .row .col-lg-4 .right-2 h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
}

.resourse-1 .row .col-lg-4 .right-2 .para {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-a);
    margin-bottom: 15px;
}

.form-check-input:checked[type="radio"] {
    background-image: none !important;
    box-shadow: 0 0 0 3px #fff, 0 0 0 4.6px var(--primary);
}

.form-check-input:checked {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.form-check-input {
    width: 12px;
    height: 12px;
}

.resourse-1 .row .col-lg-4 .right-2 .form-check {
    margin-bottom: 10px;
}

.resourse-1 .row .col-lg-4 .right-2 .form-check label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #8D8B8B;
    line-height: 20px;
}

.form-check-input:checked+label {
    color: var(--primary) !important;
    margin-top: 5px;
}

.resourse-1 .row .col-lg-4 .row .col-lg-6 .back-shadow {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.resourse-1 .row .col-lg-4 .row h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-a);
    margin-bottom: 20px;
}

.resourse-1 .row .col-lg-4 .row .col-lg-6 .material {
    padding: 10px;
}

.resourse-1 .row .col-lg-4 .row .col-lg-6 .material p {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #8d8b8b;
    margin: 0;
}

.resourse-1 .row .col-lg-4 .row .col-lg-6 .material h6 {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: var(--color-a);
    margin-top: 5px;
    margin-bottom: 8px;
}

.resourse-1 .row .col-lg-4 .row .col-lg-6 .material a {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-a);
}

.resourse-1 .row .col-lg-4 .row .col-lg-6:hover div a,
.resourse-1 .row .col-lg-4 .row .col-lg-6:hover h6 {
    color: var(--primary);
}

.resourse-1 .row .col-lg-4 .right-3 {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 100px;
}

.resourse-1 .row .col-lg-4 .right-3 h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--primary);
    margin-bottom: 5px;
}

.resourse-1 .row .col-lg-4 .right-3 p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--black-f);
    margin-bottom: 20px;
}

.angular-editor-textarea {
    border-radius: 7px;
    margin: 10px 0px 10px !important;
}

.resourse-1 .row .col-lg-4 .right-3 .btn-3 {
    /* padding: 15px 0;
    background: var(--primary);
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
    border: 1px solid var(--primary);
    margin-top: 20px; */

    /* padding: var(--btn-padding);
    border: 0;
    background-color: var(--primary);
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: var(--color-a) !important;
    margin-top: 16px;
    margin-left: 7px;
    cursor: pointer;
    background: #ffdf6d;
    border-radius: 25px;
    background-image: linear-gradient(120deg, #ff8484 0%, #ffdf6e 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s; */
    line-height: 22px;
    margin-top: 18px;
    font-weight: 600;
    font-size: 15px;
    /* margin-bottom: 35px; */
    background-color: #ff6a0b;
    color: #fff;
    border: none;
    padding: 12px 25px;
    display: block;
    border-radius: 45px;
    border: 0px solid #fff;
    /* box-shadow: 0px 5px 10px -5px #ff8131; */
    width: 25%;
    background-image: linear-gradient(120deg, #ffda22 0%, #ff5858 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
}

.resourse-1 .row .col-lg-4 .right-3 .btn-3:hover {
    background-position: 0 0;
}

.resourse-1 .row .col-lg-4 .right-3 .tox-tinymce {
    height: 250px !important;
}

.resourse-1 .row .col-lg-4 .right-3 .tox-statusbar {
    display: none !important;
}

.tox .tox-tbtn {
    width: 28px !important;
}

.info .book_rating {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    border: 1.5px solid #E8E8E8;
    padding: 4px;
}

.info .book_rating i {
    color: #FF9F10;
    font-size: 11px;
}

.info .enroll_number {
    color: #494645;
    margin: auto;
    padding: 0 8px;
}

.feedback .profile-info .checked {
    color: #ff9f10;
}

.feedback .profile-info span {
    font-size: 14.5px;
    padding: 0 2px;
    color: #DDDCDC;
}

.profile .bio .star span {
    font-size: 30px !important;
    padding: 0 3px;
    color: #DDDCDC !important;
}

.profile .bio .star .checked {

    color: #ff9f10 !important;
}

/* #course_data .package_box .img1 {
    height: 192px;
    max-height: 192px;
    display: flex;
    justify-content: center;
    text-align: center;
} */

#course_data .package_box .img1 {
    background-image: linear-gradient(145deg, #fdfbfb 0%, #ebedee 100%);
    height: 170px;
    max-height: 170px;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid #eceeef;
    padding: 10px 10px;
    border-radius: 12px;
}

.img.ng-star-inserted {
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    height: 170px;
    max-height: 170px;
    /* display: flex; */
    justify-content: center;
    text-align: center;
    border: 1px solid #eceeef;
    padding: 0px;
    border-radius: 12px;
    overflow: hidden;
}

/* #course_data .hi .content .coursePrice .usdPrice {
    color: #f16f18;
} */

.pagination_btn {
    /* background: #f65f12;
    color: #fff;
    padding: 8px 20px;
    border: unset; */

    padding: 5px 20px;
    background: #ffffff00;
    color: #616161;
    border: 1px solid #a5a5a5;
    font-size: 14px;
    margin: 10px auto 0px;
    float: none;
    width: 110px;
    border-radius: 30px;
    display: block;
    font-family: 'Poppins';
    font-weight: 600;
}

.view_all_que {
    font-size: 20px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #FF6A0B;
    text-decoration: none;
    width: 50%;
}

.page-item .page-link {
    margin-left: -1px;
    padding: 7px 14px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    border: 2px solid #e8e8e8;
    background: white;
    color: black;
    border-radius: unset;
}

.pagination .page-item {
    margin: 0 5px;
}

.page-item .page-link:focus {
    background-color: #f65f12;
    box-shadow: unset;
    border: 2px solid #f65f12 !important;
    color: #fff;
}

 .premium {
    position: absolute;
    z-index: 1;
    color: #fff;
    background: #f65f12;
    width: max-content;
    padding: 5px 10px;
    margin-top: 5px;
    margin-left: 10px;
    text-align: center;
    box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 18%);
    font-size: 12px;
    border-radius: 9px;
    transition-duration: 0.5s;
}

.paid_item {
    display: none;
}

.premium:hover .paid_item {
    display: contents;
}

.premium:hover {
    width: 60px;
}

/* ======================================= ======================================= ======================================= 
                                    
                                                CMS PAGES STYLE STARTS HERE 

  ======================================= ======================================= ======================================= */

/* ========= AskIITians CAREERS STYLE STARTS ========= */

/* .careerWrap {
    padding: 70px 0px;
}

.careerContentWrapper {
    padding-right: 8%;
}

.leftPanlStrong i {
    
    color: #FF6E1D;
    font-size: 14px;
    margin-right: 4px;
}

h1.pageSecHeading {
    font-family: "Poppins", sans-serif;
    font-size: 27px;
    font-weight: 700;
    margin: 20px 0px 30px;
}

.leftPanlStrong {
    font-family: "Poppins", sans-serif;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin: 12px 0px 12px;
    display: block;
    border-bottom: 1px solid hsl(21.5deg 100% 55.69% / 30%);
    padding: 0px 0px 3px;
}

.careerContentWrapper p {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--p-color);
}

ul.careerLisitngStyle {
    font-family: "Poppins", sans-serif !important;
    padding: 0px 0px 0px 36px;
    margin-bottom: 22px;
}

ul.careerLisitngStyle li {
    list-style: disc;
    line-height: 21px;
    margin-bottom: 7px;

    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: var(--p-color);
}

.careerContentWrapper a {
    font-weight: 600;
}

.Careerform {
    -webkit-border-horizontal-spacing: gradient;
    border: none;
    width: 99%;
    position: relative;
    padding: 30px !important;
    background-image: linear-gradient(120deg, #F9F9DD 0%, #EBE0F7 100%) !important;
    border-radius: 20px !important;
}

.Careerform .user-show-head {
    width: 100%;
    min-height: 40px;
    height: auto;
    background-color: none !important;
    border-bottom: none !important;
    background-image: none !important;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: #000 !important;
    box-shadow: none !important;
    background: transparent !important;
    margin: 0px 0px 14px;
    text-align: center;
}

.Careerform .label-space {
    display: inline-block;
    font-weight: 600 !important;
    margin-bottom: 5px;
    font-size: 15px;
}

.Careerform .input-text {
    box-shadow: none !important;
    border: none !important;
    border-radius: 50px !important;
    height: 42px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333;
    padding: 7px 25px !important;
    margin: 0px 0px 19px;
    width: 100%;
}

.form-row.countryCodde .input-box {
    margin: 0px;
    border: none !important;
}

.EmalFld {
    margin-top: 20px;
}

.Careerform .btn-applyCareer {
    line-height: 22px;
    margin: 7px auto 0px;
    font-weight: 600;
    font-size: 17px;
    background-color: #ff6a0b;
    color: #fff;
    border: none;
    padding: 14px 20px;
    display: block;
    border-radius: 45px;
    border: 0px solid #fff;
    background-image: linear-gradient(120deg, #ffda22 0%, #ff5858 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
    display: block;
    width: 100%;
}

.Careerform .btn-applyCareer:hover {
    background-position: 0 0;
} */

/* ========= AskIITians CAREERS ENDS ========= */



/* ========= AskIITians FAQS STYLE STARTS ========= */

/* .faqsWrap {
    padding: 70px 0px;
}

.faqsWrap h1 {
    font-family: "Poppins", sans-serif;
    font-size: 27px;
    font-weight: 700;
    margin: 20px 0px 11px;
}

.faqsListWrap {
    padding: 20px 0px 0px;
}

.faqsWrap p {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--p-color);
}

.faqsListWrap h2 {
    font-family: "Poppins", sans-serif;
    font-size: 27px;
    font-weight: 700;
    margin: 20px 0px 11px;
}

.faqsAccordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    font-size: 15px;
    transition: background-color 0.3s ease;
}

.faqactive {
    background-color: #ccc;
}

.faqpanel {
    padding: 0 18px;
    display: block;
    max-height: 0;
    overflow: hidden;
    background-color: #f1f1f1;
    transition: max-height 0.5s ease-out, padding 0.3s ease;
}

.faqopen {
    max-height: 500px;
    padding: 10px 18px;
} */

/* ========= AskIITians FAQs STYLE ENDS ========= */


/* ========= AskIITians FRANCHISEE STYLE STARTS ========= */

/* .AIFranchiseeWrap {
   padding: 70px 0px;
}

.AIFranchiseeWrap h1 {
    font-family: "Poppins", sans-serif;
    font-size: 27px;
    margin: 5px 0px 30px;
}

.AIFranchiseeWrap h2 {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    margin: 20px 0px 12px;
}

#content pre, #content-full pre {
    white-space: normal;
    white-space: -moz-normal;
    white-space: -normal;
    white-space: -o-normal;
    word-wrap: break-word;
    margin: 7px 0;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-family: 'Open Sans', sans-serif !important;
    color: #4c4c4c;
    font-weight: normal;
    text-align: justify;
}

pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}

#content pre h1, #content-full pre h1 {
    font-size: 24px;
    color: #4C4C4C;
    line-height: 30px;
    font-weight: normal;
    margin: 3px 0 10px 0;
    padding: 0 0 7px 0;
    border-bottom: #DADADA 1px solid;
    position: relative;
    text-align: left !important;
}

#content pre h2, #content-full pre h2 {
    font-size: 20px;
    color: #4C4C4C;
    line-height: 24px;
    font-weight: normal;
    margin: 15px 0 5px 0;
    padding: 0;
}

#content pre p, #content-full pre p {
    font-size: 14px;
    color: #4C4C4C;
    line-height: 22px;
    margin: 5px 0 8px 0;
    font-style: normal;
    text-align: justify;
}

.franchisee-txt {
    background: #fdf3e9;
    margin: 20px 0;
    padding: 8px 20px;
    float: left;
    border-radius: 20px;
}

.franchisee-txt .head {
    color: #2d2d2d;
    font-size: 20px;
    margin: 10px 0 10px 0px;
    font-weight: 600;
    border-bottom: 1px solid #ffd7af;
    padding: 0px 0px 7px 0px;
}

.box-dflt .head {
    font-size: 26px;
    color: #EB7F00;
    line-height: 38px;
    margin: 3px 0 2px 3px;
    padding: 0;
    font-style: normal;
    font-weight: 700;
}

.franchisee-txt .head .ico {
    background: url(/Resources/images/icons.png) no-repeat 0 -118px;
    width: 26px;
    height: 52px;
    position: absolute;
    margin: -5px 0 0 -35px;
}
.AIFranchiseeWrap p {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--p-color);
}

.AIFranchiseeWrap p strong {
    color: #000;
}

.franchisee-txt ul {
    padding: 0px 20px 20px;
    margin: 0;
    float: left;
}

.franchisee-txt ul li {
    width: 50%;
    float: left;
    padding: 10px 10px 10px 0px;
}

.franchisesCotent ul {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--p-color);
    padding: 10px 20px 0px;
}

.franchisee-txt ul li a {
    font-size: 15px;
    color: #EB7F00;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
}

.contact-frm .head {
    color: #000;
    font-size: 22px;
    font-weight: 600;
    margin: 0px;
    border-bottom: #dadada 1px solid;
    position: relative;
}

.contact-frm {
    display: block;
    float: left;
    background: #eee5f2;
    padding: 30px;
    margin: 0px 0px 30px 0px;
    border-radius: 20px;
    background-image: linear-gradient(120deg, #F9F9DD 0%, #EBE0F7 100%) !important;
}

.contact-frm .head:before {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    bottom: -1px;
    right: 2px;
    border-bottom: 1px solid #424242;
    width: 40%;
}

.contact-frm .subhead {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    margin: 23px 14px 20px;
}

.contact-frm .row {
    float: left;
    width: 100%;
    margin: 0 0 20px 0;
}

.contact-frm .row .frmfield {
    float: left;
    width: 46%;
    margin-right: 4%;
}

.contact-frm .row .frmfield input, .contact-frm .row .frmfield select {
    background: #faf8f9;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    padding: 10px;
    width: 100%;
    display: block;
    font-size: 14px;
}

.contact-frm .row .frmfield textarea {
    margin: 0 5% 0 0;
    background: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    width: 100%;
    display: block;
    height: 100px;
    font-size: 15px;
}

.contact-frm .row .frmfield input, .contact-frm .row .frmfield select {
    background: #FFF;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    width: 100%;
    display: block;
    font-size: 14px;
}

.contact-frm .submit-btn {
    line-height: 22px;
    margin: 19px auto 0px;
    font-weight: 600;
    font-size: 17px;
    background-color: #ff6a0b;
    color: #fff;
    border: none;
    padding: 14px 20px;
    display: block;
    border-radius: 45px;
    border: 0px solid #fff;
    width: 40%;
    background-image: linear-gradient(120deg, #ffda22 0%, #ff5858 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
    display: block;
}

.contact-frm .submit-btn:hover{
    background-position: 0 0;
}

.medical-franchisee {
    width: 98%;
    float: left;
    text-align: left;
    padding: 10px 20px;
    font-size: 14px;
    background: #ebe8e3;
    height: 41px;
    margin: 0px 40px 20px 9px;
} */


/* SIDE BAR */

/* #ask-ques {
    margin-left: 0;
    margin-top: 0;
}

.box-dflt {
    width: auto;
    height: auto;
}

.mrgin-top2 {
    margin: 25px 0px 40px;
}

.grey-box {
    background: #F7F7F7;
    padding: 12px;
    margin: 20px 0 0 0;
    border-radius: 20px;
    background-image: linear-gradient(120deg, #F9F9DD 0%, #EBE0F7 100%) !important;
}

.witecont {
    width: 100%;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 10px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}

.witecont a {
    font-size: 18px;
    color: #EB7F00;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
}

.witecont img {
    width: 48px;
    height: 48px;
    background: #EFFFFD;
    border: 1px solid #B5CDD7;
    padding: 5px;
    margin: 7px 2% 7px 1%;
    display: block;
    float: left;
}

.box-dflt p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #666;
    line-height: 22px;
    margin: 7px 0 0 0;
    padding: 8px 0px 0px;
    text-align: left;
} */

/* ========= AskIITians FRANCHISEE STYLE ENDS ========= */



/* ========= AskIITians CONTACT STYLE START ========= */

/* .contact-Us-block {
    padding: 70px 0px;
}

.contact-Us-block h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
    margin: 0px 0px 2px;
}

.addesss h4 {
    font-family: var(--poppins);
    margin: 0px 0px 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 21px;
}

.contact-Us-block p, .addesss p {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--p-color);
}

.addesss a {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--p-color);
}

.addesss a:hover {
    text-decoration: underline;
    color: #333;
}

.addesss strong {
    color: #f47408;
}

.contact-Us-block strong {
    font-weight: 600;
}

.addesss {
    border-top: 1px solid #ebebeb;
    margin-top: 25px;
    padding-top: 25px;
}

.ContactFormfields {
    font-family: var(--poppins) !important;
    -webkit-border-horizontal-spacing: gradient;
    border: none;
    width: 99%;
    position: relative;
    padding: 30px !important;
    background-image: linear-gradient(120deg, #F9F9DD 0%, #EBE0F7 100%) !important;
    border-radius: 20px !important;
}

.ContactFormfields .txtbox, .ContactFormfields select {
    box-shadow: none !important;
    border: none !important;
    border-radius: 50px !important;
    height: 42px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333;
    padding: 7px 25px !important;
    margin: 0px 0px 19px;
    width: 100%;
}

.ContactFormfields .input-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 2px!important;
    border-radius: 5rem;
    padding: .375rem 1.5rem;
    border: none !important;
}

.bottomFields {
    display: flex;
    justify-content: space-between;
}

.bottomFields select {
    width: 47%;
}

.ContactFormfields .country-list-container {
    border: none !important;
    border-radius: 9px!important;
    position: absolute;
    z-index: 999;
    width: 90%;
    background: white;
    max-height: 200px;
    overflow: hidden;
}

.ContactFormfields .country-list-container .list {
    overflow-y: scroll;
    overflow-x: hidden;
}

.PhnNumberBlock {
    margin: 0px 0px 19px;
}

.ContactFormfields .country-list li {
    padding: 5px !important;
    font-size: 14px!important;
    margin: 0px 0px 6px!important;
    padding: 3px 10px!important;
}

.contactdflt-btn {
    line-height: 22px;
    margin: 7px auto 0px;
    font-weight: 600;
    font-size: 17px;
    background-color: #ff6a0b;
    color: #fff;
    border: none;
    padding: 14px 20px;
    display: block;
    border-radius: 45px;
    border: 0px solid #fff;
    width: 100%;
    background-image: linear-gradient(120deg, #ffda22 0%, #ff5858 100%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: background-position .5s;
    display: block;
}

.contactdflt-btn:hover {
    background-position: 0 0;
} */

/* ========= AskIITians CONTACT STYLE ENDS ========= */


/* ========= AskIITians ICAT STYLE START ========= */
/* .main_contianer {
    padding-top: 0px;
}

.top_banner {
    background: #000 url(/resources/images/icat/detial_bg.jpg) top center no-repeat;
    background-size: contain;
    height: 540px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
}

.top_banner:before {
    content: "";
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    left: 0;
}

.top_banner>.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 70px 15px 20px 15px;
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999 !important;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}
.top_banner h1 {
    font-size: 40px;
    font-weight: 800;
    color: #fff;
}
[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
    transition-timing-function: cubic-bezier(.175,.885,.32,1.275);
}
[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
    transition-duration: 1s;
}
.top_banner .play_icon {
    margin: 60px 0 70px 0;
    display: block;
}
[data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0);
}
[data-aos^=flip][data-aos^=flip] {
    backface-visibility: hidden;
    transition-property: transform;
}
[data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg);
}
.top_banner h2 {
    font-size: 30px;
    color: #fff;
}
[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: translateZ(0);
}
[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
    transition-duration: .5s;
}
.top_banner .ban_btn a.active {
    background: #f47408;
    color: #fff;
    border: 0;
}
.top_banner .ban_btn a {
    border: 1px solid #fff;
    padding: 10px 20px;
    border-radius: 100px;
    width: 300px;
    display: inline-block;
    font-size: 20px;
    margin: 0 10px;
    font-weight: 600;
    color: #fff;
}
.top_banner .ban_btn a {
    border: 1px solid #fff;
    padding: 10px 20px;
    border-radius: 100px;
    width: 300px;
    display: inline-block;
    font-size: 20px;
    margin: 0 10px;
    font-weight: 600;
    color: #fff;
}

.top_banner .ban_btn a:hover.active {
    background: #ff841c;
}
.top_banner .ban_btn a.active {
    background: #f47408;
    color: #fff;
    border: 0;
}
.top_banner .ban_btn a:hover {
    background: rgba(255, 255, 255, 0.1);
}

.icat_sec {
    padding: 10px 0 50px 0;
}

.bn_btom_heading {
    padding: 50px 0;
}

.bn_btom_heading h2 {
    font-size: 24px;
    text-align: center;
    line-height: 35px;
}

.bn_btom_heading h2 span {
    font-weight: 600;
}
.icat_main_heading h2 {
    font-size: 38px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
}

.icat_three3:before {
    background-position: -260px 0;
}
.icat_three_box:before {
    content: "";
    background: url(/resources/images/icat/icant_sprite.png) top left no-repeat;
    width: 124px;
    height: 124px;
    display: block;
    margin: auto;
}

.icat_three_box h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0 20px 0;
}

.icat_three_box:before {
    content: "";
    background: url(/resources/images/icat/icant_sprite.png) top left no-repeat;
    width: 124px;
    height: 124px;
    display: block;
    margin: auto;
}

.icat_three_box a {
    border: 1px solid #222;
    padding: 8px 30px;
    border-radius: 100px;
    display: inline-block;
    font-size: 18px;
    margin: 0 10px;
    font-weight: 600;
    color: #222;
    margin-top: 30px;
}

.icat_details {
    background: #c4c4c4;
    padding: 80px 0;
}

.icat_details {
    background: #eceeef url(/resources/images/icat/icat_banner_bg.png) top center no-repeat;
    background-size: cover;
    position: relative;
    text-align: left;
}

.icat_details:before {
    content: "";
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    left: 0;
}

.details_box {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.details_box img {
    width: 100%;
}

.detail_rn_btn {
    text-align: left;
}

.detail_rn_btn>a {
    padding: 10px 50px;
    border-radius: 100px;
    display: inline-block;
    font-size: 20px;
    margin: 0 10px;
    font-weight: 600;
    color: #fff;
    background: #f47408;
    margin-top: 30px;
    font-weight: 600;
    text-align: center;
}

.detail_rn_btn>a:hover {
    background: #ff841c;
}

.detail_box ul {
    padding-left: 25px;
}

.detail_box ul li {
    font-size: 22px;
    line-height: 35px;
    list-style: disc;
    text-align: left;
    color: #fff;
} */

/* TOP */

/* .We_are_at {
    padding: 80px 0 40px 0;
}

.topper_section_box {
    padding: 50px 0 80px 0;
}

.topper_section_profile li {
    float: left;
    width: 100%;
}

.topper_section_profile span {
    width: 86px;
    height: 86px;
    border: 5px solid #eeeef6;
    border-radius: 100px;
    margin: 10px auto 15px auto;
    overflow: hidden;
    display: block;
    float: left;
}

.topper_section_profile span img {
    display: block;
    width: 100%;
}

.topper_section_profile .sp_text {
    float: left;
    margin-top: 24px;
    margin-left: 10px;
}

.topper_section_profile .sp_text h3 {
    font-size: 22px;
    margin-bottom: 5px;
}

.topper_section_profile .sp_text h4 {
    font-size: 16px;
}

.stats_box {
    padding: 110px 0;
    background: #d6dae2;
    background-size: cover;
}

.stats_profile ul {
    text-align: center;
}

.stats_profile ul li {
    display: inline-table;
    display: inline-table;
    min-height: 230px;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    background: #edeef0;
}

.stats_profile ul li:nth-child(1):before {
    background-position: 0 -159px;
}
.stats_profile ul li:before {
    content: "";
    background: url(/resources/images/icat/icant_sprite.png) top left no-repeat;
    width: 185px;
    height: 135px;
    display: block;
    margin: auto;
}

.stats_profile ul li h2 {
    font-size: 20px;
    padding: 20px;
    font-weight: 600;
}

.stats_profile ul li span {
    display: block;
}

.stats_profile ul li:nth-child(2):before {
    background-position: -202px -159px;
}

.stats_profile ul li:nth-child(3):before {
    background-position: -397px -159px;
}

.stats_profile ul li:nth-child(4):before {
    background-position: -584px -159px;
}

.stats_profile ul li h2 {
    font-size: 20px;
    padding: 20px;
    font-weight: 600;
}

.stats_profile ul li {
    display: inline-table;
    display: inline-table;
    min-height: 230px;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    background: #edeef0;
}

.stats_profile ul li:nth-child(5):before {
    background-position: -777px -159px;
}

.for_school {
    padding: 80px 0;
    text-align: center;
}

.for_school .icat_main_heading h2 {
    margin-bottom: 40px;
}
.icat_main_heading h2 {
    font-size: 38px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
}

.details_box img {
    width: 100%;
}

.for_school .icat_main_heading h2 {
    margin-bottom: 40px;
}
.left_heading h2 {
    text-align: left;
    font-size: 28px;
    margin: 20px 0 30px 0 !important;
}
.icat_main_heading h2 {
    font-size: 38px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
}

.download_btn {
    margin: 20px 0;
    text-align: left;
}

.download_btn a {
    border: 1px solid #f47408;
    padding: 10px 20px;
    border-radius: 100px;
    display: inline-block;
    font-size: 18px;
    margin-right: 10px;
    font-weight: 600;
    color: #f47408;
    margin-bottom: 10px;
}

.detail_school ul {
    margin: 0;
    padding: 0;
}
.detail_box ul {
    padding-left: 25px;
}

.detail_school ul li {
    font-size: 22px;
    line-height: 35px;
    list-style: disc;
    text-align: left;
    color: #222;
}

.detail_rn_btn {
    text-align: left;
}

.for_school .detail_rn_btn>a {
    margin-top: 50px;
}

.detail_rn_btn>a {
    padding: 10px 50px;
    border-radius: 100px;
    display: inline-block;
    font-size: 20px;
    margin: 0 10px;
    font-weight: 600;
    color: #fff;
    background: #f47408;
    margin-top: 30px;
    font-weight: 600;
    text-align: center;
} */

/* ========= AskIITians ICAT STYLE ENDS ========= */


/* ========= AskIITians REFUND POLICY STYLE STARTS ========= */
    /* .refund-policy-wrap {
        padding: 80px 0px;
    } */
/* ========= AskIITians REFUND POLICY STYLE ENDS ========= */

/* ========= AskIITians SCHOOL TIE UP PROGRAM STYLE STARTS ========= */
#content-full {
    width: 80%;
    margin: auto;
}

.main-form {
    border: #b7b7b7 1px solid;
    width: 99%;
    background: #fff;
    position: relative;
    padding: 0;
    overflow: hidden;
}
.main-form .header {
    width: 100%;
    min-height: 40px;
    height: auto;
    background-color: #F3F3F3;
    border-bottom: 1px solid #fff;
    background-image: -ms-linear-gradient(top, #FBFBFB 0%, #F3F3F3 100%);
    background-image: -moz-linear-gradient(top, #FBFBFB 0%, #F3F3F3 100%);
    background-image: -o-linear-gradient(top, #FBFBFB 0%, #F3F3F3 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FBFBFB), color-stop(1, #F3F3F3));
    background-image: -webkit-linear-gradient(top, #FBFBFB 0%, #F3F3F3 100%);
    background-image: linear-gradient(to bottom, #FBFBFB 0%, #F3F3F3 100%);
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: #4C4C4C;
    -webkit-box-shadow: inset 0 1px 1px #fff;
    -moz-box-shadow: inset 0 1px 1px #fff;
    box-shadow: inset 0 1px 1px #fff;
    line-height: 38px;
    padding: 0 2%;
    -webkit-box-shadow: 0 2px 1px #E6E6E6;
    -moz-box-shadow: 0 2px 1px #E6E6E6;
    box-shadow: 0 2px 1px #E6E6E6;
}
.main-form .wite-box {
    width: 100%;
    float: left;
    padding-right: 25px;
}
.main-form .wite-box {
    padding: 10px 25px;
}
.defaultForm .form-box {
    overflow: hidden;
    margin: 10px 0;
}

.defaultForm .form-box span {
    float: left;
    width: 20%;
    margin-right: 10px;
    text-align: left;
}

.wite-box.defaultForm .txtbox, .wite-box.defaultForm .txtarea {
    float: left;
    width: 75%;
}

.main-form .wite-box .defaultForm .txtarea {
    float: left;
    width: 75%;
}
.main-form .wite-box .txtbox {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -o-border-radius: 3px;
    /* width: 100%; */
    height: 38px;
    color: #5f6060;
    font-size: 14px;
    outline: none;
    padding: 5px;
    margin: 0 0 10px 0;
    border: #b7b7b7 solid 1px;
}

.main-form .wite-box .txtarea {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -o-border-radius: 3px;
    color: #5f6060;
    font-size: 14px;
    outline: none;
    padding: 5px;
    margin: 0 0 10px 0;
    border: #b7b7b7 solid 1px;
    height: 60px;
}

.txtbox {
    border: 1px solid #d8d8d8;
    color: #8e8e8e;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    height: 37px;
    margin: 20px 0 15px;
    outline: medium none;
    padding: 6px 10px 9px 40px;
    width: 100%;
}

.main-form .wite-box select {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -o-border-radius: 3px;
    width: 100%;
    height: 38px;
    color: #5f6060;
    font-size: 14px;
    outline: none;
    padding: 5px;
    margin: 0 0 10px 0;
    border: #b7b7b7 solid 1px;
}

#content pre, #content-full pre {
    white-space: normal;
    white-space: -moz-normal;
    white-space: -normal;
    white-space: -o-normal;
    word-wrap: break-word;
    margin: 7px 0;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-family: 'Open Sans', sans-serif !important;
    color: #4c4c4c;
    font-weight: normal;
    text-align: justify;
}

.wite-box.defaultForm .submitbtn #imgbtnsubmit {
    background: #ed8103;
    color: #fff;
    cursor: pointer;
    display: block;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    height: 40px;
    line-height: 35px;
    margin-bottom: 10px;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    width: 40%;
    border: 0;
    border-radius: 5px;
    -o-border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    box-shadow: none;
    border-bottom: 3px solid #dd7603;
    display: table;
    margin: auto;
}
.wite-box.defaultForm .submitbtn #imgbtnsubmit:hover {
    background: #f5901f;
    transition: all 0.4s ease-in-out 0s;
}

#content pre h2, #content-full pre h2 {
    font-size: 20px;
    color: #4C4C4C;
    line-height: 24px;
    font-weight: normal;
    margin: 15px 0 5px 0;
    padding: 0;
}

#content pre p, #content-full pre p {
    font-size: 14px;
    color: #4C4C4C;
    line-height: 22px;
    margin: 5px 0 8px 0;
    font-style: normal;
    text-align: justify;
}

/* ========= AskIITians SCHOOL TIE UP PROGRAM STYLE ENDS ========= */


@media screen and (max-width: 1399px) {
    header nav .header-ul li {
        padding: 0 10.5px !important;
    }
}

@media screen and (max-width: 450px) {
    header nav .menu a img {
        width: 104px;
    }

    .my-instruction {
        left: -100% !important;
        width: 100% !important;
    }

    .my-instruction-right {
        left: 0% !important;
    }
}

@media (max-width: 767px) {
    .chips-seation .get_answer, .view_all_que {
        width: 100% !important;
    }
    .lines1 {
        display: none;
    }

    .toggle-desktop {
        display: none;
    }

    .self-main .container .row .info .rating_box {
        display: block;
    }

    .self-main .container .row .info .total_enrolled {
        margin: 10px 0 0;
    }

    .faq_desc {
        font-size: 12px;
        line-height: 18px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .lines1 {
        left: 10px !important;
    }

    .toggle-desktop {
        left: -100px;
    }

    .tushar1 {
        left: 0px;
    }

    header nav .form-control {
        display: none !important;
    }

    header nav .img-f {
        display: none !important;
    }

    header nav .menu a img {
        width: 104px;
    }

    header nav .header-ul li a {
        font-size: 14px;
    }

    header nav .btn1 {
        padding: 10px 10px;
        font-size: 14px;
    }

    header nav .header-ul li {
        padding: 0 7.5px !important;
    }

    header .search {
        width: 38px;
        height: 38px;
        border: 1.2px solid #d1d1d0;
        border-radius: 50%;
        margin-right: 0;
        display: block !important;
        position: relative;
    }

    header .search img {
        position: absolute;
        top: 6px;
        left: 6px;
    }

    .feature .container .row .top p {
        padding: 0;
    }

    header nav .menu a {
        margin-right: 0;
    }

    .section-1 .left p {
        padding: 0 !important;
        font-size: 14px !important;
        line-height: 22px !important;
        padding: 0;
    }

    .section-1 .left h1 {
        font-size: 30px;
        line-height: 40px;
        padding: 0;
    }

    .section-1 .left {
        padding: 45px 19px 0 !important;
    }

    .section-1 .left .btn1 {
        padding: 9px 32px;
    }

    .section-1 .btn1::after {
        height: 44px;
    }

    .section-3 .container .row .first {
        padding: 20px 16px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    .section-3 .first p {
        display: none;
    }

    .section-3 .first h2 {
        font-size: 22px !important;
        line-height: 27px !important;
        margin: 0;
    }

    .section-3 .first .text-lg-center {
        text-align: end !important;
        width: auto !important;
        position: static !important;
    }

    .section-3 .container .row .second .row .col-lg-4 .box p {
        font-size: 18px;
    }

    .section-4 .container .row .right-1 button {
        margin-top: 20px;
    }

    .section-4 .container .row .right-1 button::after {
        content: "";
        position: absolute;
        border: 2px solid var(--primary);
        width: 100%;
        height: 51px;
        left: 10px;
        top: 10px;
    }

    .section-10 .accordion {
        padding: 0 52px;
    }

    .section-5 .container .row .right button {
        margin-top: 20px;
        right: calc(50% - 107px);
    }

    .section-5 .section-5-top {
        margin-top: 70px;
    }

    .section-5 .container .row .row .col-lg-3 img {
        width: 70px;
    }

    .section-9 .cards-1 .card p {
        padding: 0;
    }

    .section-6 .container .content p {
        margin-bottom: 20px;
        padding: 0 100px;
    }

    #course_data .package_box .img1 {
        height: 116.75px;
        max-height: 192px;
    }

    .coursebutton,
    .main .container #course_data .hi .packageDetail_gradeID_data .content .button .show {
        padding: 9px 5px !important;
        font-size: 13px;
    }
}

@media screen and (max-width: 767px) {

    .section-2 .container .row .owl_2 .owl-carousel .item {
            min-height: auto;
    }

    .section-6 .container .item h6 {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }

    .section-6 .container .item {
        height: auto;
    }

    .section-6 .container .item p {
        margin: 3px 0px 3px !important;
        font-size: 14px;
    }

    .section-6 .container .item span {
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0;
    }

    .demo {
        padding-top: 22px !important;
        padding-bottom: 30px;
    }

    .demo .container .row .top h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }

    .container .row .owl_1 {
        padding-top: 22px;
        padding-bottom: 22px;
    }

    .contact .container a {
        font-size: 10px !important;
    }

    .contact .container a img {
        width: 15px;
        height: 15px;
        margin-right: 6.5px;
    }

    .my-instruction {
        position: fixed;
        top: 100px;
        left: -60%;
        background-color: var(--white);
        width: 60%;
        height: 100vh;
        align-items: flex-start;
        transition: 0.5s ease-in-out;
        flex-direction: column;
    }

    .my-instruction .login-area {
        display: block !important;
        background-color: lightgray;
        width: 100%;
        padding: 20px 10px;
    }

    .my-instruction .login-area .img-area {
        width: 50px;
        height: 50px;
        background-color: lightcyan;
        border: 1px solid darkgray;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-a);
        margin-right: 20px;
        font-size: 20px;
    }

    .my-instruction .login-area .login-text-area p {
        font-weight: 700;
        font-size: 18px;
        color: var(--color-a);
    }

    .my-instruction .login-area .login-text-area span {
        font-weight: 400;
        font-size: 14px;
        color: var(--color-a);
    }

    .click-items {
        display: flex;
        justify-content: space-between;
        height: 50px;
        width: 100%;
    }

    .menu-home,
    .Courses-home {
        background-color: rgba(245, 222, 179, 0.353);
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
        padding: 20px;
        color: #000;
    }

    .click-items-2 {
        width: 100%;
    }

    .Courses-home-2 {
        display: none;
    }

    .Courses-home-2 ul,
    .menu-home-2 ul {
        list-style: none;
        padding: 0;
    }

    .active-item {
        display: block;
    }

    .active-item-menu-show {
        display: none;
    }

    .active-item-menu-hide {
        display: block;
    }

    .active-color {
        background-color: var(--primary);
        color: var(--white);
    }

    .Courses-home-2 ul li,
    .menu-home-2 ul li {
        margin: 10px 0;
        padding: 15px 10.5px !important;
        border-bottom: 1px solid silver;
        width: 100%;
    }

    .Courses-home-2 ul li a,
    .menu-home-2 ul li a {
        padding: 0 !important;
        font-family: var(--poppins);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-decoration: none;
        line-height: 24px;
        color: var(--color-a);
        margin: 0px 10px;
    }

    .Courses-home-2 ul li a img {
        margin: 1px 10px 1px 0px;
    }

    .container-fluid.topmenu {
        padding-left: 0px;
    }

    .my-instruction-right {
        left: 0%;
    }

    .lines {
        display: block;
    }

    header nav .header-ul {
        display: none !important;
    }

    header nav .header-ul li {
        margin: 10px 0;
        padding: 5px 10.5px !important;
    }

    header nav .header-ul li a {
        color: white;
    }

    /* header nav .menu a img {
        width: 104px;
    } */

    header .navbar .navbar-brand img {
        margin-right: 0;
        width: 106px !important;
        height: 30px;
    }

    header .btn1 {
        padding: 6px 16px;
        font-size: 12px;
        margin: 0;
    }

    header nav {
        padding: 8px 0 !important;
    }

    header .search {
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.2px solid #d1d1d0;
        border-radius: 50%;
        margin-right: 16px;
    }

    /* .section-1 {
        background: url("/assets/images/banner_1.png");
        background-size: cover;
    } */
    .section-1 .left {
        padding: 0px 0px !important;
    }

    .section-1 .left h1 {
        color: var(--white);
        font-size: 26px;
        line-height: 36px;
        padding: 0 15px;
    }

    .section-1 .left p {
        padding: 0 !important;
        font-size: 14px !important;
        line-height: 21px;
    }

    .section-1 .right {
        display: none;
    }

    .section-1 .shape {
        display: none;
    }

    .section-1 .left .btn1 {
        padding: 9px 32px;
    }

    .section-1 .btn1::after {
        height: 37px;
    }

    .section-2 .left h2 {
        font-size: 18px !important;
        line-height: 27px;
    }

    .section-2 .left p {
        padding: 0 !important;
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .section-2 .owl_1 .item {
        padding: 10px 26px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }

    /* .section-2 .owl_2 .owl-carousel .item {
        padding: 5px 10px !important;
        width: 140px !important;
        height: 130px !important;
    } */

    .section-2 .owl_2 .owl-carousel .item .box img {
        width: 40px !important;
        height: 40px !important;
    }

    .section-2 .owl_2 .owl-carousel .item .box p {
        font-size: 11px !important;
        line-height: 17px !important;
        font-weight: 500 !important;
        margin: 10px 0 0px 0 !important;
        height: auto !important;
    }

    .section-2 .owl_2 .owl-carousel .item .box a {
        font-weight: 400;
        font-size: 10px !important;
        line-height: 15px;
    }

    .section-2 .container .row .right-1 .btn1 {
        padding: 9px 32px;
        font-size: 14px;
        line-height: 26px;
    }

    /* .section-2 .container .row .right-1 button::after {
        content: "";
        position: absolute;
        border: 2px solid var(--primary);
        width: 100%;
        height: 39px;
        left: 5px;
        top: 10px;
    } */

    .section-3 {
        padding-top: 30px;
    }

    .section-3 .container .row .my-section-3 {
        padding: 0 !important;
    }

    .section-3 .container .row .first {
        padding: 20px 16px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .section-3 .first p {
        display: none;
    }

    .section-3 .first h2 {
        font-size: 18px !important;
        line-height: 27px !important;
        margin: 0;
    }

    .section-3 .first .text-lg-center {
        text-align: end !important;
        width: auto !important;
        position: static !important;
    }

    .section-3 .container .row .second {
        padding: 20px;
    }

    .section-3 .container .row .second .row .col-lg-4 {
        width: 205px !important;
    }

    .section-3 .container .row .second .row .col-lg-4 .box .img {
        width: 70px;
        height: 45px;
    }

    .section-3 .container .row .second .row .col-lg-4 .box {
        width: auto !important;
        margin-bottom: 16px;
        height: 60px !important;
    }

    .section-3 .container .row .second .row .col-lg-4 .box p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
    }

    .section-3 .container .row .second .row .col-lg-4 .box:hover p {
        font-size: 12px !important;
    }

    .section-4 {
        padding: 40px 0;
    }

    .section-4 .container .row .left {
        margin-bottom: 16px;
    }

    .section-4 .container .row .left h2 {
        font-size: 18px;
        line-height: 27px;
    }

    .section-4 .container .row .left p {
        padding: 0;
        font-size: 12px;
        line-height: 18px;
    }

    .section-4 .container .row .right-1 .btn1 {
        padding: 9px 50px;
        font-size: 14px;
        line-height: 26px;
        margin-top: 10px;
    }

    .section-4 .container .row .right-1 button::after {
        content: "";
        position: absolute;
        border: 2px solid var(--primary);
        width: 100%;
        height: 40px;
        left: 5px;
        top: 10px;
    }

    .section-5 {
        padding: 32px 0 40px !important;
    }

    .section-5 .container .row .left h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }

    .section-5 .container .row .left h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }

    .section-5 .container .row .left p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        padding: 0;
        margin-bottom: 16px;
    }

    .section-5 .container .row .owl_4 h2 {
        font-size: 14px !important;
        line-height: 21px;
        font-size: 30px;
        margin-top: 0 !important;
    }

    .section-5 .container .row .owl_4 h2 span {
        font-size: 24px;
        line-height: 21px;
        font-size: 15px;
    }

    .section-5 .col-lg-12 {
        margin-top: 0 !important;
    }

    .section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item {
        padding: 10px 5px;
    }

    .section-5 .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .text {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .section-5 .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .text {
        font-size: 11px !important;
        line-height: 18px !important;
    }

    .section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .rank {
        padding: 8px 6px;
        font-size: 12px;
        line-height: 21px;
    }

    .section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item img {
        width: 50px;
        height: 50px;
    }

    .section-5 .container .row .row .col-lg-3 p span {
        font-size: 18px;
        line-height: 27px;
    }

    .section-5 .container .row .row .col-lg-3 p {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }

    .section-5 .container .row .row .col-lg-3 span {
        font-size: 14px;
        line-height: 21px;
    }

    .section-5 .container .row .row .col-lg-3 img {
        width: 58px;
        height: 58px;
    }

    .section-5 .container .row .right button {
        margin-top: 10px;
        right: calc(50% - 100px);
        top: 0;
        padding: 9px 32px;
        font-size: 14px;
        line-height: 26px;
    }

    .section-5 .container .row .right button:after {
        left: 5px;
        height: 40px
    }

    .section-5 .section-5-top {
        margin-top: 70px;
    }

    .section-6 .container .content h3 {
        font-size: 18px;
        line-height: 27px;
        text-align: justify;
    }

    .section-6 .container .content p {
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
        margin-bottom: 16px;
        padding: 0;
    }

    .section-6 .container .row .right button {
        margin-top: 10px;
        right: calc(50% - 100px);
        top: 0;
        padding: 9px 32px;
        font-size: 14px;
        line-height: 26px;
    }

    .section-6 .container .row .right button:after {
        left: 5px;
        height: 40px
    }

    .section-7 {
        padding-top: 32px;
        padding-bottom: 80px;
    }

    .section-7 h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }

    .section-7 h2+p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px;
    }

    .section-7 .change-position {
        position: absolute;
        bottom: -50px;
        left: calc(50% - 14.5px);
    }

    .section-7 .owl-testimonials .right p {
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
    }

    .section-7 h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
    }

    .section-7 h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .section-7 .quote {
        width: 12.89px !important;
        height: 26.43px;
    }

    .section-8 .container .row .left-1 {
        padding: 32px 16px;
    }

    .section-8 .left-1 span {
        font-size: 16px;
        line-height: 24px;
    }

    .section-8 .left-1 h5 {
        font-size: 20px;
        line-height: 30px;
    }

    .section-8 .left-1 .p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .section-8 .left-1 .app-logo img {
        width: 144px;
    }

    .section-9 .cards-1 .card {
        padding: 10px !important;
    }

    .section-9 .cards-1 .card h5 {
        font-size: 12px;
        line-height: 18px;
    }

    .section-9 .global h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        margin-top: 55px;
    }

    .section-9 .cards-1 {
        margin-top: 16px;
    }

    .section-9 .global p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 25px;
    }

    .section-9 .cards-1 .card p {
        padding: 0 !important;
        font-size: 12px;
        line-height: 18px;
    }

    .section-9 .cards-1 .card a {
        font-size: 12px;
        line-height: 18px;
    }

    .section-9 .cards-1 .card a::after {
        width: 85%;
    }

    .section-9 {
        padding-bottom: 0px;
    }

    .faq_section {
        padding: 30px;
    }


    .accordion-item:first-of-type .accordion-button{
        border-top-left-radius: 50px !important;
        border-top-right-radius: 50px !important;
    }

    .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 50px !important;
        border-top-right-radius: 50px !important;
    }

    .accordion-button {
        border-radius: 80px !important;
    }  

    .section-10 .accordion {
        padding: 0 !important;
    }

    .accordion-button {
        font-size: 12px;
        line-height: 18px;
        padding: 0;
    }

    .section-10 .accordion h2 {
        padding: 16px 10px;
    }

    .section-10 .accordion-body {
        font-size: 12px;
        line-height: 18px;
    }

    .section-10 .accordion .accordion-collapse {
        padding: 0 10px 0px 10px !important;
    }

    .section-10 .questions h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }

    .section-10 .questions p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 32px;
    }

    .main .container .row .col-lg-4 .content .button button {
        padding: 9px 30px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }

    footer .container .row .col-lg-4 p {
        font-size: 13px;
        line-height: 24px;
        padding-top: 16px;
        text-align: justify;
    }

    footer .container .row .col-lg-12 {
        flex-direction: column;
    }

    footer .container .row .col-lg-4 {
        margin-bottom: 16px;
    }

    footer .container .row .second {
        padding-left: 12px;
    }

    footer .container .row .col-lg-4 h5 {
        margin-bottom: 16px;
    }

    footer .container .row .col-lg-12 .one {
        margin-bottom: 16px;
    }

    footer .container .row .col-lg-12 .two p {
        font-size: 12px;
        line-height: 18px;
    }

    main .container .row .best {
        margin-bottom: 80px;
    }

    .main .container .row .left-1 h2 {
        font-size: 18px;
        line-height: 27px;
        margin-top: -10px;
        margin-bottom: 20px;
    }

    .main .container .row .left-1 {
        padding-right: 10px;
    }

    .main .container .row .right-1 select {
        font-size: 12px;
        margin-top: -21px;
        width: 109px;
    }

    main .container .row .col-lg-6 {
        margin-bottom: 25px;
    }

    .main .container .row .hi {
        margin-top: 20px;
    }

    .main .container .row .col-lg-4 {
        margin-bottom: 0;
    }

    .main .container .row .right-1 label {
        top: 0px;
    }

    .main-2 {
        padding-bottom: 0px;
    }

    .main-2 .container .row .left-2 {
        text-align: center;
    }

    .main-2 .container .row .left-2 h1 {
        font-weight: 700;
        font-size: 26px;
        line-height: 39px;
    }

    .main-2 .container .row .left-2 p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .main-2 .container .row .owl_11 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
    }

    .section-11 {
        padding-top: 18px;
        padding-bottom: 36px;
    }

    .section-11 .accordion-flush .accordion-item .accordion-button,
    .accordion-flush .accordion-item .accordion-button.collapsed {
        padding: 10px;
    }

    .section-11 .left {
        margin-bottom: 30px;
    }

    .section-11 .col-lg-6 h2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .section-11 .col-lg-6 .accordion-body {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
    }

    .main .container .row .owl_10 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item {
        padding: 8px 10px;
        background-color: #feefe7;
    }

    .main .container .row .col-lg-6 {
        margin: 0px;
    }

    .main .container .row .owl_10 {
        margin: 0px;
    }

    .main .container .row .owl_10 .item {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .about-The-course .bottom {
        display: block;
    }

    .about-The-course .bottom h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: var(--color-a);
        margin-bottom: 5px;
    }

    .about-The-course .bottom p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--p-color);
        padding-right: 0px;
        padding-left: 0px;
        text-align: justify;
    }

    .about-The-course .table .main-box {
        border: 1px solid black;
        padding: 20px 16px;
    }

    .about-The-course .table .main-box .row {
        position: relative;
    }

    .about-The-course .table .main-box .row img {
        padding: 0px;
    }

    .about-The-course .table .main-box .row .silver-img {
        padding-left: 3px;
    }

    .about-The-course .table .main-box .row .hour-img {
        position: absolute;
        top: -31px;
        right: 5px;
    }

    .about-The-course .table .table-1 h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: var(--color-a);
        padding: 0px;
    }

    .about-The-course .table .table-1 .part {
        border-bottom: 1px dashed #a4a3a2;
    }

    .about-The-course .table .table-1 .part li {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-a);
        padding-bottom: 10px;
    }

    .about-The-course .table .table-1 .part ul {
        padding-left: 50px;
    }

    .about-The-course .table .table-1 .part ul li {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: var(--color-a);
    }

    .feature .container .row .top p {
        padding: 0;
    }

    .demo .container .row .top p {
        padding: 0;
    }

    .one-to-one {
        padding-top: 44px;
        padding-bottom: 44px;
    }

    .one-to-one .col-lg-8 .col-lg-12 .btn2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        padding: 9px 32px;
    }

    .container .row .left h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 5px;
    }

    .container .row .left p {
        font-weight: 500;
        font-size: 12px !important;
        line-height: 18px;
        padding: 0;
        background: transparent;
    }

    .one-to-one h1 {
        font-weight: 700;
        font-size: 26px;
        line-height: 39px;
    }

    .one-to-one p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .one-to-one .para {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .one-to-one .col-lg-8 .col-auto .item-1 {
        margin-bottom: 22px;
    }

    .more-course {
        padding-top: 32px;
        padding-bottom: 52px;
    }

    .more-course .container .row .left h2 {
        font-weight: 700;
        font-size: 18px !important;
        line-height: 27px;
    }

    .more-course .container .row .left p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .more-course .col-lg-6 h3 {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .more-course .col-lg-6 p {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    .more-course .col-lg-6 .img img {
        width: 50px;
        height: 50px;
    }

    .assitance {
        padding-top: 32px !important;
        padding-bottom: 32px !important;
    }

    .assitance .col-lg-6 h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }

    .assitance .col-lg-6 .para {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .assitance .col-lg-6 .d-flex p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
    }

    .assitance .col-lg-6 .d-flex .sign-img img {
        height: 20px;
    }

    .assitance .space {
        margin-bottom: 33px;
    }

    .feature .container .row .top h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }

    .feature .container .row .top {
        display: block;
    }

    .feature .container .row .top p {
        text-align: start;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .feature {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .feature .container .row .top p {
        padding: 0 !important;
    }

    .test-series {
        padding-top: 25px;
        padding-bottom: 0;
    }

    .test-series .media {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .test-series .row h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 39px;
        text-align: center;
        padding-right: 0px;
        margin-bottom: 10px;
    }

    .test-series .row .btn1 {
        margin-top: 20px;
    }

    .test-series .row .col-lg-6 {
        justify-content: center !important;
        margin-bottom: 20px;
    }

    .test-series .row .col-lg-6 img {
        width: 147px;
    }

    .study-package .row .subjects div div {
        border: 1px solid lightgray;
        width: 100px !important;
        text-align: center;
        font-weight: 600;
        font-size: 11px;
        line-height: 18px;
        color: var(--p-color);
    }

    .study-package .col-6 h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin: auto 0;
        width: max-content;
    }

    .provide {
        padding: 32px 0 80px 0;
    }

    .provide .container .row .right button,
    .provide .container .row .right span {
        right: 100px !important;
    }

    .provide .container .row .right span {
        top: 20px;
    }

    .section-6 {
        padding: 32px 0 5px;
    }

    .btn1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
    }

    .one-to-one .col-lg-8 .col-lg-12 .btn2::after {
        height: 100%;
    }

    .container .row .right button::after {
        height: 100%;
        padding: 9px 32px;
    }

    .self-main {
        padding-top: 22px;
        padding-bottom: 31px;
    }

    .self-main .container .row .info h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .self-main .container .row .info .price .span {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
    }

    .self-main .container .row .info .price {
        font-weight: 800;
        font-size: 12px;
        line-height: 18px;
    }

    .self-main .container .row .info h2 {
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
    }

    .self-main .container .row .info .para {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    .self-main .container .row .info .pre {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .self-main .container .row .info .teacher>span {
        display: block;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        margin: 5px 0;
    }

    .self-main .container .row .info .button .first {
        margin-right: 13px;
    }

    .self-main .container .row .info .button .first,
    .self-main .container .row .info .button .second {
        padding: 10px 36px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .self-main .container .row .info .teacher span span {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .course-feature .container .row .col-lg-7 .col-lg-6 {
        padding-left: 32px;
    }

    .course-feature .container .row .col-lg-7 h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .course-feature .container .row .col-lg-7 .col-lg-6 .image {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .course-feature .container .row .col-lg-7 .second-row h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
        margin-bottom: 19px;
    }

    .course-feature .container .row .col-lg-7 .row {
        padding-bottom: 0;
    }

    .course-feature .col-lg-7 .second-row h2 button {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        padding: 10px 20px;
    }

    .course-feature .col-lg-7 .third-row .accordion-item,
    .course-feature .col-lg-7 .second-row .accordion-item {
        padding: 11px;
    }

    .course-feature .col-lg-7 .third-row .accordion-item h2 button {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .course-feature .col-lg-7 .third-row .accordion .accordion-body {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        padding: 0 5px;
    }

    .course-feature .col-lg-7 .fourth-row {
        padding-bottom: 20px !important;
    }

    .course-feature .col-lg-7 .fourth-row h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .course-feature .col-lg-7 .fourth-row .bio h6 {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .course-feature .col-lg-7 .fourth-row .bio p {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    .course-feature .col-lg-7 .fourth-row .bio span {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    .course-feature .col-lg-7 .fourth-row .profile h5 {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    .course-feature .col-lg-7 .fifth-row h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .course-feature .col-lg-7 .fifth-row .rating p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .course-feature .col-lg-7 .fifth-row .profile-info h2 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .course-feature .col-lg-7 .fifth-row .profile-info p {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    .course-feature .container .row .col-lg-5 .info h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .course-feature .container .row .col-lg-5 .info .rupee h2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 33px;
    }

    .course-feature .container .row .col-lg-5 .info .rupee button {
        padding: 10px 25px;
        /*05-01-2023*/
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-left: 20px;
    }

    .margin-bottom {
        margin-bottom: 170px !important;
    }

    .ask-a-doubt .row .col-lg-4 .right-1 h5 {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .ask-a-doubt .row .col-lg-4 .right-1 button {
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        padding: 10px 0;
    }

    .ask-a-doubt .container .row .col-lg-8 .doubt h4 {
        margin-bottom: 16px;
    }

    .ask-a-doubt .container .row .col-lg-8 .menu select {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 16px;
    }


    .ask-a-doubt .container .row .col-lg-8 .menu .select-2 {
        width: -webkit-fill-available;
    }

    .ask-a-doubt .container .row .col-lg-8 .row-2 textarea {
        margin-bottom: 21px !important;
    }

    .ask-a-doubt .container .row .col-lg-8 .row-3 h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }

    .ask-a-doubt .container .row .col-lg-8 .row-3 p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .ask-a-doubt .container .row .col-lg-8 .row-3 .content h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .ask-a-doubt .container .row .col-lg-8 .row-3 .content h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .ask-a-doubt .container .row .col-lg-8 .row-3 .content p {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    .ask-a-doubt .container .row .col-lg-8 .row-3 .content .answer h6 {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
    }

    .ask-a-doubt .container .row .col-lg-8 .row-3 .content .answer p {
        font-weight: 400;
        font-size: 8px;
        line-height: 12px;
    }

    .ask-a-doubt .row .col-lg-4 .right-2 {
        margin-bottom: 20px;
    }

    .ask-a-doubt .col-lg-8 .question-row .question-1 {
        background: #ffffff;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
        padding: 0px 15px;
    }

    .ask-a-doubt .col-lg-8 .question-row .question-1 .question-number:last-child {
        border-bottom: none;
    }

    .ask-a-doubt .col-lg-8 .question-row .question-number {
        display: flex;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        padding: 15px;
        border-bottom: 2px solid #dbdbdb;
    }

    .ask-a-doubt .col-lg-8 .question-row .question-number p {
        margin-right: 10px;
        color: var(--color-a);
    }

    .ask-a-doubt .col-lg-8 .question-row .question-number a {
        color: var(--color-a);
        text-decoration: none;
        text-align: justify;
    }

    .Our-Resources .top- h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        margin-top: 10px;
    }

    .Our-Resources .top- {
        padding-bottom: 42px;
        padding-top: 20px;
    }

    .Our-Resources .position-relative .select-1 {
        padding: 10px;
    }

    .Our-Resources .left-resource h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
    }

    .Our-Resources .left-resource p {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
    }

    .Our-Resources .preview-result .table thead tr th,
    .Our-Resources .old-paper .table thead tr th {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        padding: 10px;
    }

    .Our-Resources .preview-result .table tbody tr td,
    .Our-Resources .old-paper .table tbody tr td {
        font-weight: 400;
        font-size: 12px;
        padding: 10px;

        line-height: 18px;
    }

    .Our-Resources .preview-result .table tbody tr td:nth-child(2),
    .Our-Resources .preview-result .table tbody tr td:nth-child(3),
    .Our-Resources .old-paper .table tbody tr td:nth-child(2),
    .Our-Resources .old-paper .table tbody tr td:nth-child(3) {
        font-weight: 500;
        padding: 10px;
    }

    .Our-Resources .content- ul li a {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
    }

    .Our-Resources .content- ul li {
        padding: 5px 0;
    }

    .Our-Resources .right-resourse .video .lecture-info p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .Our-Resources .right-resourse .video .lecture-info h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }

    .Our-Resources .right-resourse .video .lecture-info span {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .Our-Resources .item {
        padding: 10px;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .Our-Resources .right-resourse .right-1 button {
        font-size: 12px;
    }

    .resourse-doubt-4 {
        padding-top: 10px;
    }

    .resourse-doubt-4 .container .row .col-lg-6 h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: var(--color-a);
        margin-bottom: 10px;
    }

    .resourse-doubt-4 .container .row .col-lg-12 {
        margin-bottom: 15px;
    }

    .resourse-doubt-4 .row .col-lg-8 {
        text-align: justify;
    }

    .resourse-doubt-4 .row .col-lg-8 h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        color: var(--color-a);
        margin-bottom: 10px;
    }

    .resourse-doubt-4 .row .col-lg-8 p {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: var(--color-a);
    }

    .resourse-doubt-4 .row .col-lg-8 .para {
        margin-bottom: 20px;
    }

    .resourse-doubt-4 .row .col-lg-8 h3 {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: var(--color-a);
        margin-top: 20px;
    }

    .resourse-doubt-4 .row .col-lg-8 h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: var(--color-a);
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .resourse-doubt-4 .row .col-lg-8 ul li {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
    }

    .resourse-doubt-4 .row .col-lg-8 .row .col-lg-6 {
        background: #feefe7;
    }

    .resourse-doubt-4 .row .col-lg-8 .lorem {
        margin-bottom: 20px;
    }

    .resourse-doubt-4 .row .col-lg-8 .lorem h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .resourse-doubt-4 .row .col-lg-8 .lorem p {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: var(--color-a);
    }

    .resourse-doubt-4 .row .col-lg-8 .para-2 {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: var(--color-a);
        margin-bottom: 20px;
    }

    .resourse-doubt-4 .row .col-lg-8 table {
        margin-bottom: 30px;
    }

    .resourse-doubt-4 .row .col-lg-8 table thead {
        background: #feefe7;
    }

    .resourse-doubt-4 .row .col-lg-8 table thead td {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: var(--color-a);
        border: 1px solid gray;
    }

    .resourse-doubt-4 .row .col-lg-8 table tbody td {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: var(--color-a);
        border: 1px solid gray;
        text-align: left;
    }

    .resourse-doubt-4 .row .col-lg-8 .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 40px;
        margin-bottom: 50px;
    }

    .resourse-doubt-4 .row .col-lg-8 .buttons button {
        padding: 10px 45px 10px 44px;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.02em;
        background: transparent;
        border: 1px solid var(--primary);
        color: var(--primary);
    }

    .resourse-doubt-4 .row .col-lg-8 .buttons button i {
        margin-right: 10px;
    }

    .resourse-doubt-4 .row .col-lg-8 .buttons button:hover {
        background: var(--primary);
        color: var(--white);
    }

    .resourse-doubt-4 .row .col-lg-4 .right-1 button {
        font-size: 12px;
        line-height: 18x;
    }

    .start {
        padding-top: 17px;
    }

    .start .container .row .col-lg-6 h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;
        margin-top: 15px;
    }

    .start .container .row .col-lg-6 .select-1 {
        padding: 13px 30px 13px 15px;
        width: 150px;
        margin: 0;
    }

    .start .container .row .col-lg-12 .item {
        border: 1px solid #8d8b8b;
        padding: 13px;
        background: white;
        margin: 0 10px;
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
        height: 70px;
        justify-content: start;
    }

    .start .container .row .col-lg-12 .item .image {
        display: none;
    }

    .resourse-1 {
        background: #f5f5f5;
    }

    .resourse-1 .container .row .col-lg-8 h3 {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: var(--color-a);
        margin-bottom: 15px;
    }

    .resourse-1 .container .row .col-lg-8 .subject {
        margin-bottom: 20px;
    }

    .resourse-1 .container .row .col-lg-8 .subject div {
        padding: 7px;
        background: #fff7f3;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-a);
    }

    .resourse-1 .container .row .col-lg-8 .subject div:hover {
        background: #fddfd0;
        color: var(--primary);
    }

    .resourse-1 .row .accordion .accordion-body .item .text span {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
    }

    .resourse-1 .row .accordion-body .item .text p {
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
    }

    .resourse-1 .row .accordion-body .item .video-img img {
        width: 100px !important;
        height: auto;
    }

    .resourse-1 .row .accordion-body .item .text h5 {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 5px;
    }

    .resourse-1 .row .accordion-body .item .text h6 {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    .accordion-button:not(.collapsed) {
        color: black;
        background-color: transparent !important;
        box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    }

    .accordion-button:focus {
        z-index: 3;
        border-color: black !important;
        outline: 0;
        box-shadow: var(--bs-accordion-btn-focus-box-shadow);
    }

    .resourse-1 .row .col-lg-4 .right-1 h5 {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .resourse-1 .row .col-lg-4 .right-1 button {
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        padding: 10px 0;
    }

    .resourse-1 .row .accordion h2 button {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        padding: 15px;
    }

    .resourse-1 .row .col-lg-4 .right-2 {
        padding: 20px;
        border: 1px solid var(--p-color);
        border-radius: 12px;
        background: #fff;
        margin-top: 20px;
    }

    .resourse-1 .row .col-lg-4 .right-2 h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }

    .resourse-1 .row .col-lg-4 .right-2 .para {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 10px;
    }

    .resourse-1 .row .col-lg-4 .right-3 {
        margin-bottom: 150px;
    }

    .resourse-1 .row .col-lg-4 .right-3 h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }

    .resourse-1 .row .col-lg-4 .right-3 p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .resourse-1 .row .col-lg-4 .right-3 .btn-3 {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
    }

    .resourse-1 .row .col-lg-4 .right-3 .tox-tinymce {
        height: 250px !important;
    }

    .resourse-1 .row .ngbAccordion_padding .item .img .img-fluid {
        width: 155px;
    }

    #course_data .hi .packageDetail_gradeID_data {
        width: auto;
    }

    .package_box {
        display: block;
        padding: 20px;
    }

    .main-2 .container .row .owl_11 .global_btn .country_btn {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .study-package .row .col-lg-4 .material .button button {
        padding: 9px 32px;
    }
}

@media (max-width: 1024px) and (min-width: 992px) {
    .study-package .button {
        display: flex;
    }

    .study-package .row .col-lg-4 .material .button button {
        padding: 9px 24px;
    }

    .study-package .row .col-lg-4 .material .name span {
        font-size: 14px;
    }

    .study-package .row .col-lg-4 .material .price h2 {
        font-size: 22px;
    }

    .test-series h1 {
        padding-right: 0;
    }

    .study-package .right-11 input {
        margin-right: 140px !important;
    }
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
    .section-1 .left {
        padding: 40px 80px !important;
    }
}

.accordion-active {
    border: 2px solid #fee7d8;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-bottom: 0px solid transparent !important;
}

.accordion-button:not(.collapsed) {
    color: var(--primary);
}

.accordion-active .accordion-button,
.accordion-active .accordion-header {
    border: none !important;
}


.autowidth .owl-stage {
    width: 700% !important;
}

@media (min-width: 768px) {
    .autowidth .owl-stage {
        width: 400% !important;
    }
}

@media (min-width: 992px) {
    .autowidth .owl-stage {
        width: 300% !important;
    }
}


.rating-section h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    color: #FF9F10;
    text-align: center;

}

.rating-section .p {
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #494645 !important;
    text-align: center;
}

.rating-section .ratings-main img {
    width: 16.67px;
    height: 16.67px;
}

.rating-section .ratings-main h6 {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0;
    margin-left: 6.67px;
    margin-right: 10px;
}

.rating-section .ratings-main {
    margin: 8px 0;
}

.rating-section .ratings-main .totle-rating {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0;
    margin-left: 10px;
}

.rating-section .ratings-main .progress {
    width: 200px;
    height: 10px;
    border-radius: 10px;
}

.rating-section .ratings-main .progress span {
    width: 50%;
    height: 10px;
    background-color: #FF9F10;
    border-radius: 10px;
}

@media (max-width:767px) {
    .ngbAccordion_padding .owl-item .item {
        width: 155px !important;
    }

    .rating-section h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }

    .rating-section .p {
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .rating-section .ratings-main {
        margin: 3px 0;
    }

    .rating-section .ratings-main img {
        width: 8.33px;
        height: 8.33px;
    }

    .rating-section .ratings-main h6 {
        font-weight: 600;
        font-size: 8px !important;
        line-height: 12px;
        margin-right: 3px;
        margin-left: 3px;
    }

    .rating-section .ratings-main .totle-rating {
        font-weight: 600;
        font-size: 10px !important;
        margin-left: 3px;
        line-height: 15px;
    }

    .rating-section .ratings-main .progress {
        width: 150px;
        height: 4px;
    }

    .faq_heading {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }

    .ngbAccordion_padding .owl-item .item {
        width: 155px !important;
    }

    .mind-map_page .ngbAccordion_padding .owl-item .item {
        width: auto !important;
    }
}

button.btn.country_btn.jee.active_1 {
    border: none;
    background: #000 !important;
}


.global_btn .inspired_results_btn,
.global_btn .country_btn,
.global_btn .courses_btn,
.global_btn .res_courses_btn {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 10px 0px 10px 0px;
    background: #ffffff00;
    border-radius: unset;
    font-weight: 500;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
}

.courses_btn:hover,
.country_btn:hover,
.inspired_results_btn:hover,
.global_btn .res_courses_btn:hover {
    background-color: #FF6A0B;
    color: white;

}

.global_btn {
    overflow: auto;
    white-space: nowrap;
}

.global_btn {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-x: scroll;
}

.global_btn::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.faq_heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-a);
}

.video_width {
    width: 95%;
}

@media (max-width:767px) {
    .faq_heading {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }

    .info .book_rating {
        padding: 4px 2px;
        margin: auto;
    }
}

@media (max-width:280px) {
    .course-feature .col-lg-5 .box .img-fluid {
        max-width: 20px !important;
    }

    .ask-a-doubt .container .row .col-lg-8 .menu .select-1 {
        width: 110px;
    }
}

.accordion-header {
    padding: 5px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-a);
}

.spanError {
    color: #f47408;
}

.mt-20 {
    margin-top: 20px;
}

.header-mt-50 {
    margin-top: 50px;
}

.slider-txt .course-list {
    display: flex;
}

.header-mt-100 {
    margin-top: 100px;
}

@media (max-width: 768px) {
    .rplr-0 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .header-mt-50 {
        margin-top: 60px !important;
    }
}

@media (max-width: 768px) {
    .rmt-25 {
        margin-top: 25px;
    }
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 60px !important;
}

.header-mt-50 {
    margin-top: 50px;
}

.smt-50 {
    margin-top: 60px !important;
}

.iit-mb-100 {
    margin-bottom: 100px;
}

@media (max-width: 768px) {
    .smt-50 {
        margin-top: 30px !important;
    }

    .iit-mb-100 {
        margin-bottom: 0px !important;
    }

    .iit-mt-50 {
        margin-top: 0px !important;
    }

    .mt-50 {
        margin-top: 55px !important;
    }
}



.pt-0 {
    padding-top: 0 !important;
}

.mt-55 {
    margin-top: 55px;
}

.mln-35 {
    margin-left: -35px;
}

@media (max-width: 576px) {
    .mln-35 {
        margin-left: 0;
    }
}

.mln-145 {
    margin-left: -125px;
}

.navbar {
    height: 60px;
    margin-bottom: 0;
}

.navbar .navbar-header .navbar-toggle {
    border: none;
}

.navbar .navbar-header button .fa {
    font-size: 22px;
    color: #333333;
}

.navbar .navbar-header .navbar-brand {
    padding: 10px 5px 7px 10px;
}

.navbar .navbar-header .rbook-now-link {
    border: 2px solid #f47408;
    padding: 4px 7px;
    display: inline-block;
    margin-top: 15px;
    border-radius: 50px;
    color: #f47408;
    font-weight: bold;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 13px;
    text-decoration: none;
    display: none;
}

.top_header_right {
    text-align: right;
    padding-right: 160px;
}

.book_afree_trial {
    position: absolute;
    right: 11px;
    top: 9px;
}

.book_afree_trial a {
    text-decoration: none;
    border: 2px solid #f47408;
    margin: 0px;
    padding: 8px 15px;
    border-radius: 50px;
    color: #f47408;
    font-weight: bold;
    display: inline-block;
}

.header-book-btn {
    position: absolute;
    right: 10px;
    top: 12px;
}

.header-book-btn button {
    border: 0;
    background-color: #fff;
    font-size: 16px;
    outline: none;
}

.header-book-btn button small {
    font-size: 24px;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background-color: #f47408;
    display: inline-block;
    color: #fff;
}

.navbar .navbar-header .rbook-now-link:hover {
    color: #fff;
    background-color: #f47408;
}

.navbar .navbar-header .header-book-btn {
    display: inline-block;
    position: absolute;
    right: 8%;
    top: 11px;
}

.navbar .navbar-header .header-book-btn .dropdown .btn-warning {
    color: #333;
    background-color: #ffffff;
    border: none;
    border-radius: 50px;
    border-width: 2px;
    font-size: 16px;
    outline: none;
    padding-top: 0;
}

.navbar .navbar-header .header-book-btn .dropdown .btn-warning small {
    font-size: 25px;
    background-color: #f47408;
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    display: inline-block;
}

@media (max-width: 768px) {
    .navbar .navbar-header .book-free-demo-btn {
        position: absolute;
        right: 17%;
        top: 1px;
    }

    .header-book-btn button small {
        display: none;
    }

    .header-book-btn button {
        border: 0;
        background-color: #fff;
        font-size: 16px;
        outline: none;
        border: 2px solid #f4770d;
        border-radius: 15px;
        padding: 2px 10px;
    }

    .navbar .navbar-header .header-book-btn {
        display: inline-block;
        position: absolute;
        right: 16%;
        top: 11px;
    }

    .navbar .navbar-header .rbook-now-link {
        display: inline-block;
        margin-left: -22px;
    }

    .navbar .navbar-header .navbar-brand img {
        width: 75%;
    }
}

@media (max-width: 321px) {
    .navbar .navbar-header .rbook-now-link {
        display: inline-block;
        margin-left: -40px;
        padding: 4px 6px;
        font-size: 11px;
    }

    .navbar .navbar-header .navbar-brand img {
        width: 70%;
    }
    .ask-a-doubt .container .row .col-lg-8 .menu .select-1,
.ask-a-doubt .container .row .col-lg-8 .menu .select-2,
.ask-a-doubt .container .row .col-lg-8 .menu .select-3 {
    padding: 13px 30px 13px 15px;
}

}

.navbar .navbar-collapse ul li .book-now-link {
    border: 2px solid #f47408;
    margin: 0px;
    padding: 8px 15px;
    padding-top: 8px !important;
    margin-top: 9px;
    border-radius: 50px;
    color: #f47408;
    font-weight: bold;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.navbar .navbar-collapse ul li .dropdown {
    margin-top: 1px;
}

.navbar .navbar-collapse ul li .dropdown button {
    color: #505050;
    background-color: #ffffff;
    border-color: #ffffff;
    font-size: 18px;
}

@media (max-width:768px) {
    .navbar .navbar-collapse ul li .dropdown .dropdown-menu li a {
        display: block;
        text-align: center;
        padding: 10px 0;
        font-size: 18px;
        color: #333;
    }
}

.navbar .navbar-collapse ul li .dropdown button small {
    display: inline-block;
    font-size: 25px;
    padding: 3px;
    background-color: #f47408;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.navbar .navbar-collapse ul li .book-now-link:hover {
    color: #fff;
    background-color: #f47408;
}

@media (max-width: 768px) {
    .navbar .navbar-collapse ul li .book-now-link {
        width: 50%;
        margin: auto;
    }

    .navbar-nav>li>a {
        padding: 15px 0px 15px 0px !important;
        margin: 0px 20px;
    }
}

.navbar-nav>li>a {
    padding-bottom: 10px;
    line-height: 20px;
    text-decoration: none;
}

.navbar-nav>li>a:after {
    display: none;
}

.navbar-inverse .navbar-nav>li>a {
    color: #000000;
}

.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:hover {
    color: #f47408;
    background-color: transparent;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
    color: #f47408;
    background-color: transparent;
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
    background-color: #fff;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border-color: #ffffff;
    background-color: #fff;
}

@media (max-width: 768px) {

    .navbar-inverse .navbar-collapse,
    .navbar-inverse .navbar-form {
        text-align: center;
        border-bottom: 1px solid lightgray;
    }

    .navbar-inverse .navbar-toggle:focus,
    .navbar-inverse .navbar-toggle {
        position: relative;
        float: right;
        margin-right: 0px;
        margin-top: 6px;
        border: none;
        font-size: 18px;
    }

    .navbar-inverse .navbar-brand {
        padding: 10px 0px !important;
    }

    .navbar-inverse .navbar-brand img {
        width: 80%;
    }

    .top_header_right {
        text-align: right;
        padding-right: 0px;
    }

    .header-book-btn {
        position: absolute;
        right: 60px;
        top: 12px;
    }

    .book_afree_trial {
        position: absolute;
        right: 50px;
        top: 9px;
    }
}

.navbar-inverse {
    background-color: #ffffff;
    border-color: #ffffff;
}

.navbar .navbar-nav {
    float: none;
    vertical-align: top;
}

.navbar .navbar-collapse {
    text-align: center;
    float: right;
}

.navbar-inverse .navbar-brand {
    padding: 8px 15px;
}

.top-sticky {
    width: 100%;
    background-color: #de6601;
    position: fixed;
    top: 60px;
    left: 0;
    text-align: center;
    color: #ffffff;
    padding: 4px;
    z-index: 999;
    display: none;
}

.top-sticky p {
    width: 70%;
    margin: auto;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.5px;
}

@media (max-width: 768px) {
    .top-sticky p {
        width: 100%;
        font-size: 13px;
    }

    .navbar .navbar-collapse {
        width: 100%;
    }
}

.top-sticky p a {
    text-decoration: none;
    color: #ffffff;
    display: inline-block;
    margin-left: 30px;
    font-size: 15px;
    padding: 2px 15px;
    border: 2px solid #fff;
    border-radius: 50px;
}

@media (max-width: 768px) {
    .top-sticky p a {
        margin-left: 0px;
        font-size: 13px;
        padding: 2px 12px;
    }
}

.modal .modal-dialog .modal-content .modal-header {
    border-bottom: none;
}

.modal .modal-dialog .modal-content .modal-header h3 {
    padding: 0 5px;
}

.modal .modal-dialog .modal-content .modal-header h3 span {
    color: #f47408;
}

.modal .modal-dialog .modal-content .modal-body {
    padding: 10px;
    float: right;
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}

.neet-slider-sec {
     background-image: url("/assets/images/askiitian-global/neet-slider-img.png"); 
    background-size: cover;
    background-repeat: repeat-x;
    background-position-x: -215px;
    padding: 25px 0 420px;
}

@media (max-width: 576px) {
    .neet-slider-sec {
        padding: 10px 0 100px !important;
        /* background-image: url("/assets/images/askiitian-global/res-iit-slider-img.png"); */
    }
}

.neet-slider-sec .modal-width {
    width: 75%;
    margin: auto;
}

@media (max-width: 768px) {
    .neet-slider-sec .modal-width {
        width: 100%;
    }
}

.neet-slider-sec .modal-header .close {
    border: 1px solid #020202;
    border-radius: 50%;
    padding: 3px 7px;
    opacity: 0.25;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.neet-slider-sec .modal-header .close:hover {
    opacity: 0.6;
}

.iit-slider-sec {
    background-image: url("/assets/images/askiitian-global/iit-slider-img.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px 0 295px;
    margin-top: 50px;
}

@media (max-width: 768px) {
    .iit-slider-sec {
        padding: 10px 0 60px;
        background-repeat: no-repeat;
    }
}

.iit-slider-sec .modal-width {
    width: 75%;
    margin: auto;
}

@media (max-width: 768px) {
    .iit-slider-sec .modal-width {
        width: 100%;
    }
}

.iit-slider-sec .modal-header .close {
    border: 1px solid #020202;
    border-radius: 50%;
    padding: 3px 7px;
    opacity: 0.25;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.iit-slider-sec .modal-header .close:hover {
    opacity: 0.6;
}

.slider-txt h1 {
    color: #333333;
    text-shadow: 0 1px 3px rgba(128, 128, 128, 0.3);
    line-height: 45px;
}

@media (max-width: 576px) {
    .slider-txt h1 {
        font-size: 28px;
        line-height: 1.2;
        text-align: center;
        margin-top: 30px;
    }
}

.slider-txt h1 span {
    color: #f47408;
    font-weight: bold;
}

.slider-txt .course-list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-top: 20px;
}

.slider-txt .course-list ul li {
    font-size: 18px;
    color: #333333;
    margin-top: 5px;
    text-shadow: 0 1px 3px rgba(128, 128, 128, 0.2);
}

.slider-txt .course-list ul li span:empty {
    width: 13px;
    height: 13px;
    margin-right: 8px;
    border-radius: 50%;
    border: 2px solid #f47408;
    display: inline-block;
    color: #333333;
    -webkit-box-shadow: 0 1px 3px rgba(128, 128, 128, 0.2);
    box-shadow: 0 1px 3px rgba(128, 128, 128, 0.2);
}

.slider-txt .course-list ul li .fa {
    color: #6ed423;
}

.slider-txt .course-list-right {
    margin-left: -40px;
}

.slider-txt .responsive-head-txt {
    display: none;
    text-align: center;
}

@media (max-width: 576px) {
    .slider-txt .responsive-head-txt {
        display: block;
    }
}

.slider-txt .responsive-head-txt h3 {
    text-align: center;
    text-shadow: 0 1px 3px rgba(128, 128, 128, 0.3);
}

@media (max-width: 576px) {
    .slider-txt .responsive-head-txt h3 {
        margin-top: 25px;
    }
}

.slider-txt .responsive-head-txt a {
    background-color: #68C721;
    width: 75%;
    display: inline-block;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #68C721;
    text-decoration: none;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    -webkit-box-shadow: 0 1px 10px rgba(128, 128, 128, 0.3);
    box-shadow: 0 1px 10px rgba(128, 128, 128, 0.3);
}

@media (max-width: 768px) {
    .slider-txt .responsive-head-txt a {
        margin-top: 18px;
        text-decoration: none;
        display: inline-block;
        text-shadow: 4px 4px 20px gray;
    }
}

#modalMobile {
    padding: 0 30px 0 100px;
}

.book-form {
    padding: 10px 20px 25px;
    margin-top: 55px;
    width: 72%;
    background: #4b4844;
    float: right;
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.input-group {
    margin-bottom: 15px;
}

.ctry-drpdwn {
    display: inline-block;
    position: absolute;
    font-size: 21px;
    border: none;
    left: 30px;
}

#admin_cal_list,
#admin_cal_list2 {
    position: absolute;
    top: 32px;
    left: -30px;
    height: 110px;
    overflow: auto;
}

#select_staff,
#select_staff2 {
    border: none;
    width: 25px;
    background-color: #fff !important;
    padding: 0 0px;
    font-size: 15px;
    font-weight: normal;
    color: #000;
}

#select_staff:hover,
#select_staff2:hover {
    background-color: #fff !important;
}

#select_staff:focus,
#select_staff:active,
#select_staff2:focus,
#select_staff2:active {
    background-color: #fff !important;
    border: none !important;
    outline: none;
}


#txtMobile {
    padding: 0 30px 0 100px;
}

.book-form .google-register-button {
    background: #fff;
    color: #425365;
    text-align: center;
    border: 0;
    height: auto;
    padding: 8px 10px;
    margin: 0;
    width: 100%;
    display: block;
    border-radius: 5px;
    text-decoration: none;
}

.book-form .google-register-button .icon {
    content: "";
    background: url(/Resources/images/google_icon.png)no-repeat;
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    border: 0px;
    float: none;
    vertical-align: middle;
    border-radius: 0 0 5px 5px;
}

.book-form .or-txt {
    font-size: 16px;
    color: #fff;
    margin-bottom: 8px;
    text-align: center;
}

.book-form .or-txt:before,
.or-txt:after {
    background-color: #929292;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 44%;
}

.book-form .gbutton-text {
    padding: 0;
    font-size: 13px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
}

.success-pop .modal-content .modal-header {
    text-align: center;
}

.success-pop .modal-content .modal-header h4 {
    font-size: 28px;
    color: #54ab12;
    padding-top: 10px;
}

.success-pop .modal-content .modal-body {
    text-align: center;
}

.success-pop .modal-content .modal-body p {
    font-size: 18px;
    width: 85%;
    margin: auto;
    padding-bottom: 10px;
}

@media (max-width:768px) {
    .success-pop .modal-content .modal-body p {
        font-size: 17px;
        width: 100%;
    }
}

.success-pop .modal-content .modal-body small {
    font-size: 13px;
    font-weight: bold;
}

.book-form h3 {
    color: #fff;
    padding: 0px 0 15px;
    font-size: 22px;
}

.book-form h3 span {
    color: #f47408;
}

.book-form .form-group {
    position: relative;
}

.book-form .form-group .grade {
    padding: 6px 25px;
}

.book-form .form-group input {
    padding: 0 30px;
}

.book-form .form-group img {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    left: 8px;
}

.book-form .head-bn-btn {
    background: #68c721;
    border: none;
    color: #fff;
    margin-bottom: 12px;
    font-size: 16px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-weight: bold;
}

.book-form .head-bn-btn:hover {
    background: #56b40e;
}

@media (max-width: 576px) {
    .book-form {
        display: none;
    }
}

.responsive-header-sec {
    display: none;
}

@media (max-width: 576px) {
    .responsive-header-sec {
        display: block;
    }
}

.responsive-header-sec .res-head-img {
    margin-top: 60px;
}

@media (max-width: 576px) {
    .responsive-header-sec .res-head-img {
        margin-top: 62px;
    }
}

.responsive-header-sec .res-head-img img {
    width: 100%;
}

.responsive-header-sec .res-head-list {
    margin-top: 20px;
}

.responsive-header-sec .res-head-list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.responsive-header-sec .res-head-list ul li {
    margin-left: 16px;
    margin-top: 8px;
    font-size: 18px;
}

@media (max-width: 576px) {
    .responsive-header-sec .res-head-list ul li {
        font-size: 16px;
    }
}

.responsive-header-sec .res-head-list ul li span:empty {
    width: 13px;
    height: 13px;
    margin-right: 8px;
    border-radius: 50%;
    border: 2px solid #f47408;
    display: inline-block;
    color: #333333;
    -webkit-box-shadow: 0 1px 3px rgba(128, 128, 128, 0.2);
    box-shadow: 0 1px 3px rgba(128, 128, 128, 0.2);
}

@media (max-width: 576px) {
    .responsive-header-sec .res-head-list ul li span:empty {
        width: 12px;
        height: 12px;
        margin-right: 3px;
    }
}

.course-section {
    background-color: #f1f1f2;
}

.course-section .course-program1,
.course-section .course-program2 {
    background-color: #ffffff;
}

.course-section .course-program1 .crs-box1 {
    background-color: #fdebdb;
    border-radius: 5px;
    padding: 1px 20px;
    text-align: center;
}

.course-section .course-program1 .crs-box1 h2 {
    margin-top: 10px;
    font-weight: bold;
    color: #f47408;
}

.course-section .course-program1 .crs-box1 p {
    color: #333333;
    font-size: 16px;
}

.course-section .course-program2 {
    background-color: #f1f1f2;
}

@media (max-width: 576px) {
    .course-section .course-program2 .crs-box1-mt {
        margin-top: 20px;
    }
}

.course-section .course-program2 .crs-box2 {
    border: 1px solid #f47408;
    border-radius: 5px;
    position: relative;
    margin-top: 10px;
    background-color: #fff;
}

@media (max-width: 576px) {
    .course-section .course-program2 .crs-box2 {
        height: auto;
    }
}

.ask-wwa-tab-container {
    padding: 60px 0 38px;
}

@media (max-width: 991px) {
    .ask-wwa-tab-container {
        margin-top: 12px;
        padding: 30px 0 0px;
    }
}

.ask-wwa-tab-container .panel-group .panel {
    margin-bottom: 0;
    border-radius: 10px;
    -webkit-box-shadow: 1px 1px 4px #020A281F;
    box-shadow: 1px 1px 4px #020A281F;
    margin-bottom: 10px;
}

.ask-wwa-tab-container .panel-group .panel .panet-txt {
    margin-right: 25px;
}

.ask-wwa-tab-container .panel-group .panel .panet-txt h2 {
    font-size: 17px;
    display: inline-block;
    padding: 0;
    margin: 0;
}

.ask-wwa-tab-container .panel-group .panel-body {
    font-size: 15px;
}

.ask-wwa-tab-container .panel-default>.panel-heading {
    color: #333;
    background-color: #fff;
    border-color: #e4e5e7;
    padding: 0;
    border-radius: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ask-wwa-tab-container .panel-default>.panel-heading a {
    display: block;
    padding: 18px;
}

.ask-wwa-tab-container .panel-default>.panel-heading a:after {
    content: "";
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: -webkit-transform .25s linear;
    transition: transform .25s linear;
    transition: transform .25s linear, -webkit-transform .25s linear;
    -webkit-transition: -webkit-transform .25s linear;
}

.ask-wwa-tab-container .panel-default>.panel-heading a[aria-expanded="true"] {
    background-color: #fff;
    border-top-left-radius: 10px;
    color: #333 !important;
    border-top-right-radius: 10px;
}

.ask-wwa-tab-container .panel-default>.panel-heading a[aria-expanded="true"]:after {
    content: "\2212";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    color: #333;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    line-height: 29px;
    font-size: 15px;
    font-weight: normal;
    margin-top: -25px;
    text-align: center;
}

@media (max-width: 768px) {
    .ask-wwa-tab-container .panel-default>.panel-heading a[aria-expanded="true"]:after {
        margin-top: -34px;
    }
}

.ask-wwa-tab-container .panel-default>.panel-heading a[aria-expanded="false"]:after {
    content: "\002b";
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    color: #333;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    line-height: 29px;
    margin-top: -25px;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
}

@media (max-width: 768px) {
    .ask-wwa-tab-container .panel-default>.panel-heading a[aria-expanded="false"]:after {
        margin-top: -34px;
    }
}

.course-section .course-program2 .crs-box2 .ncrs-box1 {
    background-color: #fdebdb;
    border-radius: 5px;
    padding: 1px 20px;
    text-align: center;
    margin-bottom: 10px;
    position: absolute;
    top: -45px;
    left: 41px;
    width: 85%;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

@media (max-width: 768px) {
    .course-section .course-program2 .crs-box2 .ncrs-box1 {
        left: 27px;
    }
}

.course-section .course-program2 .crs-box2 .ncrs-box1 h2 {
    margin-top: 10px;
    font-weight: bold;
    color: #f47408;
}

@media (max-width: 576px) {
    .course-section .course-program2 .crs-box2 .ncrs-box1 h2 {
        font-size: 25px;
    }
}

.course-section .course-program2 .crs-box2 .ncrs-box1 p {
    color: #333333;
    font-size: 16px;
}

.course-section .course-program2 .crs-box2 .crs-box1 {
    background-color: #fdebdb;
    border-radius: 5px;
    padding: 1px 20px;
    text-align: center;
    margin-bottom: 10px;
    position: absolute;
    top: -45px;
    left: 28px;
    width: 85%;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.course-section .course-program2 .crs-box2 .crs-box1 h2 {
    margin-top: 10px;
    font-weight: bold;
    color: #f47408;
}

@media (max-width: 576px) {
    .course-section .course-program2 .crs-box2 .crs-box1 h2 {
        font-size: 25px;
    }
}

.course-section .course-program2 .crs-box2 .crs-box1 p {
    color: #333333;
    font-size: 15px;
}

.course-section .course-program2 .crs-box2 ul {
    margin: 0;
    padding: 0px 20px 10px;
    list-style-type: none;
}

.course-section .course-program2 .crs-box2 ul li {
    padding-bottom: 10px;
    color: #222;
    text-align: left;
    font-size: 16px;
}

.course-section .course-program2 .crs-box2 ul li .num-txt {
    font-size: 18px;
    color: #f47408;
    font-weight: bold;
}

.course-section .course-program2 .crs-box2 ul li span:empty {
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 50%;
    border: 2px solid #f47408;
    display: inline-block;
    color: #333333;
}

.course-section .course-program2 .crs-box2 .btn-container {
    padding: 0px 60px 15px;
}

.course-section .course-program2 .crs-box2 .btn-container .btn {
    background-color: #fff;
    border: 1px solid #f47408;
    text-align: center;
    color: #f47408;
    font-size: 16px;
    margin: auto;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.course-section .course-program2 .crs-box2 .btn-container .btn:hover {
    color: #f47408;
    background-color: #fdebdb;
    border: 1px solid #fdebdb;
}

.course-section .course-program2 .crs-box2 .btn-container .fa {
    font-size: 30px;
    color: #f47408;
}

@media (max-width: 576px) {

    .course-section .course-program2 #crs-bx2,
    .course-section .course-program2 #ncrs-bx2,
    .course-section .course-program2 #crs-bx3 {
        margin-top: 45px;
    }
}

.course-section .exam-tab-section {
    padding: 66px 0 20px;
}

@media (max-width: 768px) {
    .course-section .exam-tab-section {
        padding: 40px 0 0;
    }
}

.course-section .exam-tab-section .nav {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding: 5px;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.course-section .exam-tab-section .nav li a {
    font-size: 17px;
    color: #333333;
    font-weight: bold;
    padding: 10px 0px;
    background-color: #fdebdb;
    color: #f47408 !important;
    margin: 0 2px;
    margin-top: 2px;
}

.course-section .exam-tab-section .exam-tab-txt-container {
    background-color: #ffffff;
    margin-top: 2px;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 20px;
    font-size: 16px;
    letter-spacing: 0.5px;
}

.course-section .exam-tab-section .exam-tab-txt-container .far {
    color: #f47408;
}

.course-section .exam-tab-section .exam-tab-txt-container p {
    padding-bottom: 8px;
}

.course-section .exam-tab-section .exam-tab-txt-container .live-img-sec {
    padding: 20px 20px 25px;
    background-color: #f47408;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .course-section .exam-tab-section .exam-tab-txt-container .live-img-sec {
        display: none;
    }
}

.course-section .exam-tab-section .exam-tab-txt-container .live-img-sec .liv-img {
    text-align: center;
}

.course-section .exam-tab-section .exam-tab-txt-container .live-img-sec .liv-txt {
    text-align: center;
    margin-top: 15px;
    color: #ffffff;
}

.course-section .exam-tab-section .exam-tab-txt-container .live-img-sec .liv-txt p {
    font-weight: bold;
    letter-spacing: 1.3px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

.course-section .exam-tab-section .exam-tab-txt-container .live-img-sec .liv-txt h2 {
    font-weight: bold;
    margin-top: 8px;
    font-size: 30px;
    color: #fff;
}

.course-section .exam-tab-section .exam-tab-txt-container .live-img-sec .liv-txt .btn {
    background-color: #68c721;
    border: 1px solid #68c721;
    color: #fff;
    border: none;
    width: 60%;
    margin-top: 10px;
    -webkit-box-shadow: 0 1px 5px rgba(128, 128, 128, 0.5);
    box-shadow: 0 1px 5px rgba(128, 128, 128, 0.5);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 16px;
    font-weight: bold;
}

.course-section .exam-tab-section .exam-tab-txt-container .live-img-sec .liv-txt .btn:hover {
    background-color: #56b40e;
}

.course-section .exam-tab-section .exam-tab-txt-container #iit-eligibility #ie-show-more-content ul li a {
    text-decoration: underline;
    color: #333333;
}

.course-section .exam-tab-section .exam-tab-txt-container #nd-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #nd-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #ned-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #ned-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #nac-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #nac-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #ne-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #ne-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #nr-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #nr-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #nep-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #nep-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #id-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #id-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #ied-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #ied-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #iac-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #iac-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #ie-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #ie-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #ir-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #ir-read-less,
.course-section .exam-tab-section .exam-tab-txt-container #iep-read-more,
.course-section .exam-tab-section .exam-tab-txt-container #iep-read-less {
    color: #f47408;
    text-decoration: none;
    display: none;
}

@media (max-width: 768px) {

    .course-section .exam-tab-section .exam-tab-txt-container #nd-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #nd-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #ned-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #ned-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #nac-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #nac-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #ne-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #ne-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #nr-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #nr-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #nep-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #nep-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #id-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #id-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #ied-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #ied-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #iac-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #iac-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #ie-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #ie-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #ir-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #ir-read-less,
    .course-section .exam-tab-section .exam-tab-txt-container #iep-read-more,
    .course-section .exam-tab-section .exam-tab-txt-container #iep-read-less {
        display: block;
    }
}

@media (max-width: 768px) {

    .course-section .exam-tab-section .exam-tab-txt-container #nd-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #ned-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #nac-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #ne-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #nr-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #nep-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #id-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #ied-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #iac-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #ie-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #ir-show-more-content,
    .course-section .exam-tab-section .exam-tab-txt-container #iep-show-more-content {
        display: none;
    }
}

.course-section .exam-tab-section .nav-pills>li.active>a,
.course-section .exam-tab-section .nav-pills>li.active>a:focus,
.course-section .exam-tab-section .nav-pills>li.active>a:hover {
    background-color: #ffffff;
    border-bottom: 2px solid #f47408;
    color: #f47408;
    border-radius: 0;
}

.course-section .exam-tab-section .nav>li>a:focus,
.course-section .exam-tab-section .nav>li>a:hover {
    text-decoration: none;
    background-color: #fdebdb;
    color: #f47408 !important;
}

.course-section .our-faculty {
    padding: 30px 30px 60px;
    background: #ffffff;
    margin-top: 50px;
}

@media (max-width: 768px) {
    .course-section .our-faculty {
        margin-top: 40px;
        padding: 10px 20px 20px;
    }
}

.course-section .our-faculty .view-more-faculty {
    float: right;
    margin-top: 10px;
    color: #f47408;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.course-section .our-faculty .view-more-faculty:hover {
    color: #ff7300;
}

.course-section .our-faculty .fact-head-txt h2 {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
}

.course-section .our-faculty .fact-head-txt h2 span {
    color: #f47408;
}

.course-section .our-faculty .fact-section {
    margin-top: 20px;
}

.course-section .our-faculty .fact-section .view-more-faculty {
    float: right;
    margin-top: 18px;
    font-size: 20px;
}

.course-section .our-faculty .fact-section .view-more-faculty .fa {
    transition: 0.4s;
}

.course-section .our-faculty .fact-section .view-more-faculty:hover .fa {
    transform: translateX(3px)
}

@media (max-width:768px) {
    .course-section .our-faculty .fact-section .view-more-faculty {
        font-size: 16px;
    }
}

.course-section .our-faculty .fact-section a {
    text-decoration: none;
}

.course-section .our-faculty .fact-section .fact-box {
    -webkit-box-shadow: 0 2px 4px 0 rgba(104, 104, 104, 0.2);
    box-shadow: 0 2px 4px 0 rgba(104, 104, 104, 0.2);
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-top: 25px;
    cursor: default;
}

@media (max-width: 576px) {
    .course-section .our-faculty .fact-section .fact-box {
        margin-top: 15px;
    }
}

.course-section .our-faculty .fact-section .fact-box:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.course-section .our-faculty .fact-section .fact-box img {
    width: 100%;
    height: 70%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.course-section .our-faculty .fact-section .fact-box .fact-detail {
    padding: 1px 12px;
}

.course-section .our-faculty .fact-section .fact-box .fact-detail p {
    color: #333333;
}

.course-section .our-faculty .fact-section .fact-txt-section {
    margin-top: 60px;
    padding: 0 20px;
}

@media (max-width: 576px) {
    .course-section .our-faculty .fact-section .fact-txt-section {
        margin-top: 60px;
        padding: 0;
    }
}

.course-section .our-faculty .fact-section .fact-txt-section h2 {
    font-size: 26px;
    font-weight: bold;
}

@media (max-width: 576px) {
    .course-section .our-faculty .fact-section .fact-txt-section h2 {
        font-size: 20px;
        font-weight: bold;
    }
}

.course-section .our-faculty .fact-section .fact-txt-section h2 span {
    color: #f47408;
}

.course-section .our-faculty .fact-section .fact-txt-section p {
    margin-top: 20px;
}

.course-section .our-faculty .fact-section .fact-txt-section p span {
    display: inline-block;
    background-color: #fdebdb;
    padding: 2px 8px;
    color: #f47408;
    font-weight: bold;
    font-size: 16px;
}

.course-section .our-faculty .fact-section .fact-txt-section .fts-txt {
    font-size: 20px;
}

@media (max-width: 576px) {
    .course-section .our-faculty .fact-section .fact-txt-section .fts-txt {
        font-size: 16px;
    }

    .course-section .our-faculty .fact-section .fact-txt-section p {
        margin-top: 0px;
    }
}

.course-section .our-faculty .fact-section .fact-txt-section ul {
    margin: 20px 0 0 0;
    padding: 0;
    list-style-type: none;
}

.course-section .our-faculty .fact-section .fact-txt-section ul li {
    margin-top: 15px;
    font-size: 15px;
}

.course-section .our-faculty .fact-section .fact-txt-section ul li span {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: 1px solid #fdebdb;
}

.course-section .our-faculty .fact-section .fact-txt-section ul li span .fa {
    color: #f47408;
    margin-left: 3px;
}

.course-section .course-details {
    padding: 50px 0 25px;
}

@media (max-width: 768px) {
    .course-section .course-details {
        padding: 15px 0 42px;
    }
}

.course-section .course-details .header-txt h2 {
    font-weight: bold;
    color: #333333;
    font-size: 26px;
    line-height: 1.5;
}

@media (max-width: 576px) {
    .course-section .course-details .header-txt h2 {
        font-size: 22px;
    }
}

@media (max-width: 576px) {
    .course-section .course-details .header-txt h2 img {
        display: none;
    }
}

.course-section .course-details .header-txt h2 span {
    color: #f47408;
    margin-left: 20px;
}

@media (max-width: 576px) {
    .course-section .course-details .header-txt h2 span {
        margin-left: 0px;
    }
}

.course-section .course-details .cd-sec {
    margin-top: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

@media (max-width: 768px) {
    .course-section .course-details .cd-sec {
        margin-top: 20px;
    }
}

.course-section .course-details .cd-sec .cd-box {
    text-align: center;
    height: 230px;
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.course-section .course-details .cd-sec .cd-box:hover .cd-icon {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
}

@media (max-width: 576px) {
    .course-section .course-details .cd-sec .cd-box {
        padding: 0;
        height: 180px;
    }
}

.course-section .course-details .cd-sec .cd-box .cd-icon {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: auto;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

@media (max-width: 576px) {
    .course-section .course-details .cd-sec .cd-box .cd-icon {
        width: 70px;
        height: 70px;
    }
}

.course-section .course-details .cd-sec .cd-box .cd-icon img {
    width: 50%;
    display: inline-block;
    margin-top: 26px;
}

@media (max-width: 576px) {
    .course-section .course-details .cd-sec .cd-box .cd-icon img {
        margin-top: 18px;
    }
}

.course-section .course-details .cd-sec .cd-box .cd-txt h3 {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
}

@media (max-width: 576px) {
    .course-section .course-details .cd-sec .cd-box .cd-txt h3 {
        font-size: 14px;
    }
}

.course-section .course-details .cd-sec .cd-box .cd-txt p {
    color: #333333;
    width: 65%;
    margin: auto;
}

@media (max-width: 576px) {
    .course-section .course-details .cd-sec .cd-box .cd-txt p {
        width: 100%;
        font-size: 12px;
    }
}

.live-class {
    padding: 50px 0;
}

@media (max-width: 576px) {
    .live-class .live-txt-sec .live-head-txt {
        margin-top: -35px;
    }
}

@media (max-width: 576px) {
    .live-class .live-txt-sec .live-head-txt img {
        display: none;
    }
}

.live-class .live-txt-sec .live-head-txt h2 {
    font-weight: bold;
    color: #333333;
    font-size: 26px;
    line-height: 1.3;
}

@media (max-width: 576px) {
    .live-class .live-txt-sec .live-head-txt h2 {
        font-size: 22px;
    }
}

.live-class .live-txt-sec .live-head-txt h2 span {
    color: #f47408;
}

.live-class .live-txt-sec .live-normal-txt {
    margin-top: 20px;
}

@media (max-width: 576px) {
    .live-class .live-txt-sec .live-normal-txt {
        margin-top: 0px;
    }
}

.live-class .live-txt-sec .live-normal-txt p {
    font-size: 17px;
    line-height: 26px;
    color: #222222;
}

@media (max-width: 576px) {
    .live-class .live-txt-sec .live-normal-txt p {
        font-size: 15px;
    }
}

.live-class .live-img-sec {
    padding: 20px 20px 25px;
    background-color: #f47408;
    border-radius: 10px;
    margin-top: 25px;
}

.live-class .live-img-sec .liv-img {
    text-align: center;
}

.live-class .live-img-sec .liv-txt {
    text-align: center;
    margin-top: 15px;
    color: #ffffff;
}

.live-class .live-img-sec .liv-txt p {
    font-weight: bold;
    letter-spacing: 1.3px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

.live-class .live-img-sec .liv-txt h2 {
    font-weight: bold;
    margin-top: 8px;
    font-size: 30px;
    color: #fff;
}

.live-class .live-img-sec .liv-txt .btn {
    background-color: #68c721;
    color: #fff;
    border: none;
    width: 60%;
    margin-top: 10px;
    -webkit-box-shadow: 0 1px 5px rgba(128, 128, 128, 0.5);
    box-shadow: 0 1px 5px rgba(128, 128, 128, 0.5);
}

.result-section {
    padding: 42px 0 80px;
    background-color: #ffffff;
}

@media (max-width: 576px) {
    .result-section {
        padding: 15px 0 40px;
    }
}

.result-section .res-head-txt h2 {
    color: #333333;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
}

@media (max-width: 576px) {
    .result-section .res-head-txt h2 img {
        display: none;
    }
}

.result-section .res-head-txt h2 span {
    color: #f47408;
    margin-left: 8px;
}

@media (max-width: 576px) {
    .result-section .res-head-txt h2 span {
        margin-left: 0px;
    }
}

.result-section .res-sec {
    margin-top: 15px;
}

.result-section .res-sec .toppe-bg {
    position: relative;
}

@media (max-width: 576px) {
    .result-section .res-sec .toppe-bg img {
        width: 135%;
    }
}

.result-section .res-sec .toppe-bg .topper-txt {
    position: absolute;
    top: 90px;
    right: 110px;
}

@media (max-width: 576px) {
    .result-section .res-sec .toppe-bg .topper-txt {
        position: absolute;
        top: 55px;
        right: 90px;
    }
}

.result-section .res-sec .toppe-bg .topper-txt h2 {
    font-weight: bold;
    color: #f47408;
    font-size: 25px;
}

@media (max-width: 576px) {
    .result-section .res-sec .toppe-bg .topper-txt h2 {
        font-size: 22px;
    }
}

.result-section .res-sec .toppe-bg .topper-txt ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.result-section .res-sec .toppe-bg .topper-txt ul li {
    font-size: 15px;
    margin-top: 5px;
}

.result-section .res-sec .toppe-bg .topper-txt ul li span:empty {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: #f47408;
    display: inline-block;
    color: #333333;
}

@media (max-width: 576px) {
    .result-section .res-sec .toppe-bg .topper-txt ul li {
        font-size: 14px;
    }
}

.result-section .res-sec .owl-theme .owl-nav .owl-prev .fas {
    font-size: 18px;
}

.result-section .res-sec .owl-theme .owl-nav .owl-next .fas {
    font-size: 18px;
}

.result-section .res-sec .owl-theme .owl-nav [class*=owl-]:hover,
.result-section .res-sec :focus {
    background: none !important;
    color: #f47408;
    text-decoration: none;
    outline: none;
}

.result-section .res-sec .res-box {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.result-section .res-sec .res-box .res-img {
    width: 100px;
    height: 100px;
}

.result-section .res-sec .res-box .res-img img {
    width: 100%;
    border-radius: 50%;
    border: 2px solid #f47408;
}

.result-section .res-sec .res-box .res-txt h2 {
    color: #f47408;
    font-weight: bold;
    margin: 0 0 5px;
    font-size: 27px;
}

@media (max-width: 768px) {
    .result-section .res-sec .res-box .res-txt h2 {
        font-size: 22px;
    }
}

.result-section .res-sec .res-box .res-txt p {
    font-size: 18px;
    margin-bottom: 5px;
}

.result-section .res-sec .res-box .res-txt small {
    font-size: 14px;
}

.join-now-section {
    padding: 20px 0;
    background-color: #f47408;
}

@media (max-width: 576px) {
    .join-now-section {
        padding: 15px 0;
    }
}

.join-now-section .join-txt {
    margin-top: 65px;
}

@media (max-width: 576px) {
    .join-now-section .join-txt {
        margin-top: 0px;
        text-align: center;
    }
}

.join-now-section .join-txt h2 {
    color: #fff;
    font-weight: bold;
    font-size: 31px;
    letter-spacing: 1px;
}

@media (max-width: 576px) {
    .join-now-section .join-txt h2 {
        font-size: 25px;
    }
}

.join-now-section .join-txt .btn {
    color: #fff;
    width: 40%;
    background-color: #68c721;
    padding: 8px 30px;
    font-size: 20px;
    border: 1px solid #68c721;
    margin-top: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-weight: bold;
}

.join-now-section .join-txt .btn:hover {
    background-color: #56b40e;
}

@media (max-width: 576px) {
    .join-now-section .join-txt .btn {
        padding: 6px 30px;
        margin-bottom: 20px;
        font-size: 15px;
    }
}

.join-now-section .join-img img {
    width: 80%;
    float: right;
}

@media (max-width: 576px) {
    .join-now-section .join-img img {
        width: 100%;
    }
}

.testimonial-section {
    padding: 60px 0 62px;
    background-color: #f2f3f7;
}

@media (max-width: 768px) {
    .testimonial-section {
        padding: 0px 0 30px;
    }
}

@media (max-width: 576px) {
    .testimonial-section .testimonial-head-txt img {
        display: none;
    }
}

.testimonial-section .testimonial-head-txt h2 {
    font-weight: bold;
    color: #333333;
    font-size: 26px;
    line-height: 1.3;
    margin-top: 15px;
    margin-left: -35px;
}

@media (max-width: 576px) {
    .testimonial-section .testimonial-head-txt h2 {
        margin-left: 0px;
        font-size: 22px;
    }
}

.testimonial-section .testimonial-head-txt h2 span {
    color: #f47408;
}

.testimonial-section .testimonial-container {
    margin-top: 50px;
    border-radius: 5px;
    background-color: #fff;
    padding: 65px 45px 45px;
}

@media (max-width: 768px) {
    .testimonial-section .testimonial-container {
        padding: 0;
        background-color: #f2f3f7;
        margin-top: 5px;
    }
}

.testimonial-section .testimonial-container .testimonial-video {
    background-color: #464547;
    padding: 14px 14px 10px;
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: relative;
}

@media (max-width: 768px) {
    .testimonial-section .testimonial-container .testimonial-video {
        margin-top: 10px;
    }
}

.testimonial-section .testimonial-container .testimonial-video:hover {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
}

@media (max-width: 768px) {
    .testimonial-section .testimonial-container .testimonial-video iframe {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .testimonial-section .testimonial-container .testimonial-content {
        margin-top: 20px;
    }
}

.testimonial-section .testimonial-container .testimonial-content .ttm-img {
    width: 80px;
    height: 80px;
    background-color: #464547;
    border-radius: 50%;
    margin: auto;
}

.testimonial-section .testimonial-container .testimonial-content .ttm-img img {
    border-radius: 50px;
    border: 2px solid #333333;
}

.testimonial-section .testimonial-container .testimonial-content .ttm-txt {
    text-align: center;
    margin-top: 10px;
}

.footer-txt {
    background-color: #464547;
    padding: 10px 5px;
    color: #f3ecec;
    text-align: justify;
}

.footer-sec {
    background-color: #2d2d2d;
    padding: 2px;
}

.footer-sec p {
    color: #c5c5c5;
    padding-top: 8px;
}

.footer-sec p span {
    float: right;
    color: #c5c5c5;
}

#ftread-more,
#ftread-less {
    color: #f47408;
    text-decoration: none;
    font-size: 12px;
}

#ft-show-more-content {
    font-size: 12px;
}

@media (max-width:768px) {
    .footer-sec p span {
        float: left;
    }
}

.payment_page .all_details {
    padding-top: 20px;
    padding-bottom: 20px;
}

.payment_page .all_details .user_image {
    background: #F4F3F3;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    padding: 5px 0px 0px 3px;
}

.payment_page .all_details .checkout_detils {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

.user_address {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #A4A3A2;
}

.payment_page .all_details .chnage_address {
    padding: 10px;
    background: #F4F3F3;
    border: 1.4px solid #D1D1D0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.payment_page .all_details .order_payment {
    font-weight: 600;
}

.payment_page .all_details .form-check-input {
    height: 15px;
    width: 15px;
}

.payment_page .all_details .right-2 .form-check label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-top: 3px;
    color: #8D8B8B;
}

.payment_page .all_details .right-2 .form-check {
    background: #FEEFE7;
    padding: 10px 40px 12px;
    border: 1.6px solid #F65F12;
}

.payment_page .all_details .coupon_details {
    margin-top: 40px;
}

.payment_page .all_details .coupon_details .apply_coupon {
    margin-top: 10px;
    display: flex;
}

.coupon_details .coupon_code_heading,
.credit_details .coupon_code_heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.payment_page .all_details .credit_details {
    margin-top: 30px;
    display: grid;
}

.enter_coupon {
    border: 1.6px solid #767473;
    width: 468px;
    height: 50px;
}

.apply_btn {
    color: #fff;
    background: #F65F12;
    border: unset;
    padding: 12px 50px;
    margin-left: 20px;
}

.payment_option {
    display: flex;
}

.other_option {
    margin-left: 20px;
}

.order_summery_heading {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
    margin-bottom: 40px;
}

.order_summery {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.card_number {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #A4A3A2;
}

.all_prdouct_number {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5F5D5C;
    padding: 20px 0;
}

.all_prdouct {
    padding: 20px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #5F5D5C;
}

.payable_amount {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #1B1816;
}

.order_summery .payment_now {
    background: #F65F12;
    color: #fff;
    padding: 12px 110px;
    border: unset;
}

.payment_page .open_address {
    padding: 0 25px;
    margin-bottom: 10px;
}

.address_continue {
    background: #F65F12;
    color: #fff;
    padding: 12px 110px;
    border: unset;
    margin-top: 10px;
}

.all_prdouct .cart_product_img {
    margin-right: 30px;
}

.all_prdouct .cart_product_img .img-fluid {
    width: 53.33px;
}

.all_prdouct .cart_product_name {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #000;
}

.product_desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.product_discount {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
}

.check_out_details .check_out_button {
    background: #F65F12;
    color: #fff;
    padding: 10px 32px;
    border: none;
}

.check_out_amount {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #000;
}

.payment_page .open_address input:focus,
.payment_page .open_address select:focus {
    border-color: var(--primary);
    box-shadow: unset;

}

@media (max-width:767px) {
    .enter_coupon {
        width: auto;
    }

    .payment_page .all_details .coupon_details .apply_coupon {
        display: grid;
    }

    .apply_btn {
        margin-top: 10px;
        width: fit-content;
        margin-left: 0;
    }

    .payment_option {
        display: grid;
    }

    .other_option {
        margin-left: 0px;
        margin-top: 5px;
    }

    .chnage_address span {
        display: none;
    }

    .payment_page .all_details .form-check-input {
        height: 10px;
        width: 10px;
    }

    .payment_page .all_details .right-2 .form-check label {
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
    }

    .payment_page .all_details .chnage_address {
        padding: 0;
        border-radius: 5px;
    }

    .section-9 .cards-1 {
        width: auto;
    }

    .product_btn_spacing {
        padding: 10px 15px 0px;
    }

    #global_owl .owl-carousel .owl-stage-outer .owl-stage .owl-item {


        margin-right: 10px !important;

    }

    .section-5 .container .row .row {
        margin: auto;
        margin-top: 55px;
    }

    .all_prdouct .cart_product_name {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .all_prdouct .product_desc {
        display: none;
    }

    .check_out_amount {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
    }

    .product_discount {
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
    }

    .check_out_details .check_out_button {
        padding: 10px 30px;
    }

    .all_prdouct .cart_product_img .img-fluid {
        width: 33.33px;
    }

    .ngbAccordion_padding .item .video-img {
        margin: auto;
    }

    .payment_page .open_address {
        padding: 0px;
    }

    .about-The-course {
        padding-top: 0;
    }

    .section-9 .cards-1 .card p {
        margin-bottom: unset;
        height: 55px;
    }

    .study-package .container .row .right-11 {
        padding-top: unset !important;
    }

    .section-5 .top_result_slider_btn {
        padding: 0 10px;
    }

    .section-5 .container .row .owl_4 .owl-carousel .owl-stage-outer .owl-stage .owl-item .item .text {
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

    .set_box_margin {
        margin-top: 15px;
        justify-content: center;
    }
}

@media(min-width: 768px) and (max-width: 1024px) {
    #global_owl .owl-carousel .owl-stage-outer .owl-stage .owl-item {
        width: 348px !important;
        margin-right: 7px !important;
    }

    .section-5 .container .row .row {
        margin-top: 80px;
    }
}

@media all and (device-width: 912px) and (device-height: 1368px) {
    .section-5 .container .row .row {
        margin-top: 90px;
    }
}

@media (min-width:1500px) {
    #global_owl .owl-carousel .owl-stage-outer .owl-stage .owl-item {
        margin-left: 7px;
    }
}

.ngbAccordion_padding .item .text .dot_data {
    padding: 5px 10px 5px 10px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    cursor: pointer;
    margin-top: -10px;
}

.ngbAccordion_padding .item .text .dot_data_video {
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    padding-left: 10px;
    padding-right: 10px;
}

.ngbAccordion_padding .item .text .dot_data_video_desc {
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    padding-left: 10px;
    padding-right: 10px;
}

.resourse-1 .row .accordion .accordion-body .video_data:hover div {
    box-shadow: 0px 0px 20px rgb(27 24 22 / 10%);
    border-radius: 8px;
    color: var(--primary);
}

.ngbAccordion_padding .owl-item .item:hover {
    box-shadow: 0px 0px 20px rgb(27 24 22 / 10%);
    border-radius: 8px;
    color: var(--primary);
}

.resourse-1 .row .accordion .accordion-body .box_data {
    padding: 0 10px;
}

.resourse-1 .row .accordion .accordion-body .box_data:hover div {
    box-shadow: 0px 0px 20px rgb(27 24 22 / 10%);
    border-radius: 8px;
}

.resourse-1 .row .accordion-body .item .video-img img {
    width: 100px !important;
    height: auto;
}

@media (min-width:1500px) {
    #global_owl .owl-carousel .owl-stage-outer .owl-stage .owl-item {
        margin-left: 7px;
    }
}

.icon-div {
    box-shadow: inset 0px 5px 20px 0px #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-icon {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.main-icon img {
    width: 30px;
    height: 30px;
}

@media screen and (max-width: 992px) {
    .pre {
        padding-top: 50px;
    }
}

.text-area-dash h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
}

.fa-caret-down {
    padding-left: 10px;
    font-size: 15px;
}

.text-area-main span {
    color: #FF6A0B;

}

.text-area-main h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
}

.text-area-main p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.text-area-main button {
    padding: 11px 32px;
    background: #F65F12;
    border: none;
    margin-top: 35px;
    color: #fff;
}

.goClass {
    border: 1px solid #E8E8E8;
    border-radius: 10px;
}

.goClass h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #1B1816;
}

table td {
    color: #8D8B8B;
}

table h5 {
    color: #494645;
}

.goClass p {
    color: #8D8B8B;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.goClass table {
    margin-top: 30px;
}

.gobtn {
    width: 100%;
    padding: 12px 48px;
    background: #FF6A0B;
    border-radius: 0px 0px 10px 10px;
    border: none;
    color: #fff;
}

.gobtn1 {
    width: 100%;
    padding: 12px 48px;
    border-radius: 0px 0px 10px 10px;
    background: #E8E8E8;
    border: none;
}

@media only screen and (max-device-width: 438px) {
    .text-area-main h4 {
        font-size: 19px;
    }

    .text-area-main p {
        display: 16px;
    }
}

@media only screen and (max-device-width: 350px) {
    .text-area-main h4 {
        font-size: 18px;
    }
}

.rofile-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.rofile-logo h5 {
    font-weight: 600;
    font-size: 24px;
}

.rofile-logo a button {
    width: 173px;
    border: none;
    padding: 8px 32px !important;
    background: #F65F12;
    color: #ffffff;
    font-size: 16px;
    font-family: 'Poppins';
}

input::placeholder {
    font-family: 'Poppins', sans-serif;
}

.logo-man {
    display: flex;
    align-items: center;
}

.logo-man img {
    margin-top: 30px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
}

.logo-man a button {
    font-weight: 600;
    font-size: 14px;
    border: 1.6px solid #D1D1D0;
    margin-left: 30px;
    padding: 12px 22px;
}

@media screen and (max-width: 410px) {
    .logo-man {
        display: block;
        text-align: center;
    }

    .logo-man a button {
        margin-top: 20px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 369px) {
    .logo-man a button {
        width: 95%;
    }
}

@media screen and (max-width: 676px) {
    .logo-man a button {
        padding: 12px 10px;
        margin-left: 10px;
    }

    .header123.scrolling {
        height: auto;
    }

    .header123.hide {
        top: -140px;
    }

    .footer_section img {
        width: auto;
    }
}

.form-admin {
    padding-bottom: 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);

}

.form-admin label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-top: 20px;
    padding-bottom: 10px;
    color: #8D8B8B;
}

.form-admin input {
    border: 1px solid #A4A3A2;
    border-radius: 8px;
    padding: 13px 15px;
    font-family: 'Poppins';


}

.form-admin input::placeholder {
    font-size: 14px;
    color: #BBBAB9;
}

.form-admin input:focus {
    outline: none;
    box-shadow: none;
    border-color: #FF6A0B;
}

.current-running-plane {
    width: 100%;
    margin-bottom: 30px;
}

.current-running-plane h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

@media (max-width: 954px) {
    .current-running-plane h4 {
        padding-top: 30px;
    }

    .topscroll {
        margin-top: 30px;
    }
}

.current-box {
    margin-bottom: -25px;
    width: 100%;
    height: 15%;
    position: relative;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
}

.text-upgrade {
    padding: 20px;
    padding-bottom: 0;
}

.current-box h5 {
    color: #1B1816;
    font-size: 22px;
}

.current-box-date h5 {
    color: #494645 !important;
    font-size: 18px !important;
}

.current-box span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #F4371E;
    position: absolute;
    top: 20px;
    right: 35px;
}

@media (max-width: 450px) {
    .current-box span {
        position: relative;
        left: 0px;
        bottom: 10px;
    }
}

.current-box h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #1B1816;

}

.current-box p {
    color: #8D8B8B;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.current-box a .upgrade {
    width: 100%;
    padding: 12px 48px;
    background: #FF6A0B;
    border-radius: 0px 0px 10px 10px;
    border: none;
    margin-top: 31px;
    font-size: 18px;
    color: #ffffff;
}

.form-admin h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    padding-top: 65px;
    color: #1B1816;
    padding-bottom: 32px;
}

.setting-box {
    padding-bottom: 15px;
}

.setting-box-border {
    opacity: 0.1;
    border-bottom: 1px solid #425365;
}

.setting-box {
    display: flex;
    justify-content: space-between;
}

.card-subsc img {
    display: inline-block;
}

.form input::placeholder {
    color: #BBBAB9;
    font-size: 14px;
    font-family: 'Poppins' !important;
}

.card-subsc h4 {
    display: inline-block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-left: 22px;
    cursor: pointer;
}

.card-subsc h4:hover {
    color: var(--primary);
}

.setting-box i {
    padding-right: 30px;
    font-size: 20px;
    padding-top: 20px;
}

.active-iit {
    background: #FFF8F3;
    border: 1.2px solid #F65F12;
    border-radius: 8px;
}

.active-iit::placeholder {
    color: #F65F12 !important;
    font-weight: 600;
}

.active-text::placeholder {
    color: #1B1816;
}

.dropdown-menu.show li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.dropdown-menu.show li:hover {
    color: #FEEFE7;
}

.profileShows .dropdown-menu.show {
    width: max-content;
}

.dropdown-item {
    padding: 10px 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 11px;
}

.dropdown-menu.show span {
    color: rgb(27, 24, 22);
    font-weight: bold;
}

.text-area-row {
    background: #FFF7F3;
    border-radius: 20px;
}

@media screen and (max-width: 280px) {
    .section-1 .left {
        padding: 15px 36px !important;
    }
}

.modal_content_pdf .modal_content_body {
    padding: 10px 0 0 !important;
}

.pdf_popup_container {
    display: flex;
}

.pdf_file_container iframe {
    width: 100vw !important;
    min-height: 100vh !important;
}

.print_btn {
    border: 1px solid #e67e22 !important;
    color: #e67e22 !important;
}

.save_btn {
    border: 1px solid #7f8fa6 !important;
    color: #7f8fa6 !important;
}

.print_btn:hover {
    background-color: #e67e22 !important;
    color: #fff !important;
}

.save_btn:hover {
    background-color: #7f8fa6 !important;
    color: #fff !important;
}


@media (max-width:767px) {
    .pdf_file_container {
        width: 100% !important;
    }

    .pdf_file_container iframe {
        width: 100% !important;
        min-height: auto !important;

    }
}

.vdo_play_poster_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 400px;
    background-image: url('https://st3.depositphotos.com/4327059/13950/v/450/depositphotos_139503388-stock-illustration-hand-drawn-mountains-poster.jpg')
}

.vdo_play_poster_container a img {
    mix-blend-mode: color-burn;
    width: 80px;
}

.modal_content_vdo,
.modal_vdo_body {
    padding: 0 !important;
}

.vdo_popup_container {
    display: flex;
}

.vdo_container {
    width: 100%;
}

.vdo_container #video_frame {
    width: 100%;
    margin: 0 auto;
}

.modal_content_pdf .modal_content_body {
    padding: 10px 0 0 !important;
}

.okay_btn {
    border: 1px solid #e67e22 !important;
    color: #e67e22 !important;
}

.okay_btn:hover {
    background-color: #e67e22 !important;
    color: #fff !important;
}

.img_file_container img {
    width: 100%;
    margin: 0 auto;
}

@media (max-width:767px) {
    .img_file_container {
        width: 100% !important;
    }
}

.containerModal {
    width: 80%;
    border: 2px solid Black;
    padding: 15px 15px 15px 15px;
    margin: 20px 20px 20px 20px;
    background: #A4D3EE;
    overflow: visible;
    box-shadow: 5px 5px 2px #888888;
    position: relative;
}

.form-text {
    border-color: #dc3545;
}

.invalid-feedback-dt {
    display: block;
    width: 100%;
    margin-top: 3.25rem;
    font-size: .875em;
    color: #dc3545
}


@media screen and (max-width: 1200px) {
    .toggle-desktop {
        width: 40px;
    }

    .toggle-desktop .desktop-content {
        margin-left: -15px;
    }

    .lines1 {
        left: 10px;
    }

    .tushar {
        width: 250px !important;
    }
}

.drop-in_effect {
    animation: drop-in_effect 1s ease 200ms backwards;
}

@keyframes drop-in_effect {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }

    to {
        opacity: 1;
        transform: translate(0px);
    }
}

.INTL_rank {
    font-weight: 600;
}

@media(min-width: 768px) and (max-width: 990px) {
    .container .row .right button {
        right: 240px;
    }
}

@media all and (device-width: 1024px) and (device-height: 600px) {
    .container .row .right button {
        right: 22px;
    }
}

@media all and (device-width: 1024px) and (device-height: 1366px) {
    .container .row .right button {
        right: 22px;
    }
}

@media (min-width:768px) and (max-width:1200px) {
    .ask-a-doubt .container .row .col-lg-8 .menu .select-2 {
        width: 220px;
        margin: auto;
    }

    .ask-a-doubt .container .row .col-lg-8 .menu .select-1 {
        width: 120px;
    }
}

@media (max-width:325px) {
    .credit_details img {
        width: 90%;
    }

    .coupon_details .coupon_code_heading,
    .credit_details .coupon_code_heading {
        font-size: 12px;
    }

    .order_summery .payment_now {
        padding: 12px 60px;
    }

    .address_continue {
        padding: 12px 60px;
    }
}

.iti {
    position: relative;
    display: inline-block;
    width: 100%
}

.attachment-full.size-full {
    width: 300px;
    height: 300px;
}

.button-hide {
    display: none;
}

.book_free_demo_mb {
    position: fixed;
    top: 57px;
    z-index: 9;
    left: 0px;
    right: 0px;
    text-align: center;
    display: none;
}



.book_free_demo_mb .expand_box_btn {
    background: rgb(255, 143, 48);
    background: linear-gradient(0deg, rgba(255, 143, 48, 1) 0%, rgba(244, 116, 8, 1) 100%);
    color: #fff;
    padding: 10px 25px;
    border-radius: 16px;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    cursor: pointer;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    position: relative;
    margin: auto;
    display: none;
    top: 8px;
}

.bottom_link_strip .bt_lcourses:before {
    background-position: -176px 0;
}

.book_free_demo_mb .expand_box_btn:after {
    content: "\f103";
    font-family: "FontAwesome";
    color: #fff;
    font-size: 16px;
    width: 20px;
    margin-left: 10px;
    font-weight: 200;
}

.bottom_link_strip {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #21354b;
    display: none;
    z-index: 99;
    text-align: center;
}

.bottom_link_strip a {
    padding: 8px 0px;
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
}

@media all and (max-width: 767px) {
    .book_free_demo_mb {
        display: block;
        top: 97px;
    }

    .bottom_link_strip {
        display: block;
    }

    .bottom_link_strip a {
        width: 32%;
    }

}


.left_box h1 {
    font-size: 22px;
    color: #425365;
    font-weight: 600;
    margin-top: 50px;
}

.left_box h2 {
    font-size: 18px;
    color: #425365;
    font-weight: 600;
    padding: 0;
}

.left_box p {
    font-size: 14px;
    color: #4c4c4c;
    line-height: 22px;
    margin: 5px 0 8px 0;
    word-break: break-word;
}

.left_box table {
    border-collapse: collapse;
    line-height: 0;
    margin-bottom: 20px;
    width: 100% !important;
    border: 1px solid #d2d2d2;
}

.left_box table td p {
    margin: 0px;
    text-align: left !important;
}

.left_box h1 span strong,
.left_box h1 strong span {
    font-size: 22px !important;
    color: #425365 !important;
    font-weight: 600;
}

.left_box ul li a,
.left_box a,
.left_box a strong {
    color: #eb7f00;
    font-weight: 600;
    font-size: 14px;
}

.left_box table td,
.left_box table td>a {
    font-size: 14px;
    line-height: 22px;
    margin: 5px 0 8px 0;
    text-align: center !important;
}

.left_box table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #d2d2d2;
}

.left_box h1 span strong,
.left_box h1 strong span {
    font-size: 22px !important;
    color: #425365 !important;
    font-weight: 600;
}

.left_box h2 span strong,
.left_box h2 strong span,
.left_box p>strong>span>span,
.left_box p>span>strong>span {
    font-size: 18px !important;
    color: #425365 !important;
    font-weight: 600;
}

.left_box h3,
.left_box h3 span strong,
.left_box h3 strong span {
    font-size: 18px !important;
    color: #425365 !important;
    font-weight: 600;
    margin-left: 0px !important;
}

.left_box h3,
.left_box h3 span strong,
.left_box h3 strong span {
    font-size: 18px !important;
    color: #425365 !important;
    font-weight: 600;
    margin-left: 0px !important;
}

.left_box a,
.left_box a strong {
    color: #eb7f00;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none !important;
}

.left_box a:hover {
    color: #ec8002;
    text-decoration: underline;
}

.limitTextHeight p {
    font-weight: 500 !important;
    line-height: 21px !important;
    color: #1b1816 !important;
}

.toggle-desktop img {
    margin-right: 25px;
}

.enrolldivision {
    display: flex;
    align-items: center;
}

.mobile-arrow {
    width: 7.5% !important;
    height: 34.5% !important;
    margin-top: 51px;
    display: none !important;
}

@media screen and (max-width: 600px) {

    /* .mobile-button {
    background-color: #ff6a0b;
    color: #fff;
    border: none;
    padding: 0px;
    margin-top: 54px;
    display: block;
    margin-left: 13px;
  } */

    /* .enrolldivision .button-mobile {
        margin-top: -30px;
    } */

    .mobile-arrow {
        width: 7.5% !important;
        height: 34.5% !important;
        margin-top: 51px;
        display: block !important;
    }

    .banner-wrap {
        width: 100% !important;
    }

    .banner-left-side ul {
        font-size: 8px !important;
    }

    .section-1 {
        background-color: #FFEBE0;
        width: 100%;
        position: relative;
        height: 176px;
    }

    .banner-right-side img {
        width: 170px !important;
        max-width: 170px !important;
        right: 14px;
        bottom: -30px;
        position: relative;
    }

    .banner-right-side {
        width: 30% !important;
    }

    .banner-left-side {
        display: flex !important;
        justify-content: center !important;
        width: 70% !important;
    }

    .banner-left-side p span {
        font-size: 10px !important;
        display: flex !important;
        margin-top: 0px !important;
        color: #F55F12 !important;
        font-weight: 600 !important;
    }

    .banner-left-side p {
        font-size: 9px !important;
        color: #2E2E2E !important;
        font-weight: 400 !important;
        margin-bottom: 0px !important;
        line-height: 13px !important;
    }

    .banner-left-side h1 {
        font-size: 13px !important;
        color: #000 !important;
        font-weight: 700 !important;
        margin-bottom: 0px !important;
        line-height: 15px !important;
    }

    .banner-left-side {
        font-family: 'Poppins', sans-serif !important;
        padding-top: 20px !important;
        width: 70% !important;
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
    }

    .banner-left-side ul {
        font-size: 15px;
        text-align: left;
        padding: 0px 0px 0px 20px;
        margin: 2px 0px 8px 0px !important;
    }

    .section-1 p {
        font-family: var(--poppins);
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        margin-top: 2px !important;
        line-height: 32px;
        color: var(--p-color);
    }

    .button[_ngcontent-serverApp-c45] {
        background-color: #ff6a0b !important;
        color: #fff !important;
        border: none !important;
        padding: 1px 20px !important;
        margin-top: 51px !important;
        margin-left: 12px !important;
        display: block !important;
    }

    .enrolldivision .button{
        background-color: #ff6a0b ;
        color: #fff;
        border: none;
        /* padding: 5px  !important; */
        /* margin-top: 16px  !important; */
        display: block  !important;
        margin-left: 13px  !important;
        /* display: flex !important; */
        margin-top: 50px !important;
        padding: 3px !important;
        width: 105px !important;
        font-size: 13px !important;
    }

    /* .banner-left-side p span {
        font-size: 15px;
        display: flex;
        margin-top: 0px;
        color: #F55F12;
        font-weight: 600;
    }
    h1
    {
        font-size: 85%;
    }
    p{
        font-size: 65%;
    }
    .banner-left-side{
        height: 9%;
    width: 32%;
    padding-top: 18%;
    margin-left: -32px;
    }
    .banner-left-side p span {
        font-size: 15px;
        display: flex;
        margin-top: 0px;
        color: #F55F12;
        font-weight: 600;
    } */
}

.banner-wrap {
    /* background: #FFEBE0; */
    background: transparent;
    max-height: 400px;
    padding-top: 20px;
}

.section-1 .owl-theme .owl-nav {
    margin-top: -8px;
}

.banner-left-side {
    font-family: 'Poppins', sans-serif;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.banner-left-side h1 {
    font-size: 28px;
    color: #000;
    font-weight: 700;
    margin-bottom: 0px;
    line-height: 34px;
}


.banner-left-side h1 span {
    color: #F55F12;
}

.banner-left-side p {
    font-size: 15px;
    color: #2E2E2E;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 21px;
}

.banner-left-side p strong {
    font-weight: 600;
}

.banner-left-side p span {
    font-size: 18px;
    display: flex;
    margin-top: -20px;
    color: #F55F12;
    font-weight: 600;
}

.banner-left-side ul {
    font-size: 15px;
    text-align: left;
    padding: 0px 0px 0px 20px;
    margin: 0px 0px 15px 0px;
}

.banner-left-side ul li {
    margin-bottom: 5px;
}

.banner-left-side button {
    background-color: #ff6a0b;
    color: #fff;
    border: none;
    padding: 10px 14px;
    width: 50% !important;
}

.banner-left-side button:hover {
    opacity: 0.8;
}

.banner-right-side {
    display: flex;
    justify-content: center;
}

.banner-right-side img {
    width: 100%;
    max-width: 60%;
}

.list_leftside_video>.first:hover {
    background-color: #f65f12;
}

.enrolldivision {
    margin-top: -70px;
    height: 110px;
    margin-bottom: 0px;
}


/* ================= NEW STYLING ============== */

.global_btn .country_btn {
    background: #fff;
    border-radius: 25px !important;
}

button.btn.country_btn.foundation.active_1, .global_btn .country_btn .active_1:active {
    background: #000!important;
    color: #fff;
}

button.btn.country_btn:hover {
    background: rgb(211 211 210 / 50%)
    /* background: #b4eafc; */
}

.OurResrs {
    border-radius: 30px;
    overflow: hidden;
    background: #b4eafc;
}

.resrcSectWrapFirst {
    /* background: #dce9b9; */
    /* background: #b4eafc; */
    border-radius: 40px 0px 0px 40px;
}

.resrcSectWrapSecond {
    /* background: #f5f8ed; */
    background: #e9f5f9;
    border-radius: 40px 0px 0px 40px;
}

.section-3 .container .row .first div a {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #333;
    text-align: left;
    text-decoration: none;
    border: none;
    padding: 5px 20px;
    border-radius: 65px;
    transition: all 0.5s ease-in-out;
    background: rgb(255 255 255 / 60%);
}

.section-3 .container .row .first div a:hover {
    /* border: 1px solid #648806; */
    background: rgb(255 255 255 / 90%);
}

.inspirResultWrap .item.ng-star-inserted {
    padding: 20px;
    background: #e9ead1;
    margin: 0px 5px;
    border-radius: 24px;
}

.aiStats {
    /* background: rgb(255 255 255); */
    padding: 20px 0px 24px;
    border-radius: 45px 45px 0px 0px;
    margin: 0px auto;
    /* border: 1px solid #dae0ca;
    box-shadow: 0px 5px 13px -5px rgb(219 223 209 / 65%); */
    background-image: linear-gradient(to top, #ffffff00 0%, #ffffff 100%);
    display: flex;
    justify-content: space-around;
}

.accordion-button {
    border-radius: 15px !important;
    /* color: var(--primary); */
    color: #333;
    background: none;
    box-shadow: none;
    font-weight: 600 !important;
    font-size: 17px;
    line-height: 26px;
    padding: 10px 25px;
    height: unset !important;
}

.dropdown-list[_ngcontent-serverApp-c53] {
    position: absolute;
    padding-top: 0px !important;
    width: 100%;
    z-index: 9999;
    border: 1px solid #ddd !important;
    border-radius: 5px !important;
    background: #eceff5 !important;
    margin-top: 40px !important;
    /* box-shadow: 0px 8px 14px #d6d8dd !important; */
    box-shadow: none !important;
}

.dropdown-list[_ngcontent-serverApp-c53] .filter-textbox[_ngcontent-serverApp-c53] input[_ngcontent-serverApp-c53] {
    border: 0;
    width: 100%;
    padding: 9px 13px 9px !important;
    font-size: 14px;
    border-radius: 30px !important;
}

.multiselect-item-checkbox[_ngcontent-serverApp-c53]:hover {
    background-color: #dfe7f7 !important;
}

.multiselect-item-checkbox[_ngcontent-serverApp-c53] input[type=checkbox]+div[_ngcontent-serverApp-c53]:before {
    border: 1px solid #717171 !important;
    border-radius: 3px !important;
}

.multiselect-item-checkbox[_ngcontent-serverApp-c53] input[type=checkbox]+div[_ngcontent-serverApp-c53] {
    padding-left: 24px !important;
}

.col-lg-4.text- {
    /* background: lightgrey; */
    /* background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); */
    /* border-radius: 10px; */
    padding: 25px 20px 20px;
    /* border: 1px solid #ddd; */
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
    /* box-shadow: 0px 0px 15px rgb(227 227 227); */
}

/* .uploadFrmCTA input {
} */

.uploadFrmCTA input[type=file] {
    padding: 0;
    margin: 0;
    position: relative;
    outline: none;
  }

.uploadFrmCTA input[type=file]::file-selector-button {
    border-radius: 5px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: none;
    margin-right: 16px;
    width: 132px;
    color: transparent;
  }

  @supports (-moz-appearance: none) {
    .uploadFrmCTA input[type=file]::file-selector-button {
      color: var(--primary-color);
    }
  }

  .uploadFrmCTA input[type=file]::file-selector-button:hover {
    background-color: #f3f4f6;
  }

  .uploadFrmCTA input[type=file]::file-selector-button:active {
    background-color: #e5e7eb;
  }

  .uploadFrmCTA input[type=file]::before {
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 12px;
    height: 20px;
    width: 20px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
  }

  .uploadFrmCTA input[type=file]::after {
    position: absolute;
    pointer-events: none;
    top: 9px;
    left: 37px;
    color: var(--primary-color);
    content: "Upload File";
    font-size: 15px;
  }

  .uploadFrmCTA input[type=file]:focus-within::file-selector-button, input[type=file]:focus::file-selector-button {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
  }

  h1.readQuesAsked, h1.readQuesAsked span {
    font-family: var(--poppins)!important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 32px !important;
    color: var(--color-a) !important;
    margin-bottom: 5px;
    text-align: left !important;
  }

  h1.readQuesAsked{
    margin-top: -5px;
  }

  .OCFeature {
    margin: 9px 0px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    padding: 5px;
    border-radius: 8px;
}

  .OCFeature .img-fluid {
    max-width: 100%;
    height: auto;
    width: 30px;
    height: 30px;
}

.img.onClassBlock {
    border-bottom: 1px dashed #ddd !important;
}

/* ===== LOGIN SCREEN STYLE ===== */

.google-login-button #google-login-button {
    border: none !important;
    border-radius: 10px !important;
}

#google-login-button[_ngcontent-serverApp-c31] {
    border: none !important;
}


.nsm7Bb-HzV7m-LgbsSe {
    border: none;
}
#google-login-button {
    border-radius: 40px!important;
    border: 1px solid #c2c4c7 !important;
    height: 42px;
    overflow: hidden;
}

.login_sign_link {
    border: 1px solid #c2c4c7 !important;
    padding: 9px 13px !important;
    color: #555;
    background-color: #fff !important;
    font-weight: 400!important;
    width: 80%;
    display: block;
    margin: auto;
    border-radius: 50px!important;
    font-size: 14px;
    /* background-image: linear-gradient(120deg, #ffffff 0%, #ffffff 100%) !important; */
    /* background-image: linear-gradient(120deg, #ffffff 0%, #f1f1f1 100%) !important; */
    /* background-size: 200% 100% !important; */
    transition: background-color 1s !important;
    height: 42px;
}

p.AIformTerms {
    font-size: 12px;
    text-align: center;
    line-height: 17px;
    margin: 40px 0px 0px;
    color: #919191;
    font-weight: 400;
}

p.AIformTerms a {
    color: #ed9143;
}

p.AIformTerms a:hover {
    color: #333;
}

.col-md-12.text-center {
    margin: 0px !important;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: inherit;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: unset;
    border-top-right-radius: unse;
    flex-direction: row;
    background: #e5e7eb;
    /* width: 36px; */
    padding: 10px;
    text-align: center;
    border-radius: 60px;
    flex-wrap: wrap;
    float: right;
    margin: 6px;
    position: absolute;
    right: 0px;
    z-index: 99;
    align-content: flex-start;
    top: 0;
}

.modal-header .btn-close {
    padding: 0px !important;
    margin: 0px !important;
}

.modal-header .btn-close:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0em 0em;
    color: #000;
    /* background: transparent url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) center / 1em auto no-repeat; */
    border: 0;
    border-radius: .375rem;
    opacity: .5;
}

.jhModelwrap .modal-content {
    position: relative !important;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: none !important;
    border-radius: 20px !important;
    outline: 0;
    padding: 0px !important;
}


.modal-content {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: none !important;
    border-radius: 20px !important;
    outline: 0;
    padding: 10px;
    /* position: absolute; */
}



.email-Icon-brand-svg {
    width: 25px;
    float: left;
    margin-top: 2px;
}

.sign_up_hide .form-group label {
    font-weight: 700 !important;
    font-size: 15px;
    margin: 0px 0px 8px !important;
    color: #333;
}

.form-group.phnNumb label {
    font-weight: 700 !important;
    font-size: 15px;
    margin: 0px 0px 8px !important;
    color: #333;
}

.form-group.phnNumb .input-box {
    display: flex;
    flex-direction: row;
    box-shadow: none !important;
    border: 1px solid #ddd !important;
    border-radius: 50px !important;
    height: 42px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #bbbab9;
    padding: 3px 25px !important;
    margin-bottom: 20px;
}

.notificationMessage .modal-dialog {
    float: none !important;
    margin-right: auto !important;
    width: inherit;
    width: 100%;
}


/* -- ================= NEW MEDIA QUEARY ================= -- */

@media screen and (max-width: 767px) {

    body {
        padding-top: 90px;
    }

    .section-2 {
        padding-top: 40px;
    }

    /* HEADER STYLE */
    header nav .menu a img {
        width: 143px;
    }

    /* BANNER STYLE */
    .banner-wrap {
        padding-top: 0px;
    }

    .banner-left-side {
        padding-top: 0px !important;
        width: 68% !important;
    }
    .button[_ngcontent-serverApp-c56] {
        width: 33% !important;
        padding: 5px 5px !important;
        font-size: 13px !important;
    }

    /* EXPLORE COURSES */
    .section-2 .owl_2 .owl-carousel .item {
        padding: 10px 20px !important;
        width: auto !important;
        height: 130px !important;
        border-radius: 15px !important;
        margin: 20px 10px 5px !important;
    }
    .product_btn_spacing .global_btn[_ngcontent-serverApp-c58] {
        padding-left: 0px;
    }

    /* OUR RESOURCES */

    .section-3 .container .row .first p {
        font-family: var(--poppins);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-a);
        margin-top: 10px;
        display: block;
        text-align: center;
    }

    .resrcSectWrapSecond {
        border-radius: 25px 25px 0px 0px;
    }

    /* STATS STYLE */
    
    .aiStats {
        padding: 30px 0px 5px;
        display: ruby;
    }

    .section-5 .container .row .row {
        padding: 0px;
    }

    .aiStats.col-lg-3 {
        text-align: center;
        width: 43%;
        display: inline-block;
        /* float:left; */
        margin: 0px 12px 12px;
    }

}


/* ============= -- EXTRA NEW STYLES  -- ============= */

main.main-2.extraNewMargin {
    margin-top: -60px;
}